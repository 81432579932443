import React from "react";
import css from "../../style/availablePickups.module.css";
import FilterOptions from "../../components/filterOptions";
import AvailablePickupCard from "../../components/availablePickupCard";

const AvailablePuckup = () => {
  const Options = [
    {
      title: "Filter By",
      select: [["All", "on demand", "scheduled", "flexible"]],
    },
    {
      title: "Sort By",
      select: [
        [
          "Select Time/Distance Sorting",
          "Shortest-Longest Time",
          "Longest-Shortest Time",
          "Shortest-Longest Distance",
          "Longest-Shortest Distance",
        ],
      ],
    },
    {
      title: "Pickup Location",
      select: [
        ["current", "home", "other"],
        ["within 5 km", "within 10 km", "within 50 km", "within 100km"],
      ],
    },
  ];
  return (
    <div className={css?.AvailablePuckup}>
      <div className={css?.sideFilterDiv}>
        {Options?.map((option) => (
          <FilterOptions title={option?.title} select={option?.select} />
        ))}
        <div className={css?.sideFilterOption}>
          <div className={css?.searchButton}>Search</div>
        </div>
      </div>
      <div className={css?.mainScreen}>
        <span className={css?.topHeading}>All available pickups</span>
        <span className={css?.results}>no results found</span>
        <AvailablePickupCard />
      </div>
    </div>
  );
};

export default AvailablePuckup;
