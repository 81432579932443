import React from "react";
import Layout from "../../layouts/index";
import VehicleInsuranceScreen from "../../screens/onbording_web/VehicleInsurance";

const VehicleInsurance = () => {
  return (
    <Layout>
      <VehicleInsuranceScreen />
    </Layout>
  );
};

export default VehicleInsurance;
