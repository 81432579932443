import axios from "axios";
import baseUrl from "../constants/baseUrl";
import { returnAuthHeader } from "../../utils/auth";
import { showLoader, hideLoader } from "../../utils/loader";

// post Driver profile action
// this action is called in Driver registration page
export const postDriverInfoWOvehicle =
  (driverProfile, driverId, setChangeScreen) => async (dispatch) => {
    try {
      showLoader();
      console.log("profile is updating", driverProfile);
      const { data } = await axios.post(
        `${baseUrl}RegisterPageAPI/NoVehicleDriverFlow?id=${driverId}&vehicleType=${driverId}`,
        driverProfile,
        returnAuthHeader()
      );
      console.log("No Vehicle Driver Flow response from api", data);
      setChangeScreen(1);
      hideLoader();
    } catch (error) {
      console.log(error);
      hideLoader();
    }
  };

// post Driver profile action
// this action is called in Driver with company page
export const postCompanyVehicleDriverFlowInfo =
  (driverProfile, driverId, setChangeScreen) => async (dispatch) => {
    try {
      showLoader();
      console.log("profile is updating", driverProfile);
      const { data } = await axios.post(
        `${baseUrl}RegisterPageAPI/CompanyVehicleDriverFlow?id=${driverId}`,
        driverProfile,
        returnAuthHeader()
      );
      console.log("Company Vehicle Driver Flow response from api", data);
      setChangeScreen(1);
      hideLoader();
    } catch (error) {
      console.log(error);
      hideLoader();
    }
  };

// post Driver profile action
// this action is called in Driver with vehicle page
export const postDriverWithVehicleInfo =
  (driverProfile, driverId, setChangeScreen) => async (dispatch) => {
    try {
      showLoader();
      console.log("profile is updating", driverProfile);
      const { data } = await axios.post(
        `${baseUrl}RegisterPageAPI/OwnVehicleDriverFlow?id=${driverId}&vehicleType=${driverId}`,
        driverProfile,
        returnAuthHeader()
      );
      console.log("Own Vehicle Driver Flow response from api", data);
      setChangeScreen(1);
      hideLoader();
    } catch (error) {
      console.log(error);
      hideLoader();
    }
  };

// Get vehicle types action
// this action is called in Driver REgistration pages
export const postDriverDetails = (details, id, history) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}RegisterPageAPI/PostVehicle?id=${id}`,
      details,
      returnAuthHeader()
    );
    history.push("/driver/welcomePage");
    console.log("Driver details response from api", data);
  } catch (error) {
    console.log(error);
  }
};

// Get vehicle types action
// this action is called in Driver REgistration pages
export const getVehicleTypes = (setVehicleTypes) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}RegisterPageAPI/GetVehicleTypes`,
      returnAuthHeader()
    );
    console.log("Vehicle types info response from api", data);

    setVehicleTypes(data?.data);
  } catch (error) {
    console.log(error);
  }
};

// Get categories action
// this action is called in Driver REgistration pages
export const getCategories = (setCategories) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}RegisterPageAPI/GetCategories`,
      returnAuthHeader()
    );
    console.log("Vehicle types info response from api", data);
    setCategories(data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getRegistrationStatus = async (
  id,
  setData,
  setError,
  setLoading
) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}Auth/GetDriverSignUpStatus?driverId=${id}`,
      returnAuthHeader()
    );
    setData(data);
    setError("");
    setLoading(false);
  } catch (error) {
    console.log(error);
    setError("Something went wrong, please try again later!");
    setLoading(false);
  }
};
