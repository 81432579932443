import React from "react";
import CompanyVehicleDriverFlow from "../screens/Signin/CompanyVehicleDriverFlow";
import Layout from "../layouts";

const CompanyVehicleDriverFlowPage = () => {
  return (
    <Layout>
      <CompanyVehicleDriverFlow />
    </Layout>
  );
};

export default CompanyVehicleDriverFlowPage;
