import React from "react";
import Layout from "../layouts/index";
import ConfirmEmail from "../screens/Accounts/ConfirmEmail";

const ConfirmEmailPage = () => {
  return (
    <Layout>
      <ConfirmEmail />
    </Layout>
  );
};

export default ConfirmEmailPage;
