import React, { useState, useEffect } from "react";
import css from "../style/becomeADriver.module.css";
import Tabs from "@material-ui/core/Tabs";
import { useHistory } from "react-router";
import FAQAccordion from "../components/FAQAccordion";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../store/actions/vehicleTypeActions";
import { vehicleSpecsImageURL } from "../store/constants/baseUrl";
import Layout from "../layouts/index";

function DriverBecome() {
  // history for routing
  const history = useHistory();
  // dispatch API calls
  const dispatch = useDispatch();
  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );
  // vehicle name
  const [vehicle, setvehicle] = useState("");
  // calling vehicle types and delivery slots API
  useEffect(() => {
    dispatch(getAllVehicles());
  }, []);

  const FAQs = [
    {
      question: "What documents are required to be a MUVEZ driver?",
      answer:
        "You will be required to provide a valid Canadian driver's licence, vehicle insurance and registration, proof of Canadian work eligibility and a background check will be performed. We need to screen all eligible drivers in order to keep our customers safe.",
    },
    {
      question: "How do I sign up to be a MUVEZ driver?",
      answer:
        'Step one is to apply. Please click on any of the buttons that read: “become a driver”, "apply to drive” or “sign up” to drive. Once you have signed up, you will be directed to our application process. Follow the required steps and upload all necessary documents. These documents are required by law. You won’t be able to accept orders until all necessary documents have been uploaded and approved by our verification team',
    },
    {
      question: "How long does the application process take?",
      answer:
        "Typically, filling out basic information (like your mobile phone number, driver’s license, and other documents) takes less than 30 minutes. Then a few more things need to happen: Vehicle Inspection: We’ll need to make sure your vehicle meets our basic requirements and fits in the category of vehicle you've applied for. Background check: Safety is our top priority, so we’ll need to run a background check to review your driving history. This can take a few days to be completed.",
    },
    {
      question: "When and where should I drive?",
      answer:
        "Normal business hours are of course quite good because most people are busy with work so could free up some time during their day by outsourcing delivery and saving on unessential trips. Weekends are a great time to target family orders. This is generally the time families will choose to move, do home improvement, declutter and clean, etc. People will gladly pay for a vehicle that show up at their doorstep and saves them a trip to the rental counter or even better does the entire delivery for them",
    },
    {
      question: "Is MUVEZ safe?",
      answer:
        "At moovez, we’re committed to driver and customer safety. We’ve got your back before, during, and after the ride. We've designed a review process for drivers to review the customers. Any customer that behaves in a way deemed inappropriate by our internal code of conduct will be removed from our platform indefinitely. 24/7 support: Easily contact our support team for reliable, around-the-clock help by phone or email, with any issues on and off the road.Emergency assistance: You always have the ability to contact 911 directly. Insurance: Our coverage includes liability and car insurance so we quite literally have your back — from the moment you switch into driver mode until you drop off your last delivery of the day. Learn more We do our best to create a safe work environment for every driver on our platform but we also need you to make a commitment to your own safety. Moving, lifting, carrying and delivering can be dangerous if not done properly. Safe work practices are ultimately what will reduce injuries. Slow down, be careful and stay safe out there.",
    },
    {
      question: "How do I get paid?",
      answer:
        "Your pay is based on the delivery distance and if the customer chooses to add labour. Drivers keep 100% of the tips and earn 80% of the remaining transaction. All payouts to the drivers are made through the moovez web app weekly, so you’ll need to add your banking info to get paid. You can also set up Express Pay to cash out when you want.",
    },
  ];
  return (
    <Layout>
      <div id="become_a_driver" className={css?.becomeADriver}>
        <div className={css?.topBannerDiv}>
          <div className={css?.bannerTextDiv}>
            <div className={css?.bannerHeading}>
              Grab the wheel and start earning.
            </div>
            <div className={css?.bannerText}>
              Earn money helping people move their things.
            </div>
            <button
              className={css?.bannerButton}
              // onClick={() => {
              //   window.location.href = "/DriverSignup";
              // }}
            >
              Apply to drive
            </button>
            <div className={css?.bannerLink}>Learn more about delivery</div>
          </div>
          <div className={css?.bannerImageDiv}>
            <img
              src={"/Scripts/muv_team/img/compressed-banner/car_box.png"}
              className={css?.bannerImage}
              alt="Grab the wheel and start earning."
              title="Grab the wheel and start earning"
            />
          </div>
        </div>
        {/* delivery vehicle */}
        {vehicleSpecsImageURL && (
          <div className={css?.vehicleDiv}>
            <div className={css?.vehicleDivHeading}>
              What vehicle do you drive?
            </div>
            <div className={css?.vehicleDivText}>
              You must have access to one of these vehicles in order to drive
              for MUVEZ.
            </div>
            <Tabs
              variant="scrollable"
              scrollButtons="on"
              className={css?.tabsSlider}
            >
              {vehicleTypesInfo?.map((vehicleCard) => (
                <div
                  onClick={() => setvehicle(vehicleCard?.title)}
                  className={css?.vehicleCard}
                >
                  <img
                    src={`${vehicleSpecsImageURL}${vehicleCard?.pictureUrl}`}
                    alt={vehicleCard?.description}
                    className={css?.vehicleImage}
                  />
                  <div className={css?.vehicleText}>{vehicleCard?.title}</div>
                </div>
              ))}
            </Tabs>
          </div>
        )}
        <div className={css?.secondBannerMainDiv}>
          <div className={css?.secondBannerHeading}>
            Don’t see your vehicle?
          </div>
          <div className={css?.secondBannerText}>
            Your vehicle may belong to a subcategory or a NEW category. You can
            still apply. Our verification team will process your application and
            put you in the appropriate category or create a new one for your
            vehicle type so you can start accepting deliveries.
          </div>
          <button
            className={css?.secondBannerButton}
            onClick={() => {
              history.push("/MUVcontactUsScreen");
            }}
          >
            Contact us
          </button>
        </div>
        <div className={css?.helpStepsMainDiv}>
          <div className={css?.helpStepsMainHeading}>Why drive with MUVEZ?</div>
          <div className={css?.helpStepsMainText}>
            It’s the perfect side hustle! Make a delivery when and where you
            want because you’ll be your own boss.
          </div>
          <div className={css?.helpStepsCardsDiv}>
            <div className={css?.helpStepsCard}>
              <div className={css?.helpStepsImgDiv}>
                <img
                  src={
                    "/Scripts/muv_team/img/compressed-icons/purpIcons/money.png"
                  }
                  alt="Earn Money"
                  title="Earn Money"
                  className={css?.helpStepsCardImg}
                />
              </div>
              <div className={css?.helpStepCardTextDiv}>
                <div className={css?.helpStepsCardHeading}>Earn money</div>
                <div className={css?.helpStepsCardText}>
                  The more you deliver the more you earn.
                </div>
              </div>
            </div>
            <div className={css?.helpStepsCard}>
              <div className={css?.helpStepsImgDiv}>
                <img
                  src={
                    "/Scripts/muv_team/img/compressed-icons/purpIcons/register.png"
                  }
                  alt="Earn Money"
                  title="Earn Money"
                  className={css?.helpStepsCardImg}
                />
              </div>
              <div className={css?.helpStepCardTextDiv}>
                <div className={css?.helpStepsCardHeading}>
                  Drive on your own time
                </div>
                <div className={css?.helpStepsCardText}>
                  On the way to and from work, for an hour, a weekend, make
                  deliveries around your schedule.
                </div>
              </div>
            </div>
            <div className={css?.helpStepsCard}>
              <div className={css?.helpStepsImgDiv}>
                <img
                  src={
                    "/Scripts/muv_team/img/compressed-icons/purpIcons/register.png"
                  }
                  alt="Earn Money"
                  title="Earn Money"
                  className={css?.helpStepsCardImg}
                />
              </div>
              <div className={css?.helpStepCardTextDiv}>
                <div className={css?.helpStepsCardHeading}>
                  Simple registration process
                </div>
                <div className={css?.helpStepsCardText}>
                  Need to be 21 or older, have a valid Canadian driver's licence
                  and proof of Canadian work eligibility.
                </div>
              </div>
            </div>
            <div className={css?.helpStepsCard}>
              <div className={css?.helpStepsImgDiv}>
                <img
                  src={
                    "/Scripts/muv_team/img/compressed-icons/purpIcons/training.png"
                  }
                  alt="Earn Money"
                  title="Earn Money"
                  className={css?.helpStepsCardImg}
                />
              </div>
              <div className={css?.helpStepCardTextDiv}>
                <div className={css?.helpStepsCardHeading}>
                  Virtual training
                </div>
                <div className={css?.helpStepsCardText}>
                  Become a verified MUVEZ driver partner by completing our quick
                  and simple training course online from home.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={css?.thirdBannerMainDiv}>
          <div className={css?.thirdBannerMainHeading}>How does it work?</div>
          <div className={css?.thirdBannerInnerDiv}>
            <div className={css?.thirdBannerMainImage}>
              <img
                className=""
                src={"/Scripts/muv_team/img/compressed-mobile/phone75.png"}
                alt=""
                title=""
              />
            </div>
            <div
              id="how_work_right"
              className={css?.thirdBannerTextDiv}
              id="instructions"
            >
              <div className={css?.thirdBannerTextBullet}>
                <div className={css?.thirdBannerStepsHeading}>Step 1</div>
                <div className={css?.thirdBannerStepsText}>
                  View all orders available for your vehicle type from your
                  Dashboard.
                </div>
              </div>
              <div className={css?.thirdBannerTextBullet}>
                <div className={css?.thirdBannerStepsHeading}>Step 2</div>
                <div className={css?.thirdBannerStepsText}>
                  Accept the orders you want to deliver.
                </div>
              </div>
              <div className={css?.thirdBannerTextBullet}>
                <div className={css?.thirdBannerStepsHeading}>Step 3</div>
                <div className={css?.thirdBannerStepsText}>
                  Hit the road and complete the deliveries.
                </div>
              </div>
              <div className={css?.thirdBannerTextBullet}>
                <div className={css?.thirdBannerStepsHeading}>Step 4</div>
                <div className={css?.thirdBannerStepsText}>
                  Review your earnings and get paid.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css?.earningMainDiv}>
          <div className={css?.earningMainHeading}>Earnings?</div>
          <div className={css?.earningText}>
            Better understand how your earnings work. Get to know the tools in
            the app, track your earnings and cash out, all from your driver
            account.
          </div>
          <button
            className={css?.earningButton}
            value="Earnings overview"
            onClick={() => {
              window.location.href = "/MUVearningScreen";
            }}
          >
            Earnings overview
          </button>
        </div>

        <div className={css?.getStartedMainDiv}>
          <div class={css?.getStartedImageDiv}>
            <img
              className={css?.getStartedImage}
              src={
                "/Scripts/muv_team/img/compressed-banner/sign_up_to_be_a_rider_or_a_driver.svg"
              }
              alt="moovez moving truck moving moving boxes"
              title="Sign up to be a rider or a driver."
            />
          </div>
          <div className={css?.getStartedTextDiv}>
            <div className={css?.getStartedTextHeading}>
              Ready to get started?
            </div>
            <div class={css?.getStartedTextText}>
              Sign up to be a rider or a driver.
            </div>
            <button
              className={css?.getStartedButton}
              onClick={() => {
                window.location.href = "/DriverSignup";
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className={css?.FAQDiv}>
          {FAQs?.map((item) => (
            <FAQAccordion question={item?.question} answer={item?.answer} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default DriverBecome;
