import React from "react";
import Layout from "../../layouts/index";
import ProofOfInsuranceScreen from "../../screens/onbording_web/ProofOfInsurance";

const ProofOfInsurance = () => {
  return (
    <Layout>
      <ProofOfInsuranceScreen />
    </Layout>
  );
};

export default ProofOfInsurance;
