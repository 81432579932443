import React from "react";
import css from "../style/availablePickups.module.css";

const availablePickupCard = () => {
  return (
    <div className={css?.cardMainDIv}>
      <div className={css?.cardFirstLine}>Real-Time</div>
      <div className={css?.cardContent}>
        <div className={css?.cardSecondLine}>
          <div className={css?.cardContentLeft}>
            <div className={css?.cardContentLeftImage}></div>
            <div className={css?.cardContentLeftData}>
              <div className={css?.cardContentLeftDataName}>
                <span className={css?.cardContentLeftDataNameName}></span>
                <span className={css?.cardContentLeftDataNameRating}></span>
                <span className={css?.cardContentLeftDataNameIcon}></span>
              </div>
              <div className={css?.cardContentLeftDataAddress}>
                <span className={css?.cardContentLeftDataAddressIcon}></span>
                <span className={css?.cardContentLeftDataAddressAddress}></span>
              </div>
            </div>
          </div>
          <div className={css?.cardContentRight}>
            <div className={css?.cardContentRightBox}>
              <div className={css?.cardContentRightBoxLabel}>
                <span className={css?.cardContentRightBoxLabelIcon}></span>
                <span className={css?.cardContentRightBoxLabelText}></span>
              </div>
              <div className={css?.cardContentRightBoxData}></div>
            </div>
            <div className={css?.cardContentRightBox}>
              <div className={css?.cardContentRightBoxLabel}>
                <span className={css?.cardContentRightBoxLabelIcon}></span>
                <span className={css?.cardContentRightBoxLabelText}></span>
              </div>
              <div className={css?.cardContentRightBoxData}></div>
            </div>
            <div className={css?.cardContentRightBox}>
              <div className={css?.cardContentRightBoxLabel}>
                <span className={css?.cardContentRightBoxLabelIcon}></span>
                <span className={css?.cardContentRightBoxLabelText}></span>
              </div>
              <div className={css?.cardContentRightBoxData}></div>
            </div>
            <div className={css?.cardContentRightBox}>
              <div className={css?.cardContentRightBoxLabel}>
                <span className={css?.cardContentRightBoxLabelIcon}></span>
                <span className={css?.cardContentRightBoxLabelText}></span>
              </div>
              <div className={css?.cardContentRightBoxData}></div>
            </div>
          </div>
        </div>
        <div className={css?.cardThirdLine}>
          <div className={css?.cardThirdLineLeft}>
            <div className={css?.cardThirdLineLeftBox}>
              <span className={css?.cardThirdLineLeftBoxIcon}></span>
              <span className={css?.cardThirdLineLeftBoxText}></span>
            </div>
            <div className={css?.cardThirdLineLeftBox}>
              <span className={css?.cardThirdLineLeftBoxIcon}></span>
              <span className={css?.cardThirdLineLeftBoxText}></span>
            </div>
            <div className={css?.cardThirdLineLeftBox}>
              <span className={css?.cardThirdLineLeftBoxIcon}></span>
              <span className={css?.cardThirdLineLeftBoxText}></span>
            </div>
          </div>
          <div className={css?.cardThirdLineRight}></div>
        </div>
      </div>
    </div>
  );
};

export default availablePickupCard;
