import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import css from "../style/footer.module.css";
import { FaTwitter, FaInstagram, FaFacebook } from "react-icons/fa";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      console.log(error);
    }
  }, []);
  return size;
}

const NewFooter = () => {
  const [height, width] = useWindowSize();
  const history = useHistory();
  return (
    <div className={css?.footerMainDiv}>
      <div className={css?.headerFourDivs}>
        <div className={css?.footerInfo}>
          <img src="/images/logo.svg" alt="" class="img-fluid" />
          <p>We help you move your things.</p>
          <div className={css?.socialLinksDiv}>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/wemoovez")
              }
            >
              <FaFacebook />
            </button>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href = "https://www.instagram.com/wemoovez/")
              }
            >
              <FaInstagram />
            </button>
            <button
              className={css?.socialLinks}
              onClick={() =>
                (window.location.href =
                  "https://www.linkedin.com/company/moovez/")
              }
            >
              <i class="fab fa-linkedin-in"></i>
            </button>
            <button className={css?.socialLinks} href="#">
              <FaTwitter />
            </button>
          </div>
        </div>
        {width > 600 ? (
          <>
            <div className={css?.footerCenterDiv}>
              <div className={css?.centerHeading}>Company info</div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/howItWorks")}
              >
                How it works
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/help")}
              >
                How can we help
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/ContactUs")}
              >
                Contact us
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/FAQs")}
              >
                FAQ
              </div>
            </div>
            <div className={css?.footerCenterDiv}>
              <div className={css?.centerHeading}>Quick links</div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/Login")}
              >
                Login
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/Signup")}
              >
                Signup
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/OurStory")}
              >
                Our story
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/Earnings")}
              >
                Earnings
              </div>
            </div>
          </>
        ) : (
          <>
            <details className={css?.centerDetails}>
              <summary className={css?.centerDetailsHeading}>
                Company Info
              </summary>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/howItWorks")}
              >
                How it works
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/help")}
              >
                How can we help
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/ContactUs")}
              >
                Contact Us
              </div>
            </details>

            <details className={css?.centerDetails}>
              <summary className={css?.centerDetailsHeading}>
                Quick Links
              </summary>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/Login")}
              >
                Login
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/Signup")}
              >
                Signup
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/OurStory")}
              >
                Our story
              </div>
              <div
                className={css?.centerData}
                onClick={() => history.push("/Driver/FAQs")}
              >
                FAQ
              </div>
            </details>
          </>
        )}
      </div>
      <div className={css?.copyrightDiv}>
        <p>
          Copyright © moovez.{" "}
          <button
            className={css?.bottomLine}
            onClick={() => history.push("/privacy")}
          >
            Read our Privacy Policy
          </button>{" "}
        </p>
      </div>
    </div>
  );
};

export default NewFooter;
