import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const BackgroundCheck = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");
  const [Profile, setProfile] = useState();
  const [ProfileImg, setProfileImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e.target.files?.length) {
      setProfile(e.target.files[0]);
      setProfileImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitForm = () => {
    if (Profile) {
      const formData = new FormData();
      formData.append("backgroundCheck", Profile);
      postDocumentsMobile(id, type, formData, setError, history);
    } else {
      toast.error("Please upload your background check.");
    }
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <>
          <label htmlFor="profile-photo">
            <div className={css?.dlUploadMd}>
              <div className={css?.l2Heading}>Click below to take photo</div>
              <div className={css?.dlUploadImgBG}>
                <img
                  src={ProfileImg ?? "/images/upload.png"}
                  className={ProfileImg ? css?.UploadImg : css?.dlUploadImg}
                />
              </div>
            </div>
          </label>
          <input
            id="profile-photo"
            name="backgroundCheck"
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
          <ErrorComponent error={error} />
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Confirm
          </div>
        </>
      ) : (
        <>
          <div className={css?.mHeading}>
            Take a photo of your background check
          </div>
          <div className={css?.mtext}>
            Please upload a copy of your background check results here.
          </div>
          <div className={css?.imageDiv} style={{ margin: "30px 0" }}>
            <img src="/images/backgroundCheck.png" className={css?.dlImage} />
          </div>
          <div className={css?.submitButton} onClick={() => setTakePhoto(true)}>
            Take Photo
          </div>
        </>
      )}
    </div>
  );
};

export default BackgroundCheck;
