import React, { useState, useEffect } from "react";
import css from "../../style/loginStyles.module.css";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { driverLogin } from "../../store/actions/loginActions";
import ModalComponent from "../../components/Modals/Modal";

function MoovezLogin() {
  // catching errors
  const [error, seterror] = useState();
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  // email
  const [email, setemail] = useState("");
  const [emailActive, setemailActive] = useState(false);
  // password
  const [password, setpassword] = useState("");
  const [passwordActive, setpasswordActive] = useState(false);
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });
  // terms and conditions
  const [TnC, setTnC] = useState(false);
  // Sign In
  const handleLogin = () => {
    if (email && password) {
      const loginData = {
        email: email,
        password: password,
      };
      console.log("login data from signup component", loginData);
      dispatch(driverLogin(loginData, seterror, history));
    } else {
      alert("Please fill all the fields");
    }
  };

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);
  return (
    <div className={css?.loginMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.loginForm}>
        <img
          src="/images/Moovez-Dark.svg"
          alt=""
          className={css?.signupImage}
        />
        <div className={css?.posterMainHeading}>Welcome back to moovez!</div>
        <div className={css?.posterText}>Let’s start mooving.</div>
        <input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={password}
          type="password"
          placeholder="Password"
          onChange={(e) => setpassword(e?.target?.value)}
          className={css?.fullInput}
        ></input>
        {/* handleLogin */}
        <div className={css?.TnCMainDiv}>
          <input
            type="checkbox"
            checked={TnC}
            onChange={(e) => setTnC(e.target.checked)}
            className={css?.TnCCheckbox}
          ></input>
          Keep me signed in
        </div>
        <div className={css?.signupBtnMainDiv}>
          <button className={css?.signupBtn} onClick={handleLogin}>
            Log in
          </button>
          <div
            className={css?.signinTxt}
            onClick={() => history.push("/Driver/ForgotPassword")}
          >
            Forgot password?{" "}
          </div>
        </div>
        <div className={css?.signinRedirect}>
          Don't have an account?{" "}
          <span
            className={css?.signinTxt}
            style={{ fontWeight: 500 }}
            onClick={() => history.push("/Driver/Signup")}
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
}

export default MoovezLogin;
