import React from "react";
import Layout from "../../layouts/index";
import DriverLicenseScreen from "../../screens/onbording/DriverLicense";

const DriverLicense = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <DriverLicenseScreen />
    </Layout>
  );
};

export default DriverLicense;
