import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import css from "../../style/onboardingweb.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { getStatus } from "../../store/actions/onboardingActions";
import { CircularProgress } from "@material-ui/core";
import { list as optionsList } from "../../utils/onboardingConstants";
import ErrorComponent from "../../components/ErrorComponent";
const Index = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState();
  const [list, setList] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const gotoPage = (item) => {
    if (item.isCompleted) return;
    history.push(`/Driver/web/${item.route}/${status.mooverType}/${id}`);
  };

  const handleCheckIfCompleted = (item) => {
    if (Array.isArray(item.propertyNames) && item.propertyNames.length) {
      const notUploaded = item.propertyNames.filter((name) => !!status[name]);
      return !!notUploaded.length;
    } else return !!status[item.propertyName];
  };

  useEffect(() => {
    if (Array.isArray(list) && list.length) {
      let incompletedTasks = 0;
      list.forEach((l) => {
        if (!l.isCompleted) incompletedTasks += 1;
      });
      if (incompletedTasks === 0) history.push("/driver/welcomePage");
    }
  }, [list]);

  useEffect(() => {
    if (id) getStatus(id, setStatus, setError, setLoading);
  }, [id]);

  useEffect(() => {
    if (status)
      setList(
        optionsList.reduce(
          (newList, item) =>
            item.permissions.includes(status.mooverType)
              ? [
                  ...newList,
                  { ...item, isCompleted: handleCheckIfCompleted(item) },
                ]
              : newList,
          []
        )
      );
  }, [status]);

  return (
    <div className={css.mD}>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <ErrorComponent error={error} />
      ) : (
        <div>
          <h2 className={css.mHeading}>Required steps</h2>
          <p className={css.l3Heading}>
            Please complete these steps to set up your account.
          </p>
          <div className={css.listMD}>
            {list.map((item) => (
              <div
                className={css.listBox}
                style={{
                  backgroundColor: item.isCompleted
                    ? "var(--primaryDark)"
                    : "white",
                  color: item.isCompleted ? "white" : "var(--grey)",
                }}
                onClick={() => gotoPage(item)}
              >
                <div className={css.listBoxText}>{item.title}</div>
                <div className={css.listBoxIcon}>
                  {item.isCompleted ? (
                    <FaCheckCircle style={{ color: "var(--secondary)" }} />
                  ) : (
                    <FiChevronRight />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
