import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import LegalAgreementText from "../../constants/idcAgreement";
import ErrorComponent from "../../components/ErrorComponent";

const LegalAgreement = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");

  const handleSubmitForm = () => {
    const formData = new FormData();
    postDocumentsMobile(id, type, formData, setError, history, true, false);
  };
  const handleCalculateBulletMargin = (type) => {
    let margin = 0;
    let factor = 15;
    if (type === "B2" || type === "B2U") margin = factor * 1;
    if (type === "B3") margin = factor * 2;
    if (type === "B4") margin = factor * 3;
    if (type === "B5") margin = factor * 4;
    return margin;
  };

  const handleReturnStyleForTextMD = (textObject) => {
    const cssObj = { margin: "20px 0" };
    if (
      ["B1", "B2", "B3", "B4", "B5", "B1U", "B2U"].includes(textObject.type)
    ) {
      cssObj.display = "flex";
      cssObj.marginLeft = handleCalculateBulletMargin(textObject.type);
      if (["B1U", "B2U"].includes(textObject.type)) {
        cssObj.textDecoration = "underline";
        cssObj.cursor = "pointer";
        cssObj.color = "var(--primary)";
      }
    } else if (["MH", "H1", "H1U"].includes(textObject.type)) {
      cssObj.margin = "30px 0";
      cssObj.fontWeight = "500";
      cssObj.fontSize = "120%";
      cssObj.color = "var(--primary)";
      if (textObject.type === "H1U") cssObj.textDecoration = "underline";
      else if (textObject.type === "MH") cssObj.fontSize = "150%";
    } else if (textObject.type === "IT1") cssObj.fontStyle = "italic";
    return cssObj;
  };

  const handleReturnStyleBulletMd = (textObj) => {
    const cssObj = {};
    if (["B1", "B2", "B3", "B4", "B5", "B1U", "B2U"].includes(textObj.type)) {
      cssObj.width = 20;
      cssObj.paddingRight = 20;
    } else cssObj.display = "none";
    if (textObj.bolded) cssObj.fontWeight = "500";
    if (!textObj.number) cssObj.color = "transparent";
    return cssObj;
  };

  const handleReturnStyleText = (textObj) => {
    const cssObj = {};
    if (["B1U", "B2U"].includes(textObj.type)) {
      cssObj.textDecoration = "underline";
    }
    return cssObj;
  };
  return (
    <div className={css?.tnCMd}>
      <div className={css?.tnCText}>
        {LegalAgreementText.map((textObject) => (
          <div style={handleReturnStyleForTextMD(textObject)}>
            <div style={handleReturnStyleBulletMd(textObject)}>
              {textObject.number}.
            </div>
            <div style={handleReturnStyleText(textObject)}>
              {textObject.bolded ? (
                <span style={{ fontWeight: 500 }}>{textObject.bolded}</span>
              ) : (
                ""
              )}
              {textObject.content}
            </div>
          </div>
        ))}
      </div>
      <ErrorComponent error={error} />
      <div className={css?.submitButton} onClick={handleSubmitForm}>
        Yes, I agree
      </div>
    </div>
  );
};

export default LegalAgreement;
