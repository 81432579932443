import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Home.module.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { vehicleSpecsImageURL } from "../../store/constants/baseUrl";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

export default function SectionC() {
  // dispatch redux actions
  const dispatch = useDispatch();
  // height width
  const [hight, width] = useWindowSize();
  // calling vehicle types and delivery slots API
  useEffect(() => {
    try {
      dispatch(getAllVehicles());
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  // carousel counter
  const [counter, setcounter] = useState(1);

  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );
  useEffect(() => {
    try {
      console.log(vehicleTypesInfo);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleTypesInfo]);

  const handleReturnBG = (active, inactive) => {
    try {
      if (width > 600) {
        if (counter < vehicleTypesInfo?.length - 3) {
          return active;
        } else {
          return inactive;
        }
      } else {
        if (counter === vehicleTypesInfo?.length) {
          return inactive;
        } else {
          return active;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleReturnBG2 = (active, inactive) => {
    try {
      if (counter !== 1) {
        return active;
      } else {
        return inactive;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.S2MainDiv}>
      {vehicleTypesInfo &&
        Array.isArray(vehicleTypesInfo) &&
        vehicleTypesInfo?.length > 0 && (
          <CarouselProvider
            naturalSlideWidth={100}
            totalSlides={vehicleTypesInfo?.length}
            visibleSlides={width > 600 ? 4 : 1}
          >
            <div className={css?.carouselTxtMainDiv}>
              <div className={css?.S2Heading}>What vehicle do you drive?</div>
              <div className={css?.S2Txt}>
                Use your pickup truck, cargo van or moving truck to make great
                money on your schedule.
              </div>
            </div>
            <div className={css?.S2BtnDiv}>
              <ButtonBack
                className={css?.S2Icons}
                onClick={() => {
                  try {
                    if (counter > 1) {
                      setcounter(counter - 1);
                    }
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
                style={{
                  backgroundColor: handleReturnBG2(
                    "var(--secondary)",
                    "#c0faa5"
                  ),
                }}
              >
                <WestIcon />
              </ButtonBack>
              <ButtonNext
                className={css?.S2Icons}
                onClick={() => {
                  try {
                    if (counter < vehicleTypesInfo?.length) {
                      setcounter(counter + 1);
                    }
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                  }
                }}
                style={{
                  backgroundColor: handleReturnBG(
                    "var(--secondary)",
                    "#c0faa5"
                  ),
                }}
              >
                <EastIcon />
              </ButtonNext>
            </div>
            <Slider className={css?.vehicleSlider}>
              {vehicleTypesInfo?.map((vehicle, index) => (
                <Slide index={index} className={css?.vehicleCard}>
                  <img
                    src={vehicleSpecsImageURL + vehicle?.pictureUrl}
                    className={css?.vehicleImageImg}
                    alt="vehicle"
                  />
                  <div className={css?.vehicleName}>{vehicle?.title}</div>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        )}
    </div>
  );
}
