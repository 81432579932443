import React from "react";
import Layout from "../../layouts/index";
import LegalAgreementScreen from "../../screens/onbording/LegalAgreement";

const LegalAgreement = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <LegalAgreementScreen />
    </Layout>
  );
};

export default LegalAgreement;
