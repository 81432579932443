import React, { useState } from "react";
import css from "../../style/onboardingweb.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsWeb } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const StarterKit = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const starterKitItems = [
    { name: "dolly", property: "starterkitDolly" },
    { name: "Straps", property: "starterkitStraps" },
    { name: "Moving Blankets", property: "starterkitBlankets" },
    { name: "Gloves", property: "starterkitGloves" },
  ];

  const [error, setError] = useState("");
  const [assets, setAssets] = useState({
    starterkitDolly: null,
    starterkitStraps: null,
    starterkitGloves: null,
    starterkitBlankets: null,
  });
  const [image, setImage] = useState({
    starterkitDolly: null,
    starterkitStraps: null,
    starterkitGloves: null,
    starterkitBlankets: null,
  });
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name && e.target.files?.length) {
      setAssets({ ...assets, [e?.target?.name]: e.target.files[0] });
      setImage({
        ...image,
        [e?.target?.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    let err = 0;
    starterKitItems.forEach((item) => {
      if (!assets[item.property]) err += 1;
      formData.append(item.property, assets[item.property]);
    });
    if (err) {
      toast.error("Please upload all documents.");
      return;
    }
    postDocumentsWeb(id, type, formData, setError, history);
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <div className={css?.skImages}>
              {starterKitItems.map((item) => (
                <>
                  <label htmlFor={item.property} className={css?.skLabMD}>
                    <div className={css?.skUploadMd}>
                      <h4
                        className={css?.l2Heading}
                        title={item.name}
                        style={{
                          margin: "10px 0",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </h4>
                      <div className={css?.skUploadImgBG}>
                        <img
                          src={image[item.property] ?? "/images/upload.png"}
                          className={css?.sKUploadImg}
                        />
                      </div>
                    </div>
                  </label>
                  <input
                    id={item.property}
                    name={item.property}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={handleAddImage}
                    style={{ display: "none" }}
                  />
                </>
              ))}
            </div>
            <ErrorComponent error={error} />
          </div>
          <div className={css?.buttonPanel}>
            <div className={css?.submitButton} onClick={handleSubmitForm}>
              Confirm
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <h3 className={css?.mHeading}>
              Take a photo of your basic equipment
            </h3>
            <div className={css?.mtext}>
              This goes without saying but basic equipment is required before
              you can start accepting jobs on our platform. Please upload a
              picture of each item listed below Dolly, straps, moving blankets
              and gloves.
            </div>
            <div className={css?.skImages}>
              {starterKitItems.map((item) => (
                <>
                  <label className={css?.skLabMD}>
                    <div className={css?.skUploadMd}>
                      <h4
                        className={css?.l2Heading}
                        title={item.name}
                        style={{
                          margin: "10px 0",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </h4>
                      <div className={css?.skUploadImgBG}>
                        <img
                          src={`/images/${item.property}.png`}
                          className={css?.sKUploadImg}
                        />
                      </div>
                    </div>
                  </label>
                </>
              ))}
            </div>
          </div>
          <div className={css?.buttonPanel}>
            <div
              className={css?.submitButton}
              onClick={() => setTakePhoto(true)}
            >
              Take Photos
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StarterKit;
