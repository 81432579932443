import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { GoPrimitiveDot } from "react-icons/go";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const ProfilePhoto = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const bullets = [
    "Show your whole face and tops of you shoulders",
    "Take your sunglasses and hat off.",
    "Take your photo in a well-lit place",
  ];

  const [error, setError] = useState("");
  const [Profile, setProfile] = useState();
  const [ProfileImg, setProfileImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e.target.files?.length) {
      setProfile(e.target.files[0]);
      setProfileImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitForm = () => {
    if (Profile) {
      const formData = new FormData();
      formData.append("profilePhoto", Profile);
      postDocumentsMobile(id, type, formData, setError, history);
    } else {
      toast.error("Please upload your profile picture.");
    }
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <>
          <label htmlFor="profile-photo">
            <div className={css?.dlUploadMd}>
              <div className={css?.l2Heading}>Click below to take photo</div>
              <div className={css?.dlUploadImgBG}>
                <img
                  src={ProfileImg ?? "/images/upload.png"}
                  className={ProfileImg ? css?.UploadImg : css?.dlUploadImg}
                />
              </div>
            </div>
          </label>
          <input
            id="profile-photo"
            name="profilePhoto"
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
          <ErrorComponent error={error} />
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Confirm
          </div>
        </>
      ) : (
        <>
          <div className={css?.mHeading}>Take a profile photo</div>
          <div className={css?.mtext}>
            Driver’s license requirements vary based on location and size of
            vehicle. You must be at least 21 years old to operate as a driver on
            our platform. Swampers / helpers have different age requirements.
          </div>
          <div className={css?.imageDiv}>
            <img src="/images/profilePhoto.png" className={css?.ppImage} />
          </div>
          <div className={css?.bulletPoints}>
            <div className={css?.l2Heading} style={{ textAlign: "left" }}>
              Photo Requirements
            </div>
            {bullets.map((item) => (
              <div className={css?.bulletPoint}>
                <GoPrimitiveDot
                  style={{
                    color: "var(--primary)",
                    margin: "0 5px 0 0",
                    fontSize: 14,
                  }}
                />
                {item}
              </div>
            ))}
          </div>
          <div className={css?.submitButton} onClick={() => setTakePhoto(true)}>
            Take Photo
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePhoto;
