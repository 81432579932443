import React, { useState } from "react";
import css from "../../style/onboardingweb.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsWeb } from "../../store/actions/onboardingActions";
import TermsOfServicesText from "../../constants/tnC";
import ErrorComponent from "../../components/ErrorComponent";

const TermsOfServices = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");

  const handleCalculateBulletMargin = (type) => {
    let margin = 0;
    let factor = 15;
    if (type === "B2" || type === "B2U") margin = factor * 1;
    if (type === "B3") margin = factor * 2;
    if (type === "B4") margin = factor * 3;
    if (type === "B5") margin = factor * 4;
    return margin;
  };

  const handleReturnStyleForTextMD = (textObject) => {
    const cssObj = { margin: "20px 0", color: "var(--text2)" };
    if (
      ["B1", "B2", "B3", "B4", "B5", "B1U", "B2U"].includes(textObject.type)
    ) {
      cssObj.display = "flex";
      cssObj.marginLeft = handleCalculateBulletMargin(textObject.type);
      if (["B1U", "B2U"].includes(textObject.type)) {
        cssObj.textDecoration = "underline";
        cssObj.cursor = "pointer";
        cssObj.color = "var(--primary)";
      }
    } else if (["MH", "H1", "H1U"].includes(textObject.type)) {
      // debugger;
      cssObj.margin = "30px 0";
      cssObj.fontWeight = "500";
      cssObj.fontSize = "120%";
      cssObj.color = "var(--primary)";
      if (textObject.type === "H1U") cssObj.textDecoration = "underline";
      else if (textObject.type === "MH") cssObj.fontSize = "150%";
    } else if (textObject.type === "IT1") cssObj.fontStyle = "italic";
    return cssObj;
  };

  const handleReturnStyleBulletMd = (textObj) => {
    const cssObj = { color: "var(--text2)" };
    if (["B1", "B2", "B3", "B4", "B5", "B1U", "B2U"].includes(textObj.type)) {
      cssObj.width = 20;
      // cssObj.paddingLeft = handleCalculateBulletMargin(textObj.type);
      cssObj.paddingRight = 20;
    } else cssObj.display = "none";
    if (textObj.bolded) cssObj.fontWeight = "500";
    if (!textObj.number) cssObj.color = "transparent";
    return cssObj;
  };

  const handleReturnStyleText = (textObj) => {
    const cssObj = {};
    if (["B1U", "B2U"].includes(textObj.type)) {
      cssObj.textDecoration = "underline";
    }
    return cssObj;
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    postDocumentsWeb(id, type, formData, setError, history, false, true);
  };
  return (
    <div className={css?.tnCMd}>
      <div className={css?.centerDiv}>
        <div className={css?.tnCText}>
          {TermsOfServicesText.map((textObject) => (
            <div style={handleReturnStyleForTextMD(textObject)}>
              <div style={handleReturnStyleBulletMd(textObject)}>
                {textObject.number}.
              </div>
              <div style={handleReturnStyleText(textObject)}>
                {textObject.bolded ? (
                  <span style={{ fontWeight: 500 }}>{textObject.bolded}</span>
                ) : (
                  ""
                )}
                {textObject.content}
              </div>
            </div>
          ))}
          <ErrorComponent error={error} />
        </div>
        <div className={css?.buttonPanel}>
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Yes, I agree
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServices;
