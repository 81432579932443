import React from "react";
import Layout from "../../layouts/index";
import IndexScreen from "../../screens/onbording_web/Index";

const Index = () => {
  return (
    <Layout>
      <IndexScreen />
    </Layout>
  );
};

export default Index;
