import React from "react";
import Layout from "../../layouts/index";
import ProfilePhotoScreen from "../../screens/onbording_web/ProfilePhoto";

const ProfilePhoto = () => {
  return (
    <Layout>
      <ProfilePhotoScreen />
    </Layout>
  );
};

export default ProfilePhoto;
