import React from "react";
import Layout from "../layouts";
import MyTripsScreen from "../screens/Booking/MyTrips";

const myTrips = () => {
  return (
    <Layout>
      <MyTripsScreen />
    </Layout>
  );
};

export default myTrips;
