import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
} from "../constants/LoginConstants";

// used for post content
export const loginReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginInfo: null,
        userLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("moovezDriverProfile", JSON.stringify(payload));
      return {
        ...state,
        userLoading: false,
        loginInfo: payload,
        loginError: "",
      };
    case LOGIN_FAIL:
      return {
        ...state,
        userLoading: false,
        loginError: payload,
      };
    case LOGOUT:
      localStorage.removeItem("moovezDriverProfile");
      return {
        ...state,
        loginInfo: null,
        userLoading: false,
        loginError: null,
      };
    default:
      return state;
  }
};
