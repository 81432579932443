import React from "react";
import Layout from "../layouts";
import DriverProfileScreen from "../screens/Accounts/DriverProfile";

const DriverProfile = () => {
  return (
    <Layout>
      <DriverProfileScreen />
    </Layout>
  );
};

export default DriverProfile;
