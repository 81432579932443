import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
} from "../constants/LoginConstants";
import axios from "axios";
import baseUrl from "../constants/baseUrl";

export const logout = (history) => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
    history.push("/");
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOGIN_FAIL,
      payload: "Logout Failed,Please try again!",
    });
  }
};
//update driver
// update driver profile action
// this action is called in driver Profile page
export const updateUser = (userData) => async (dispatch) => {
  console.log("new user cred", userData);
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const { data } = await axios.post(`${baseUrl}Auth/login`, userData);
    console.log("user data from api", data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOGIN_FAIL,
      payload: "update Failed,Please try again!",
    });
  }
};

// login action
// this action is called in Login
// it user's profile is present in localstorage, log user in
export const populateUser = (userCredentials, history) => async (dispatch) => {
  console.log("user cred", userCredentials);
  try {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: userCredentials,
    });
    const path = window.location.pathname;
    if (path === "/DriverSignup" || path === "/DriverSignin") {
      history.push("/");
    }
  } catch (error) {
    console.log(error);
  }
};

// driver login action
// this action is called in MoovezLogin page
// it redirects to home page
export const driverLogin =
  (loginData, setError, history) => async (dispatch) => {
    console.log("login cred", loginData);
    try {
      setError("");
      dispatch({
        type: LOGIN_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}Auth/loginDriver`,
        loginData
      );
      console.log("login data from api", data);
      window.localStorage.setItem(
        "wemoovezdrivertoken",
        "Bearer " + data?.token
      );
      if (!data?.data?.isVerified)
        history.push(`/Driver/ConfirmEmail/` + loginData?.email);
      else if (!data?.data?.allDocsUploaded)
        history.push("/Driver/web/Registration/" + data.data.id);
      else if (!data?.data?.isApproved) history.push("/driver/welcomePage");
      else if (!data?.data?.isStripeAccountCreated)
        window.open(data?.onboardingLink, "_blank");
      else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data?.data,
        });
        history.push(`/`);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: LOGIN_FAIL,
        payload: "Login Failed,Please try again!",
      });
      setError(
        error?.response?.data?.message ?? "Login Failed,Please try again!"
      );
    }
  };

// driver signup action
// this action is called in DriverSignup page
// it redirects to home page
export const driverSignup =
  (loginData, setError, history) => async (dispatch) => {
    console.log("signup cred", loginData);
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      const { data } = await axios.post(
        `${baseUrl}Auth/signupMoover`,
        // config,
        loginData
      );
      console.log("signup data from api", data);
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: data?.data,
      // });
      console.log("token", data?.token);
      window.localStorage.setItem(
        "wemoovezdrivertoken",
        "Bearer " + data?.token
      );
      history.push(`/Driver/confirmEmail/${loginData?.email}`);
    } catch (error) {
      console.log(error);
      dispatch({
        type: LOGIN_FAIL,
        payload: "Signup Failed,Please try again!",
      });
      setError(
        error?.response?.data?.message ?? "Signup Failed,Please try again!"
      );
    }
  };

// confirm email action
// this action is called i confirm email page
export const ConfirmEmailAction =
  (email, code, history) => async (dispatch) => {
    try {
      const token = localStorage.getItem("wemoovezdrivertoken");
      console.log("token", token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const { data } = await axios.post(
        `${baseUrl}Auth/emailConfirmationCode`,
        {
          email,
          code,
        },
        config
      );

      history.push("/Driver/web/Registration/" + data.id);
    } catch (error) {
      console.log("verification failed", error);
      dispatch({
        type: LOGIN_FAIL,
        payload: "Email verification failed,Please try again!",
      });
    }
  };
