import {
  GET_DRIVER_PROFILE_FAIL,
  GET_DRIVER_PROFILE_SUCCESS,
  GET_DRIVER_PROFILE_REQUEST,
} from "../constants/driverProfileConstants";
import axios from "axios";
import baseUrl from "../constants/baseUrl";
import { returnAuthHeader } from "../../utils/auth";

// post Driver profile action
// this action is called in Driver profile page
export const postDriverProfile = (DriverProfile) => async (dispatch) => {
  try {
    console.log("profile is updating", DriverProfile);
    const { data } = await axios.post(
      `${baseUrl}DriverDetailAPI/saveDriverDetail`,
      DriverProfile
    );
    console.log("profile data from api", data);
    dispatch({
      type: GET_DRIVER_PROFILE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_DRIVER_PROFILE_FAIL,
      payload: "post Driver profile API Failed,Please try again!",
    });
  }
};

// get Driver profile action
// this action is called in Driver profile page
export const getDriverProfile = (DriverEmail) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DRIVER_PROFILE_REQUEST,
    });
    const { data } = await axios.get(
      `${baseUrl}DriverDetailAPI/${DriverEmail}`,
      returnAuthHeader()
    );
    console.log("Driver profile data from api", data);
    dispatch({
      type: GET_DRIVER_PROFILE_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_DRIVER_PROFILE_FAIL,
      payload: "Driver profile API Failed,Please try again!",
    });
  }
};
// post Driver image action
// this action is called in Driver profile pages
export const postProfileImage =
  (DriverId, DriverEmail, ProfileImage) => async (dispatch) => {
    try {
      console.log("file from frontend", ProfileImage);
      const datax = await axios.post(
        `${baseUrl}DriverDetailAPI/driverProfileImage?id=${DriverId}`,
        ProfileImage,
        returnAuthHeader()
      );
      const { data } = await axios.get(
        `${baseUrl}DriverDetailAPI/${DriverEmail}`,
        returnAuthHeader()
      );
      console.log("profile image data from api", data);
      dispatch({
        type: GET_DRIVER_PROFILE_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
