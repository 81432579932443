import React from "react";
import css from "../../style/earning.module.css";
import { BiPlusMedical } from "react-icons/bi";
import { FaEquals } from "react-icons/fa";
import * as Sentry from "@sentry/react";

const Section2_Breakdown_of_earning = () => {
  const jobs = [
    {
      title: "Delivery job earnings",
      jobs: [
        {
          title: "Base rate",
          description:
            "Each vehicle category has a different base rate. Larger vehicles have a higher base rate. The difference is to account for higher operating costs of larger vehicles.",
        },
        {
          title: "Distance",
          description:
            "Get paid for each kilometer you drive. Customers pay $0.90 per km.",
        },
        {
          title: "Labor fee",
          description:
            "Get paid for your muscle. Customers select the # of moovers required & if it’s a 1/2 load or full load. Each vehicle category has a set labor fee. Refer to our labor chart for more info.",
        },
        {
          title: "Tips",
          description:
            "You keep 100% of all tips. Customers will be encouraged to tip for good service after every delivery is completed.",
        },
        {
          title: "Total",
          description:
            "You keep 80% on all jobs plus 100% of tips. Hourly pay is dependent on job availability and productivity. Any additional costs like landfill fees will be charged to the customer.",
        },
      ],
    },
    {
      title: "Mooving/Moover job earnings",
      jobs: [
        {
          title: "Hourly rate",
          description:
            "Customers are charged an hourly rate. The moover app will help track the total work time. Each vehicle size has a set hourly rate for the required # of moovers.",
        },
        {
          title: "Tips",
          description:
            "You keep 100% of all tips. Customers will be encouraged to tip for good service after every job has been successfully completed.",
        },
        {
          title: "Total",
          description:
            "You keep 80% on all jobs plus 100% of tips. Any additional costs like landfill fees will be charged to the customer.",
        },
      ],
    },
  ];
  const handleReturnClass = (index, sindex, name) => {
    try {
      if (sindex === 0) {
        if (index === 4) return name + "Eq";
        else return name;
      } else {
        if (index === 2) return name + "Eq2";
        else return name + "2";
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  return (
    <div className={css?.s2Md}>
      <div className={css?.s2Heading}>A breakdown of earnings by job type</div>
      {jobs?.map((type, sindex) => (
        <div className={css?.s2Section}>
          <div className={css?.s2SectionHeading}>{type.title}</div>
          <div className={css?.s2SectionCards}>
            {type.jobs.map((info, index) => (
              <>
                <div
                  className={
                    css[handleReturnClass(index, sindex, "s2SectionCardMd")]
                  }
                >
                  {index < type.jobs.length - 1 ? (
                    <div
                      className={
                        css[
                          handleReturnClass(
                            index + 1,
                            sindex,
                            "s2SectionCardOperator"
                          )
                        ]
                      }
                    >
                      {index < type.jobs.length - 2 ? (
                        <BiPlusMedical />
                      ) : (
                        <FaEquals />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={
                      css[
                        handleReturnClass(index, sindex, "s2SectionCardHeading")
                      ]
                    }
                  >
                    {info?.title}
                  </div>
                  <div
                    className={
                      css[handleReturnClass(index, sindex, "s2SectionCardText")]
                    }
                  >
                    {info?.description}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Section2_Breakdown_of_earning;
