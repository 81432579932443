import React, { useState } from "react";
import css from "../../style/onboardingweb.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsWeb } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import ErrorComponent from "../../components/ErrorComponent";
import ModalComponent from "../../components/Modals/BackgroundCheckModal";

const BackgroundCheck = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");
  const [Profile, setProfile] = useState();
  const [ProfileImg, setProfileImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleAddImage = (e) => {
    if (e.target.files?.length) {
      setProfile(e.target.files[0]);
      setProfileImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitForm = () => {
    if (Profile) {
      const formData = new FormData();
      formData.append("backgroundCheck", Profile);
      postDocumentsWeb(id, type, formData, setError, history);
    } else {
      toast.error("Please upload your background check.");
    }
  };
  return (
    <div className={css?.mD}>
      <ModalComponent
        text={
          "This is a criminal history screening through a third-party vendor. The screening will also include a driving history check. Background screenings expire after 90 days from the date of issue. Please follow the links below to complete the screening process:"
        }
        note="Note that the screening process may experience some delays."
        links={[
          {
            name: "Background check in Canada",
            link: "https://www.tritoncanada.ca/",
          },
          {
            name: "Background check for employment",
            link: "https://www.isbglobalservices.com/services/background-check-solutions/background-check-for-employment/",
          },
        ]}
        openState={openModal}
        closeFunction={() => {
          setOpenModal(false);
        }}
        btnText1={"Okay"}
        btnClickFunc1={() => {
          setOpenModal(false);
        }}
        isSmall={true}
      />
      {takePhoto ? (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <label htmlFor="profile-photo">
              <div className={css?.dlUploadMd}>
                <h4 className={css?.l2Heading}>Click below to take photo</h4>
                <div className={css?.dlUploadImgBG}>
                  <img
                    src={ProfileImg ?? "/images/upload.png"}
                    className={ProfileImg ? css?.UploadImg : css?.dlUploadImg}
                  />
                </div>
              </div>
            </label>
            <input
              id="profile-photo"
              name="backgroundCheck"
              type="file"
              accept="image/*"
              capture="camera"
              onChange={handleAddImage}
              style={{ display: "none" }}
            />
            <ErrorComponent error={error} />
          </div>
          <div className={css?.buttonPanel}>
            <div className={css?.submitButton} onClick={handleSubmitForm}>
              Confirm
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <h3 className={css?.mHeading}>
              Take a photo of your background check
            </h3>
            <div className={css?.mtext}>
              Please upload a copy of your background check results here.
            </div>
            <div className={css?.infoDiv}>
              <FaInfoCircle
                className={css?.infoIcon}
                onClick={() => {
                  setOpenModal(true);
                }}
              />
              What is this?
            </div>
            <div className={css?.imageDiv} style={{ margin: "30px 0" }}>
              <img src="/images/backgroundCheck.png" className={css?.dlImage} />
            </div>
          </div>
          <div className={css?.buttonPanel}>
            <div
              className={css?.submitButton}
              onClick={() => setTakePhoto(true)}
            >
              Take Photo
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BackgroundCheck;
