import React from "react";
import Layout from "../../layouts/index";
import BusinessLicenseScreen from "../../screens/onbording_web/BusinessLicense";

const BusinessLicense = () => {
  return (
    <Layout>
      <BusinessLicenseScreen />
    </Layout>
  );
};

export default BusinessLicense;
