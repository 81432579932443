import {
  GET_DRIVER_PROFILE_FAIL,
  GET_DRIVER_PROFILE_SUCCESS,
  GET_DRIVER_PROFILE_REQUEST,
} from "../constants/driverProfileConstants";

// used for driverProfile
export const driverProfile = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DRIVER_PROFILE_REQUEST:
      return {
        ...state,
        driverProfileInfo: null,
        driverProfileLoading: true,
      };
    case GET_DRIVER_PROFILE_SUCCESS:
      return {
        ...state,
        driverProfileLoading: false,
        driverProfileInfo: payload,
        driverProfileError: "",
      };
    case GET_DRIVER_PROFILE_FAIL:
      return {
        ...state,
        driverProfileLoading: false,
        driverProfileError: payload,
      };
    default:
      return state;
  }
};
