import React from "react";
import css from "../../style/Home.module.css";

const Section4 = () => {
  return (
    <div className={css?.S4M}>
      <div className={css?.s4L}>
        <div className={css?.s4Heading}>
          Are you a small to medium
          <br /> sized moving company?
        </div>
        <div className={css?.s4Text}>
          Sign your business up with moovez. We work with local moving companies
          that value customer service. If you have gaps in your schedule and
          want to fill them with jobs, we can help keep you busy.
        </div>
      </div>
      <div className={css?.s4R}>
        <img
          className={css?.s4Image}
          src="/images/company_moving.svg"
          alt="Loading shipment"
        />
      </div>
    </div>
  );
};

export default Section4;
