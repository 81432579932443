import React from "react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router";
import css from "../../style/Help.module.css";

const Section3 = () => {
  const history = useHistory();
  return (
    <div className={css?.S3M}>
      <div className={css?.s3Card}>
        <div className={css?.s3CardLeft}>
          <div className={css?.s3CardLeftHeading}>
            Become a moover and start earning.
          </div>
          <div className={css?.s3CardLeftTxt}>
            Earn money helping people move their stuff.
          </div>
          <div
            className={css?.s3CardLeftBtn}
            onClick={() => {
              try {
                history.push("/Driver/MooverSignUp");
              } catch (error) {
                Sentry.captureException(error);
                console.log(error);
              }
            }}
          >
            Sign up now
          </div>
        </div>
        <img
          className={css?.s3Image}
          src="/images/driver.png"
          alt="Driver on the driving seat"
        />
      </div>
    </div>
  );
};

export default Section3;
