import React from "react";
import Layout from "../../layouts/index";
import VehicleInsuranceScreen from "../../screens/onbording/VehicleInsurance";

const VehicleInsurance = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <VehicleInsuranceScreen />
    </Layout>
  );
};

export default VehicleInsurance;
