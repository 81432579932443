import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layouts/index";
import css from "../style/driverRegistration.module.css";

import { postPaymentInformation } from "../store/actions/PaymentAction";

const Payments = () => {
  const [vehicleInformation, setvehicleInformation] = useState({
    insuranceFile: null,
    registrationFile: null,
    inspectionFileFront: null,
    inspectionFileBack: null,
    inspectionFileLeft: null,
    inspectionFileRight: null,
  });

  const [driverInformation, setdriverInformation] = useState({
    insuranceFile: null,
    registrationFile: null,
    inspectionFileFront: null,
    inspectionFileBack: null,
    inspectionFileLeft: null,
    inspectionFileRight: null,
  });
  const [vehicleType, setVehicleType] = useState();
  useEffect(() => {
    console.log(vehicleInformation);
  }, [vehicleInformation]);

  useEffect(() => {
    console.log(vehicleType);
  }, [vehicleType]);

  const [profileImage, setprofileImage] = useState("");

  const options = [
    {
      value: "minivan",
      id: "1",
      label: "minivan",
    },
    {
      value: "pickup Truck",
      id: "2",
      label: "pickup Truck",
    },
    {
      value: "Cargo Van",
      id: "3",
      label: "Cargo Van",
    },
    {
      value: "16 ft moving truck",
      id: "4",
      label: "16 ft moving truck",
    },
    {
      value: "26 ft moving truck",
      id: "5",
      label: "26 ft moving truck",
    },
    {
      value: "Cargo trailer",
      id: "6",
      label: "Cargo trailer",
    },
    {
      value: "Utility trailer",
      id: "7",
      label: "Utility trailer",
    },
    {
      value: "Other",
      id: "8",
      label: "Other",
    },
    // "16 ft moving truck","26 ft moving truck","Cargo Trailer","Utility Trailer", "Other"
  ];

  // change screens
  const [ChangeScreen, setChangeScreen] = useState(0);
  // dispatch actions
  const dispatch = useDispatch();
  // get loginInfo from reducer
  const { loginInfo } = useSelector((state) => state?.loginReducer);

  //upload Driver profile image
  // const handleMediaFileUpload = (e) => {
  //   console.log(e?.target?.files);
  //   const formData = new FormData();
  //   const file = e.target.files[0];
  //   formData.append(file?.type, file);

  //   // dispatch(postProfileImage(DriverObj?.id, DriverObj?.email, formData));
  // };
  //driverLicense,eligibilityProof,backgroundCheckFile
  const handlePayment = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    dispatch(postPaymentInformation(formData, loginInfo?.id));
  };

  return (
    <div className={css?.profileSettingsMainDiv}>
      <Layout>
        <div className={css?.profileSettingsInnerMain}>
          <div className={css?.sidePannel}>
            <div className={css?.sidePannelHeading}>Driver Registration</div>
            <div
              className={css?.sidePannelOptions}
              onClick={() => setChangeScreen(0)}
              style={{ background: ChangeScreen === 0 ? "#f4f4fc" : "#fafafa" }}
            >
              <span className={css?.sidePannelOptionsIcon}>
                <i class="fas fa-user-alt"></i>
              </span>
              <span className={css?.sidePannelOptionsText}>Payments</span>
            </div>
            {/* Commenting Vehicle Info for now */}
            {/* <div
              className={css?.sidePannelOptions}
              onClick={() => setChangeScreen(1)}
              style={{ background: ChangeScreen === 1 ? "#f4f4fc" : "#fafafa" }}
            >
              <span className={css?.sidePannelOptionsIcon}>
                <i class="far fa-credit-card"></i>
              </span>
              <span className={css?.sidePannelOptionsText}>
                Vehicle information
              </span>
            </div> */}
            <div
              className={css?.sidePannelOptions}
              onClick={() => setChangeScreen(2)}
              style={{ background: ChangeScreen === 2 ? "#f4f4fc" : "#fafafa" }}
            >
              <span className={css?.sidePannelOptionsIcon}>
                <i class="fas fa-link"></i>
              </span>
              <span className={css?.sidePannelOptionsText}>Payouts</span>
            </div>
          </div>
          {ChangeScreen === 1 ? (
            <div></div>
          ) : ChangeScreen === 2 ? (
            <form className={css?.accountDetails}>
              <div className={css?.accountDetailsHeading}>
                <div className={css?.accountDetailHeadingBackground}>
                  Payment information
                </div>
              </div>
              {/* Driver's license */}
              {/* <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Driver's license
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                   Driver's license requirements vary based on location. You must be 
                   21+ years old, have a valid Class 1,2 or 4 driver's license.
                  </div>
                </div>
                <div className={css?.accountDetailsBoxInputArea}>
                  <div className={css?.accountDetailsBoxInputBox}>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-profile-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-profile-image"
                      type="file"
                      accept="image/*"
                      name="driverLicense"
                      className={css?.uploadImageInput}
                    ></input>
                  </div>
                  <div className={css?.notSubmitted}>not Submitted</div>
                </div>
              </div> */}

              <input
                type="submit"
                className={css?.submitButton}
                value="submit"
              />
            </form>
          ) : (
            <form className={css?.accountDetails} onSubmit={handlePayment}>
              <div className={css?.accountDetailsHeading}>
                <div className={css?.accountDetailHeadingBackground}>
                  Payment information
                </div>
              </div>
              {/* Driver's license */}
              <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Payment Methods
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                    Add and manage your payment methods using our secure payment
                    system.
                  </div>
                </div>
                <div className={css?.accountDetailsBoxInputArea}>
                  <div className={css?.accountDetailsBoxInputBox}>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-profile-image"
                      className={css?.uploadImageSpan}
                    >
                      Remove Card
                    </label>
                    <input
                      id="upload-profile-image"
                      type="file"
                      accept="image/*"
                      name="driverLicense"
                      className={css?.uploadImageInput}
                    ></input>
                  </div>
                </div>
              </div>

              <input
                type="submit"
                className={css?.submitButton}
                value="submit"
              />
            </form>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Payments;
