import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { getRegistrationStatus } from "../../store/actions/driverRegistration";
import ErrorComponent from "../../components/ErrorComponent";
import css from "../../style/registrationStatus.module.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";

const steps = [
  {
    label: "Signup form",
    description: `Fill the signup form`,
  },
  {
    label: "Email confirmation",
    description: `A confirmation code has been sent to your email. Please enter that code in the next step in order to confirm your email.`,
  },
  {
    label: "Upload documents",
    description: `In order to be registered as a mover, you need to upload some required documents. Please make sure that all the pictures that you upload in this step are recent and clear.`,
  },
  {
    label: "Profile approval",
    description: `Our experts at Moovez will verify your profile and once verified, your profile would be approved. This process may take a few business days. In the mean time you can have a tour of our website.`,
  },
  {
    label: "Creation of stripe account",
    description: `Moovez uses stripe as their payment gateway and in order to get paid for your jobs, you must have a stripe account. This step will redirect you to stripe account creation link.`,
  },
];

function StepperComponent({ activeStep = 1, handleNext }) {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

const RegistrationStatus = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [status, setStatus] = useState();
  const [activeStep, setActiveStep] = useState();

  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  // const handle next
  const handleNext = () => {
    if (activeStep === 1)
      history.push(`/Driver/ConfirmEmail/` + status.data.email);
    if (activeStep === 2) history.push("/Driver/web/Registration/" + id);
    if (activeStep === 3) history.push("/");
    if (activeStep === 4) window.open(status.onboardingLink, "_blank");
  };
  useEffect(() => {
    if (id && search) {
      const params = new URLSearchParams(search);
      const token = params.get("token");
      localStorage.setItem("wemoovezdrivertoken", "Bearer " + token);
      getRegistrationStatus(id, setStatus, setError, setLoading);
    }
  }, [id, search]);

  useEffect(() => {
    let ac_step = 1;
    if (!status?.data) return;
    else if (!status.data.isVerified) ac_step = 1;
    else if (!status.data.allDocsUploaded) ac_step = 2;
    else if (!status.data.isApproved) ac_step = 3;
    else if (!status.data.isStripeAccountCreated) ac_step = 4;
    else ac_step = 5;
    setActiveStep(ac_step);
  }, [status]);
  return (
    <div className={css.mD}>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <ErrorComponent error={error} />
      ) : (
        <div>
          <StepperComponent handleNext={handleNext} activeStep={activeStep} />
          {activeStep === 6 ? (
            <Typography>
              <br></br>
              <br></br>You're all set.Please log in to moovez to start your
              journey as a moover!
            </Typography>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistrationStatus;
