import React from "react";
import css from "../style/availablePickups.module.css";

const filterOptions = ({ title, select }) => {
  return (
    <div className={css?.sideFilterOption}>
      <div className={css?.NameTags}>{title}</div>
      {select?.map((singleSelect) => (
        <select name={title} className={css?.SelectFilter}>
          {singleSelect?.map((option, index) => (
            <option
              value={index === 0 ? "" : option}
              className={css?.selectFilterOptions}
            >
              {option}
            </option>
          ))}
        </select>
      ))}
    </div>
  );
};

export default filterOptions;
