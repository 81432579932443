import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const StarterKit = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const starterKitItems = [
    { name: "dolly", property: "starterkitDolly" },
    { name: "Straps", property: "starterkitStraps" },
    { name: "Moving Blankets", property: "starterkitBlankets" },
    { name: "Gloves", property: "starterkitGloves" },
  ];

  const [error, setError] = useState("");
  const [assets, setAssets] = useState({
    starterkitDolly: null,
    starterkitStraps: null,
    starterkitGloves: null,
    starterkitBlankets: null,
  });
  const [image, setImage] = useState({
    starterkitDolly: null,
    starterkitStraps: null,
    starterkitGloves: null,
    starterkitBlankets: null,
  });
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name && e.target.files?.length) {
      setAssets({ ...assets, [e?.target?.name]: e.target.files[0] });
      setImage({
        ...image,
        [e?.target?.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    let err = 0;
    starterKitItems.forEach((item) => {
      if (!assets[item.property]) err += 1;
      formData.append(item.property, assets[item.property]);
    });
    if (err) {
      toast.error("Please upload all documents.");
      return;
    }
    postDocumentsMobile(id, type, formData, setError, history);
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <>
          {starterKitItems.map((item) => (
            <>
              <label htmlFor={item.property}>
                <div className={css?.skUploadMd}>
                  <div className={css?.l2Heading} style={{ margin: "10px 0" }}>
                    {item.name}
                  </div>
                  <div className={css?.skUploadImgBG}>
                    <img
                      src={image[item.property] ?? "/images/upload.png"}
                      className={
                        image[item.property] ? css?.UploadImg : css?.sKUploadImg
                      }
                    />
                  </div>
                </div>
              </label>
              <input
                id={item.property}
                name={item.property}
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleAddImage}
                style={{ display: "none" }}
              />
            </>
          ))}
          <ErrorComponent error={error} />
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Confirm
          </div>
        </>
      ) : (
        <>
          <div className={css?.mHeading}>
            Take a photo of your basic equipment
          </div>
          <div className={css?.mtext}>
            This goes without saying but basic equipment is required before you
            can start accepting jobs on our platform.
            <br />
            Please upload a picture of each item listed below Dolly, straps,
            moving blankets and gloves.
          </div>
          <div className={css?.imageDiv}>
            <img src="/images/SK.png" className={css?.weImage} />
          </div>
          <div className={css?.submitButton} onClick={() => setTakePhoto(true)}>
            Take Photos
          </div>
        </>
      )}
    </div>
  );
};

export default StarterKit;
