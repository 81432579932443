import React from "react";
import Layout from "../../layouts/index";
import BusinessLicenseScreen from "../../screens/onbording/BusinessLicense";

const BusinessLicense = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <BusinessLicenseScreen />
    </Layout>
  );
};

export default BusinessLicense;
