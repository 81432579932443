import React, { useState, useEffect } from "react";
import css from "../../style/myEarnings.module.css";
import {
  driverEarnings,
  driverEarningsGraph,
  cashOut,
} from "../../store/actions/CommonActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { FaStar } from "react-icons/fa";
import { useParams, useLocation } from "react-router";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
} from "recharts";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ModalComponent from "../../components/Modals/Modal";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      console.log(error);
    }
  }, []);
  return size;
}

const range = [
  { key: "Day", value: "day" },
  { key: "Week", value: "week" },
  { key: "Month", value: "month" },
  { key: "Year", value: "year" },
];

const MyEarnings = () => {
  const [earnings, setEarnings] = useState({});
  const [all_date_earnings, set_all_date_earnings] = useState();
  const [graphData, setGraphData] = useState();
  const [type, setType] = useState("day");
  const [year, setYear] = useState(2023);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(true);
  const [withdrawalModal, setwithdrawalModal] = useState(false);
  const [height, width] = useWindowSize();
  const dispatch = useDispatch();

  const { driverId } = useParams();
  const { search } = useLocation();

  const handleChangeYear = (e, isIncrement) => {
    if (isIncrement) {
      const date = new Date();
      if (year < date.getFullYear()) {
        setYear(year + 1);
        handleGetGraphData(year + 1);
      }
    } else if (year > 2021) {
      setYear(year - 1);
      handleGetGraphData(year - 1);
    }
  };

  const handleGetEarnings = () => {
    setLoading(true);
    setError("");
    dispatch(
      driverEarnings(driverId, set_all_date_earnings, setLoading, setError)
    );
  };

  const handleGetGraphData = (reqYear) => {
    if (reqYear && driverId) {
      setGraphLoading(true);
      dispatch(
        driverEarningsGraph(driverId, reqYear, setGraphData, setGraphLoading)
      );
    }
  };

  const handleCashOut = () => {
    if (driverId) {
      dispatch(cashOut(driverId, handleGetEarnings, setwithdrawalModal));
    }
    setwithdrawalModal(false);
  };

  useEffect(() => {
    if (all_date_earnings && type) setEarnings(all_date_earnings[type]);
  }, [all_date_earnings, type]);

  useEffect(() => {
    if (driverId && search && year) {
      const params = new URLSearchParams(search);
      const token = params.get("token");
      localStorage.setItem("wemoovezdrivertoken", "Bearer " + token);
      handleGetEarnings();
      handleGetGraphData(year);
    }
  }, [search, driverId]);

  return (
    <div className={css?.earningMainDIv}>
      <ModalComponent
        text={"Are you sure you want to withdraw all your earnings?"}
        openState={withdrawalModal}
        closeFunction={() => {
          setwithdrawalModal(false);
        }}
        btnText1={"Yes"}
        btnClickFunc1={handleCashOut}
        btnText2={"No"}
        btnClickFunc2={() => {
          setwithdrawalModal(false);
        }}
        isSmall={true}
      />
      <div className={css?.s1Md}>
        {range.map((r) => (
          <div
            className={r.value === type ? css?.s1BtnSelected : css?.s1Btn}
            onClick={() => setType(r.value)}
          >
            {r.key}
          </div>
        ))}
      </div>
      <h3 className={css?.s2Md}>Earnings</h3>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <div className={css?.s3Md}>
            <div className={css?.s3MdTL}>
              <div className={css?.s3MdTLBox} style={{ borderLeft: "none" }}>
                <div className={css?.s3MdTLBoxNum}>
                  ${earnings.totalEarning}
                </div>
                <div className={css?.s3MdTLBoxTitle}>Earnings</div>
              </div>
              <div className={css?.s3MdTLBox}>
                <div className={css?.s3MdTLBoxNum}>{earnings.ratings}</div>
                <div className={css?.s3MdTLBoxTitle}>Overall rating</div>
              </div>
              <div className={css?.s3MdTLBox}>
                <div className={css?.s3MdTLBoxNum}>{earnings.reviews}</div>
                <div className={css?.s3MdTLBoxTitle}>Total reviews</div>
              </div>
            </div>
            <div className={css?.s3MdBL}>
              <div className={css?.s3MdBLBox}>
                <div className={css?.s3MdBLBoxText}>Paid out</div>
                <div className={css?.s3MdBLBoxNumber}>
                  ${earnings.paidAmount}
                </div>
              </div>
              <div className={css?.s3MdBLBox}>
                <div className={css?.s3MdBLBoxText}>Available</div>
                <div
                  className={css?.s3MdBLBoxNumber}
                  style={{ backgroundColor: "var(--primaryLite)" }}
                >
                  ${earnings.availableAmount}
                </div>
              </div>
              <div className={css?.s3MdBLBox}>
                <div className={css?.s3MdBLBoxText}>Pending</div>
                <div className={css?.s3MdBLBoxNumber}>
                  ${earnings.unpaidAmount}
                </div>
              </div>
            </div>
            <div
              className={css?.greenBtn}
              onClick={() => {
                setwithdrawalModal(true);
              }}
            >
              Cash Out
            </div>
          </div>

          <div className={css?.graphDiv}>
            <div className={css?.graphTopLine}>
              <FaAngleLeft onClick={handleChangeYear} />
              <div className={css?.graphTitle}>{year}</div>
              <FaAngleRight onClick={(e) => handleChangeYear(e, true)} />
            </div>
            {graphLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : Array.isArray(graphData) && graphData.length ? (
              <div className={css?.graphContainer}>
                <ResponsiveContainer aspect={1.8}>
                  <BarChart data={graphData}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="month"
                      tickLine={false}
                      tick={{ fontSize: width < 600 ? 8 : 14 }}
                      tickCount={13}
                      tickMargin={10}
                    />
                    <YAxis
                      orientation="right"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: width < 600 ? 8 : 14, fill: "#332a88" }}
                      tickCount={4}
                      tickFormatter={(value) => `$${value}`}
                    />
                    <Tooltip />
                    <Bar
                      dataKey="paidAmount"
                      stackId="a"
                      fill="var(--primary)"
                    />
                    <Bar
                      dataKey="unpaidAmount"
                      stackId="a"
                      fill="var(--primaryLite)"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div>No record round for this year!</div>
            )}
          </div>

          <div className={css?.s4Md}>
            <div className={css?.s4L}>
              <div className={css?.s4LL}>Jobs</div>
              <div className={css?.s4LR}>{earnings.totalJobs}</div>
            </div>
            <div className={css?.s4L}>
              <div className={css?.s4LL}>Earnings</div>
              <div className={css?.s4LR}>${earnings.ridesEarning}</div>
            </div>
            <div className={css?.s4L}>
              <div className={css?.s4LL}>Tips</div>
              <div className={css?.s4LR}>${earnings.tipsEarning}</div>
            </div>
            <div className={css?.s4L} style={{ borderBottom: "none" }}>
              <div className={css?.s4LL}>Overall rating</div>
              <div className={css?.s4LR}>
                {earnings.ratings}
                <span className={css?.s4Star}>
                  <FaStar />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyEarnings;
