import React from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/Help.module.css";

const Card = ({ title, index }) => {
  return (
    <div className={css?.S2CardMD}>
      <div className={css?.S2CardTitle}>{title}</div>
      <img
        className={css?.S2Image}
        src={`/images/s${index + 1}.svg`}
        alt="Help"
      />
    </div>
  );
};
const Section2UseCases = () => {
  const cardInfo = [
    { title: "Moving services" },
    { title: "Store delivery" },
    { title: "Used item delivery" },
    { title: "Donations" },
    { title: "Junk removal" },
    { title: "Storage moves" },
    { title: "Office moves" },
    { title: "Labour only" },
    { title: "Small business delivery" },
  ];
  return (
    <div className={css?.S2M}>
      <div className={css?.S2Heading}>
        Use moovez for all your moving & delivery needs.
      </div>
      <div className={css?.S2CardsDiv}>
        {cardInfo.map((info, index) => (
          <Card title={info.title} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Section2UseCases;
