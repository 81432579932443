import React from "react";
import Layout from "../../layouts/index";
import LegalAgreementScreen from "../../screens/onbording_web/LegalAgreement";

const LegalAgreement = () => {
  return (
    <Layout>
      <LegalAgreementScreen />
    </Layout>
  );
};

export default LegalAgreement;
