import React from "react";
import Layout from "../layouts/index";
import LoginScreen from "../screens/Signin/Login";

function MoovezLogin() {
  return (
    // <Layout>
    <LoginScreen />
    // </Layout>
  );
}

export default MoovezLogin;
