import React from "react";
import { useHistory } from "react-router";
import Layout from "../layouts";
import css from "../style/driverRegistration.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, StepIcon } from "@material-ui/core";
import AppCards from "../components/AppCards";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "var(--primary)!important",
  },
  label: {
    color: "var(--primary)!important",
  },
}));

const style = {
  mainHeading: {
    color: "var(--primary)",
    fontSize: "40px",
    fontWeight: "500",
    margin: "10px 0",
  },
  text: {
    color: "grey",
    margin: "10px 0",
    fontWeight: "500",
    fontSize: "22px",
  },
  button: {
    color: "white",
    fontWeight: "500",
    margin: "10px 0",
    borderRadius: "10px",
    padding: "10px 20px",
    backgroundColor: "var(--primary)",
  },
};

const steps = [
  "Sign up",
  "Verify Email",
  "Upload documents",
  "Select vehicle type",
  "Pending approval",
];

const WelcomePage = () => {
  const classes = useStyles();

  function CustomStepIcon(props) {
    const { active, completed } = props;
    return <StepIcon classes={{ root: classes.icon }} {...props} />;
  }
  const history = useHistory();
  return (
    <Layout>
      <div className={css?.welcomeMainDiv}>
        <Stepper className={css?.stepperClass} activeStep={4} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={CustomStepIcon}
                classes={{ label: classes.label }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={css?.welcomeSecDiv}>
          <div style={style.mainHeading}>Welcome to moovez</div>
          <div style={style.text}>
            Your moover application is under review. You will receive an email
            once you are approved by our verification team. In the meantime,
            follow the link below to download the moover app. You’ll need the
            app once your application is approved.
          </div>
        </div>
      </div>
      <div className={css?.bookingBottomDiv}>
        <AppCards />
      </div>
    </Layout>
  );
};

export default WelcomePage;
