import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DriverLogin from "./pages/login";
import DriverSignup from "./pages/signup";
import MyEarnings from "./pages/MyEarnings";
import MyEarningsDriverApp from "./pages/MyEarnings2";
import DriverHome from "./pages/home";
import Registration from "./pages/Registration";
import BecomeADriver from "./pages/becomeADriver";
import MyTrips from "./pages/myTrips";
import Help from "./pages/help";
import AvailablePickups from "./pages/availablePickups";
import Account from "./pages/Account";
import DriverProfile from "./pages/driverProfile";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import DriverRegistrationWithoutVehicle from "./pages/driverRegistrationWithoutVehicle";
import Payments from "./pages/Payments";
import CompanyVehicleDriverFlow from "./pages/CompanyVehicleDriverFlow";
import MooverSignUp from "./pages/DriverSignUp";
import RegistrationStatus from "./pages/RegistrationStatus";
import WelcomePage from "./pages/WelcomePage";
import Earnings from "./pages/earnings";
import OurStoryPage from "./pages/OurStory";
import FAQsPage from "./pages/FAQs";
import ContactUsPage from "./pages/ContactUs";
import HowItWorksPage from "./pages/HowItWorks";
// ONBOARDING FOR WEB
import DriverSIgnup from "./pages/onbording_web/Index";
import BackgroundCheck from "./pages/onbording_web/BackgroundCheck";
import BusinessLicense from "./pages/onbording_web/BusinessLicense";
import DriverLicense from "./pages/onbording_web/DriverLicense";
import LegalAgreement from "./pages/onbording_web/LegalAgreement";
import ProfilePhoto from "./pages/onbording_web/ProfilePhoto";
import ProofOfInsurance from "./pages/onbording_web/ProofOfInsurance";
import ProofOfWorkEligibility from "./pages/onbording_web/ProofOfWorkEligibility";
import StarterKit from "./pages/onbording_web/StarterKit";
import TermsOfServices from "./pages/onbording_web/TermsOfServices";
import VehicleRegistration from "./pages/onbording_web/VehicleRegistration";
import VehicleType from "./pages/onbording_web/VehicleType";
import VehicleInsurance from "./pages/onbording_web/VehicleInsurance";
// ONBOARDING FOR MOBILE
import MobileDriverSIgnup from "./pages/onbording/Index";
import MobileBackgroundCheck from "./pages/onbording/BackgroundCheck";
import MobileBusinessLicense from "./pages/onbording/BusinessLicense";
import MobileDriverLicense from "./pages/onbording/DriverLicense";
import MobileLegalAgreement from "./pages/onbording/LegalAgreement";
import MobileProfilePhoto from "./pages/onbording/ProfilePhoto";
import MobileProofOfInsurance from "./pages/onbording/ProofOfInsurance";
import MobileProofOfWorkEligibility from "./pages/onbording/ProofOfWorkEligibility";
import MobileStarterKit from "./pages/onbording/StarterKit";
import MobileTermsOfServices from "./pages/onbording/TermsOfServices";
import MobileVehicleRegistration from "./pages/onbording/VehicleRegistration";
import MobileVehicleType from "./pages/onbording/VehicleType";
import MobileVehicleInsurance from "./pages/onbording/VehicleInsurance";
// TOAST CONTAINER
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  console.log = () => {};
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHomer
      />
      <BrowserRouter>
        <Switch>
          <Route path="/Driver/Signup" exact component={DriverSignup} />
          <Route path="/Driver/Login" exact component={DriverLogin} />
          <Route path="/driver/welcomePage" exact component={WelcomePage} />
          <Route path="/Driver/MooverSignUp" exact component={MooverSignUp} />
          <Route
            path="/Driver/status/:id"
            exact
            component={RegistrationStatus}
          />
          <Route path="/Driver/Earnings" exact component={Earnings} />
          <Route
            path="/Driver/MyEarnings/:driverId"
            exact
            component={MyEarnings}
          />
          <Route
            path="/Driver/MyEarningsDriv/:driverId"
            exact
            component={MyEarningsDriverApp}
          />

          <Route path="/Driver/OurStory" exact component={OurStoryPage} />
          <Route path="/Driver/FAQs" exact component={FAQsPage} />
          <Route path="/Driver/ContactUs" exact component={ContactUsPage} />
          <Route
            path="/Driver/ForgotPassword"
            exact
            component={ForgotPassword}
          />
          <Route
            path="/Driver/ChangePassword/:email"
            exact
            component={ChangePassword}
          />
          <Route
            path="/Driver/ConfirmEmail/:email"
            exact
            component={ConfirmEmail}
          />
          <Route
            path="/Driver/NoVehicleDriverFlow/:id"
            exact
            component={DriverRegistrationWithoutVehicle}
          />
          <Route
            path="/Driver/CompanyVehicleDriverFlow/:id"
            exact
            component={CompanyVehicleDriverFlow}
          />
          <Route
            path="/Driver/Profile/Registration/:id"
            exact
            component={Registration}
          />
          <Route
            path="/Driver/Profile/NoVehicleDriverFlow/:id"
            exact
            component={DriverRegistrationWithoutVehicle}
          />
          <Route
            path="/Driver/Profile/CompanyVehicleDriverFlow/:id"
            exact
            component={CompanyVehicleDriverFlow}
          />
          <Route path="/Driver/Payments" exact component={Payments} />
          <Route path="/Driver/BecomeADriver" exact component={BecomeADriver} />
          <Route path="/Driver/howItWorks" exact component={HowItWorksPage} />
          <Route
            path="/Driver/availablePickups"
            exact
            component={AvailablePickups}
          />
          <Route path="/Driver/Earnings" exact component={Earnings} />
          <Route
            path="/Driver/Profile/:driverEmail"
            exact
            component={DriverProfile}
          />
          <Route
            path="/Driver/Account/:driverEmail"
            exact
            component={Account}
          />
          <Route path="/Driver/help" caseSensitive={false} component={Help} />
          <Route path="/Driver/myTrips" exact component={MyTrips} />
          {/* WEB ONBOARDING FLOW */}
          <Route
            path="/Driver/Web/Registration/:id"
            exact
            caseSensitive={false}
            component={DriverSIgnup}
          />
          <Route
            path="/Driver/Web/DriverLicense/:type/:id"
            exact
            component={DriverLicense}
          />
          <Route
            path="/Driver/Web/BusinessLicense/:type/:id"
            exact
            component={BusinessLicense}
          />
          <Route
            path="/Driver/Web/LegalAgreement/:type/:id"
            exact
            component={LegalAgreement}
          />
          <Route
            path="/Driver/Web/ProfilePhoto/:type/:id"
            exact
            component={ProfilePhoto}
          />
          <Route
            path="/Driver/Web/ProofOfInsurance/:type/:id"
            exact
            component={ProofOfInsurance}
          />
          <Route
            path="/Driver/Web/ProofOfWorkEligibility/:type/:id"
            exact
            component={ProofOfWorkEligibility}
          />
          <Route
            path="/Driver/Web/StarterKit/:type/:id"
            exact
            component={StarterKit}
          />
          <Route
            path="/Driver/Web/TermsOfServices/:type/:id"
            exact
            component={TermsOfServices}
          />
          <Route
            path="/Driver/Web/BackgroundCheck/:type/:id"
            exact
            component={BackgroundCheck}
          />
          <Route
            path="/Driver/Web/VehicleRegistration/:type/:id"
            exact
            component={VehicleRegistration}
          />
          <Route
            path="/Driver/Web/VehicleType/:type/:id"
            exact
            component={VehicleType}
          />
          <Route
            path="/Driver/Web/VehicleInsurance/:type/:id"
            exact
            component={VehicleInsurance}
          />
          {/* MOBILE ONBOARDING FLOW */}
          <Route
            path="/Driver/mobile/registration/:id"
            exact
            caseSensitive={false}
            component={MobileDriverSIgnup}
          />
          <Route
            path="/Driver/mobile/DriverLicense/:type/:id"
            exact
            component={MobileDriverLicense}
          />
          <Route
            path="/Driver/mobile/BusinessLicense/:type/:id"
            exact
            component={MobileBusinessLicense}
          />
          <Route
            path="/Driver/mobile/LegalAgreement/:type/:id"
            exact
            component={MobileLegalAgreement}
          />
          <Route
            path="/Driver/mobile/ProfilePhoto/:type/:id"
            exact
            component={MobileProfilePhoto}
          />
          <Route
            path="/Driver/mobile/ProofOfInsurance/:type/:id"
            exact
            component={MobileProofOfInsurance}
          />
          <Route
            path="/Driver/mobile/ProofOfWorkEligibility/:type/:id"
            exact
            component={MobileProofOfWorkEligibility}
          />
          <Route
            path="/Driver/mobile/StarterKit/:type/:id"
            exact
            component={MobileStarterKit}
          />
          <Route
            path="/Driver/mobile/TermsOfServices/:type/:id"
            exact
            component={MobileTermsOfServices}
          />
          <Route
            path="/Driver/mobile/BackgroundCheck/:type/:id"
            exact
            component={MobileBackgroundCheck}
          />
          <Route
            path="/Driver/mobile/VehicleRegistration/:type/:id"
            exact
            component={MobileVehicleRegistration}
          />
          <Route
            path="/Driver/mobile/VehicleType/:type/:id"
            exact
            component={MobileVehicleType}
          />
          <Route
            path="/Driver/mobile/VehicleInsurance/:type/:id"
            exact
            component={MobileVehicleInsurance}
          />
          {/* DEFAULT */}

          <Route path="/Driver/" component={DriverHome} />
          <Route path="/" component={DriverHome} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
export default App;
