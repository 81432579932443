import React from "react";
import css from "../../style/ourStory.module.css";

const Section3 = () => {
  return (
    <div className={css?.S3M}>
      <div className={css?.s3L}>
        <div className={css?.s3Heading}>A message about our CEO</div>
        <div className={css?.s3Text}>
          Moovez is a culmination of Simon’s East-West experience. After
          spending 8.5 years in tech-enabled logistics in Beijing, China. He
          witnessed a country transform seemingly overnight into a tech
          logistics powerhouse. With this unique experience and perspective, he
          was able to identify areas where Canada lagged behind. Teaming up with
          like-minded and motivated problem solvers, they set out to build an
          innovative solution in an industry that has not kept up with the
          on-demand nature of the world.
        </div>
      </div>
      <div className={css?.s3R}>
        <img className={css?.s3Image} src="/images/ceo.png" alt="CEO" />
      </div>
    </div>
  );
};

export default Section3;
