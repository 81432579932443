import React, { useState } from "react";
import css from "../../style/onboardingweb.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsWeb } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const ProofOfInsurance = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const ProofOfInsuranceItems = [
    { name: "CGL", property: "businessInsuranceCargoCoverageL" },
    { name: "Cargo Coverage", property: "businessInsuranceCargoCoverage" },
    { name: "Auto Liability", property: "businessInsuranceCG" },
    {
      name: "Worker's Compensation",
      property: "businessInsuranceWorkerCompensation",
    },
  ];

  const [error, setError] = useState("");
  const [assets, setAssets] = useState({
    businessInsuranceCargoCoverageL: null,
    businessInsuranceCargoCoverage: null,
    businessInsuranceCG: null,
    businessInsuranceWorkerCompensation: null,
  });
  const [image, setImage] = useState({
    businessInsuranceCargoCoverageL: null,
    businessInsuranceCargoCoverage: null,
    businessInsuranceCG: null,
    businessInsuranceWorkerCompensation: null,
  });
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name && e.target.files?.length) {
      setAssets({ ...assets, [e?.target?.name]: e.target.files[0] });
      setImage({
        ...image,
        [e?.target?.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmitForm = () => {
    const formData = new FormData();
    let err = 0;
    ProofOfInsuranceItems.forEach((item) => {
      if (!assets[item.property]) err += 1;
      formData.append(item.property, assets[item.property]);
    });
    if (err) {
      toast.error("Please upload all documents.");
      return;
    }
    postDocumentsWeb(id, type, formData, setError, history);
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <div className={css?.skImages}>
              {ProofOfInsuranceItems.map((item) => (
                <>
                  <label htmlFor={item.property} className={css?.skLabMD}>
                    <div className={css?.skUploadMd}>
                      <h4
                        className={css?.l2Heading}
                        style={{ margin: "10px 0" }}
                      >
                        {item.name}
                      </h4>
                      <div className={css?.skUploadImgBG}>
                        <img
                          src={image[item.property] ?? "/images/upload.png"}
                          className={css?.sKUploadImg}
                        />
                      </div>
                    </div>
                  </label>
                  <input
                    id={item.property}
                    name={item.property}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={handleAddImage}
                    style={{ display: "none" }}
                  />
                </>
              ))}
            </div>
            <ErrorComponent error={error} />
          </div>
          <div className={css?.buttonPanel}>
            <div className={css?.submitButton} onClick={handleSubmitForm}>
              Confirm
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <h3 className={css?.mHeading}>
              Take a photo of your basic equipment
            </h3>
            <div className={css?.mtext}>
              Please upload a current (non-expired) photo of your Insurance
              Policy. Your policy/policies should include commercial general
              liability (CGL), cargo coverage, and auto liability. If you have
              Workers’ Compensation, please upload it because it will give you
              access to even more jobs.
              <br />
              If your policy has multiple coverages, PLEASE ONLY UPLOAD ONE
              COPY.
              <br />
              Make sure your business name, insurance company and expiration
              date(s) are clear and visible.
            </div>
            <div className={css?.imageDiv}>
              <img src="/images/insurance.png" className={css?.bIImage} />
            </div>
          </div>
          <div className={css?.buttonPanel}>
            <div
              className={css?.submitButton}
              onClick={() => setTakePhoto(true)}
            >
              Take Photo
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProofOfInsurance;
