import React from "react";
import Layout from "../../layouts/index";
import VehicleTypeScreen from "../../screens/onbording/VehicleType";

const VehicleType = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <VehicleTypeScreen />
    </Layout>
  );
};

export default VehicleType;
