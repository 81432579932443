import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel";
import css from "../../style/howMovEzWorks.module.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { FaCircle } from "react-icons/fa";

function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const Section1InfoSlider = () => {
  // dimensions
  const [, width] = useWindowSize();
  const [sliderDotBold, setsliderDotBold] = useState(0);

  const carousalItems = [
    {
      heading: "Choose your job type",
      text: "Choose between Delivery, Mooving, Moovers & Business. Make sure to read the descriptions & choose the right job type for your needs.",
    },
    {
      text: "Set your pickup and drop off locations (add a stop if there are multiple pickups), select the vehicle that is right for you, and choose a time you would like us to arrive.",
      heading: "Enter your details",
      note: "Note: this step may vary based on job type.",
    },
    {
      text: "Our moovers will arrive to load your stuff and secure it safely. You can track them in real-time through the app or just see them at the destination!",
      heading: "Sit back and relax",
    },
    {
      text: "The moovers will unload your items and place them where you want them. Leave a review and say thanks with a tip for a job well done. See you next time!",
      heading: "Review and tip",
    },
  ];
  useEffect(() => {
    try {
      const changeSlider = setTimeout(() => {
        const newSlider = sliderDotBold < 3 ? sliderDotBold + 1 : 0;
        const dot = document.getElementById("how-it-works-slide-" + newSlider);
        dot.click();
        setsliderDotBold(newSlider);
      }, 10000);
      return () => clearTimeout(changeSlider);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [sliderDotBold]);

  return (
    <div className={css?.s1MD}>
      {/* Phone carousal */}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={
          width > 1025 ? 45 : width > 900 ? 60 : width > 600 ? 90 : 200
        }
        totalSlides={carousalItems?.length}
        infinite={true}
      >
        <Slider>
          {carousalItems?.map((item, index) => (
            <Slide index={index} className={css?.sliderMainDiv}>
              <div className={css?.sliderFlexDiv}>
                <img
                  src={`/images/step${index + 1}.png`}
                  alt={`Step ${index + 1}`}
                  className={css?.phoneImage}
                />
                <div className={css?.sliderLeftText}>
                  <div className={css?.leftTextStepNo}>{`STEP ${
                    index + 1
                  }`}</div>
                  <div className={css?.leftTextHeading}>{item?.heading}</div>
                  <div className={css?.leftTextText}>{item?.text}</div>
                  <div className={css?.leftTextNote}>{item?.note}</div>
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
        <div className={css?.DotGroup} showAsSelectedForCurrentSlideOnly={true}>
          {carousalItems?.map((item, index) => (
            <Dot
              slide={index}
              className={css?.dotcss}
              id={"how-it-works-slide-" + index}
              onClick={() => setsliderDotBold(index)}
              style={{
                color: sliderDotBold === index ? "#1d184e" : "#6f6d88",
              }}
            >
              <FaCircle />
            </Dot>
          ))}
        </div>
      </CarouselProvider>
    </div>
  );
};

export default Section1InfoSlider;
