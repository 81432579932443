import React from "react";
import Layout from "../../layouts/index";
import IndexScreen from "../../screens/onbording/Index";

const Index = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <IndexScreen />
    </Layout>
  );
};

export default Index;
