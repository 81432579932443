import React from "react";
import Layout from "../layouts/index";
import AvailablePuckupScreen from "../screens/Booking/AvailablePickups";

const AvailablePuckup = () => {
  return (
    <Layout>
      <AvailablePuckupScreen />
    </Layout>
  );
};

export default AvailablePuckup;
