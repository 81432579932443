import React from "react";
import Layout from "../../layouts/index";
import StarterKitScreen from "../../screens/onbording_web/StarterKit";

const StarterKit = () => {
  return (
    <Layout>
      <StarterKitScreen />
    </Layout>
  );
};

export default StarterKit;
