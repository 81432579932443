import React from "react";
import Layout from "../layouts/index";
import OurStory from "../screens/Info/OurStory";

const OurStoryPage = () => {
  return (
    <Layout>
      <OurStory />
    </Layout>
  );
};

export default OurStoryPage;
