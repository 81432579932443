import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const DriverLicense = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");
  const [front, setFront] = useState();
  const [back, setBack] = useState();
  const [frontImg, setFrontImg] = useState();
  const [backImg, setBackImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name === "driverLicenseFront") {
      if (e.target.files?.length) {
        setFront(e.target.files[0]);
        setFrontImg(URL.createObjectURL(e.target.files[0]));
      }
    } else if (e?.target?.name === "driverLicenseBack") {
      if (e.target.files?.length) {
        setBack(e.target.files[0]);
        setBackImg(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const handleSubmitForm = () => {
    if (front && back) {
      const formData = new FormData();
      formData.append("driverLicenseFront", front);
      formData.append("driverLicenseBack", back);
      postDocumentsMobile(id, type, formData, setError, history);
    } else if (front) {
      toast.error("Please upload back of driver's License.");
    } else {
      toast.error("Please upload front of driver's License.");
    }
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <>
          <label htmlFor="dl-front">
            <div className={css?.dlUploadMd}>
              <div className={css?.l2Heading}>Front of Driver's License</div>
              <div className={css?.dlUploadImgBG}>
                <img
                  src={frontImg ?? "/images/upload.png"}
                  className={frontImg ? css?.UploadImg : css?.dlUploadImg}
                />
              </div>
            </div>
          </label>
          <input
            id="dl-front"
            name="driverLicenseFront"
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
          <label htmlFor="dl-back">
            <div className={css?.dlUploadMd}>
              <div className={css?.l2Heading}>Back of Driver's License</div>
              <div className={css?.dlUploadImgBG}>
                <img
                  src={backImg ?? "/images/upload.png"}
                  className={backImg ? css?.UploadImg : css?.dlUploadImg}
                  style={{ borderRadius: 20 }}
                />
              </div>
            </div>
          </label>
          <input
            id="dl-back"
            name="driverLicenseBack"
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
          <ErrorComponent error={error} />
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Confirm
          </div>
        </>
      ) : (
        <>
          <div className={css?.mHeading}>
            Take a photo of your driver's license
          </div>
          <div className={css?.mtext}>
            Driver’s license requirements vary based on location and size of
            vehicle. You must be at least 21 years old to operate as a driver on
            our platform. Swampers / helpers have different age requirements.
          </div>
          <div className={css?.imageDiv}>
            <img src="/images/DL.png" className={css?.dlImage} />
          </div>
          <div className={css?.submitButton} onClick={() => setTakePhoto(true)}>
            Take Photo
          </div>
        </>
      )}
    </div>
  );
};

export default DriverLicense;
