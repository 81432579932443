import axios from "axios";
import baseUrl from "../constants/baseUrl";
import { returnAuthHeader } from "../../utils/auth";

// forgot password action
// this action is called in forgot password page
export const forgotPassword =
  (email, setStatus, setError) => async (dispatch) => {
    setError("");
    console.log("forgot pass cred", email);
    try {
      const { data } = await axios.get(
        `${baseUrl}Auth/forgotPassword?email=${email}`
      );
      console.log("forgot pass res data", data);
      setStatus("verify");
    } catch (error) {
      console.log(error);
      setError("Something went wrong!");
    }
  };

// verify code action
// this action is called in forgotPassword page
export const verifyCodeAction =
  (email, code, setStatus, setError) => async (dispatch) => {
    setError("");
    console.log("verify cred", email, code);
    try {
      // const { data } = await axios.post(
      //   `${baseUrl}Auth/forgotPassword?${email}&${code}`
      // );
      // /Auth/resetPasswordCode
      const { data } = await axios.post(`${baseUrl}Auth/resetPasswordCode`, {
        email: email,
        code: code,
      });
      console.log("res from verify code action", data);
      setStatus("reset");
    } catch (error) {
      console.log(error);
      setError("Something went wrong!");
    }
  };

// reset Password action
// this action is called in forgotPassword page
export const resetPassword =
  (email, password, history, setStatus, setError) => async (dispatch) => {
    try {
      setError("");
      const { data } = await axios.post(
        `${baseUrl}Auth/resetPassword`,
        {
          email,
          password,
        },
        returnAuthHeader()
      );
      history.push(`/Driver/Login`);
      setStatus(true);
    } catch (error) {
      console.log(error);
      setError("Something went wrong!");
    }
  };
// reset Password action
// this action is called in reset password page
export const changePassword =
  (resetCreds, history, setStatus, setError) => async (dispatch) => {
    try {
      setError("");
      const { data } = await axios.post(
        `${baseUrl}Auth/changePassword`,
        resetCreds,
        returnAuthHeader()
      );
      console.log("response from change password API");
      // history.push(`/Driver/Login`);
      setStatus(true);
    } catch (error) {
      console.log(error);
      setError("Something went wrong!");
    }
  };
