import React from "react";
import Layout from "../../layouts/index";
import VehicleRegistrationScreen from "../../screens/onbording/VehicleRegistration";

const VehicleRegistration = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <VehicleRegistrationScreen />
    </Layout>
  );
};

export default VehicleRegistration;
