import React from "react";
import css from "../../style/Accounts.module.css";
import AccountCard from "../../components/AccountCard";
import { useHistory, useParams } from "react-router";

const Account = () => {
  const history = useHistory();
  const { driverEmail } = useParams();
  const cardsInfo = [
    {
      icon: "fas fa-user",
      title: "My Profile",
      description: "Provide basic info and necessary contact details.",
      link: `/Driver/Profile/${driverEmail}`,
    },
    {
      icon: "fas fa-truck-moving",
      title: "Driver registration",
      description: "Provide required documents to become a moovez driver.",
      link: "/Driver/Registration",
    },
    {
      icon: "fas fa-wallet",
      title: "Payouts",
      description: "Add bank info in order to cashout your earnings.",
      link: "/DriverPayout",
    },
  ];
  return (
    <div className={css?.mainDiv}>
      <div className={css?.mainHeading}>account</div>
      <div className={css?.cardsDiv}>
        {cardsInfo?.map((info) => (
          <AccountCard data={info} history={history} />
        ))}
      </div>
      <div className={css?.DeactivateAccountTxt}>
        Need to deactivate your account?
      </div>
      <div className={css?.DeactivateAccountBtn}>Deactivate my account</div>
    </div>
  );
};
export default Account;
