import React from "react";
import css from "../style/common.module.css";

const RoundButton = ({ text, clickFunction }) => {
  return (
    <div className={css?.roundBtn} onClick={clickFunction}>
      {text}
    </div>
  );
};

export default RoundButton;
