import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import css from "../style/howMovEzWorks.module.css";

export default function SimpleAccordion({ question, answer }) {
  return (
    <div>
      <Accordion className={css?.accordionMainDiv}>
        <AccordionSummary
          expandIcon={<i class="far fa-plus-square"></i>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={css?.FAQQuestionText}>{question}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={css?.FAQAnswerText}>{answer}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
