export default [
  {
    type: "MH",
    content: `MOOVEZ INDEPENDENT CONTRACTOR AGREEMENT`,
  },
  {
    type: "T1",
    content: `Last Updated: August 19, 2022`,
  },
  {
    type: "T1",
    content: `MUV Logistics Network Technologies LTD. DBA MOOVEZ (hereinafter referred to as “MOOVEZ”)`,
  },
  {
    type: "H1",
    content: `THE PARTIES HEREBY AGREE TO THE FOLLOWING:`,
  },
  {
    type: "H1U",
    content: `PART I. PARTIES AND BACKGROUND`,
  },
  {
    type: "B1",
    number: "1",
    content: `The contractor (hereinafter the “Contractor”) is an individual or Corpora on, as the case may be, who provides independent contractor services to MOOVEZ. “Contractor” includes its employees, affiliates, sub-contractor or otherwise agent included in providing the below-described services of loading, movement, transportation and delivery of goods, materials or personal items (hereinafter referred to as the “Delivery Items”) of MOOVEZ’s pla orm users, subscribers or customers (hereinafter referred to as the “Customer”). The Contractor may be provided no ces, Moving Orders and other opportunities from me-to- me by MOOVEZ.`,
  },
  {
    type: "B1",
    number: "2",
    content: `THIS AGREEMENT TAKES EFFECT WHEN YOU CLICK THE “ACCEPT" BUTTON (the "Effective Date"). BY
    CLICKING ON THE "ACCEPT” BUTTON YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND
    THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY
    TO ENTER INTO THIS AGREEMENT AND, IF ENTERING INTO THIS AGREEMENT FOR AN ORGANIZATION,
    THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT THIS
    AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.`,
  },
  {
    type: "B1",
    number: "3",
    content: `IF YOU DO NOT AGREE TO THESE TERMS, PLEASE SELECT THE "DECLINE" BUTTON BELOW. IF YOU DO
    NOT ACCEPT THESE TERMS, THIS AGREEMENT SHALL NOT BE BINDING.`,
  },
  {
    type: "B2",
    number: "3.1",
    content: `The Contractor represents and warrants that he, she, or it is duly authorized and has the
    legal authority to deliver this Agreement as a valid, legal and enforceable contract.`,
  },
  {
    type: "B1",
    number: "4",
    content: `MOOVEZ INC. (hereinafter the “MOOVEZ”) is a Corporation incorporated and doing business in
    Alberta. MOOVEZ provides a proprietary software and platform, which matches and connects
    Customers to Contractors for the loading, movement, transportation and delivery of the Customer’s
    Delivery Items. As respecting the Contractor, MOOVEZ notifies and allows for Contractors to directly
    connect with and attend at the Customer’s location, wherein the Contractor can load, move, transport
    and deliver the Customer’s Delivery Items to the Customer’s requested and designated location.`,
  },
  {
    type: "B1",
    number: "5",
    content: `The Parties agree to the terms of this agreement (the “Agreement”), and the Parties agree that the
    Agreement constitutes the entire relationship between MOOVEZ and the Contractor (together the
    “Parties”). This Agreement supersedes any prior understanding or representation of any kind preceding
    the date of this Agreement. There are no other promises, conditions, understandings or other
    agreements, whether oral or written, relating to the subject matter of this Agreement.`,
  },
  {
    type: "H1U",
    number: "",
    content: `PART II. CONTRACTOR’S SERVICES`,
  },
  {
    type: "B1",
    number: "1",
    content: `The Agreement is entered into by and between the Contractor and MOOVEZ (together the “Parties”).
    This Agreement begins on the day that the “ACCEPT” button is clicked and agreed-to by the Contractor
    and continues until termination.`,
  },
  {
    type: "B1",
    number: "2",
    content: `MOOVEZ may from time-to-time, notify the Contractor of a Customer’s request for the delivery of the
    Customer’s Delivery Items, the Customer’s contact and other information, current location of the
    goods and personal property to be moved, and requested location to be moved, among other
    information and special requests of MOOVEZ’s Customers (the “Moving Order”).`,
  },
  {
    type: "B1",
    number: "3",
    content: `Upon receiving notification and accepting the Moving Order, the Contractor shall attend at the
    Customer’s location, pick up and deliver the specified Delivery Items in a safe and timely manner. The
    Contractor shall to the best of its ability, ensure the accuracy, completeness, legality, specificity of the
    Moving Order including but not limited to the accuracy, completeness, legality, specificity of the
    Delivery Items, the Customer’s identity and requested delivery location, and any other details of the
    Delivery Items or Moving Order.`,
  },
  {
    type: "B2",
    number: "3.1",
    content: `The Contractor shall also use reasonable inquiries into the general suitability of the
    Delivery Item and Moving Order, including but not limited to: the dangerousness and practicality of
    the Delivery Items or Moving Order, whether the Delivery Items are hazardous or dangerous to
    move, or any other issues or concerns which may make the moving of the Delivery Items, or
    execution of the Moving Order impossible, impractical or otherwise may disallow for the Contractor
    to perform the services.`,
  },
  {
    type: "B2",
    number: "3.2",
    content: `Upon receiving and accepting the Moving Order, the Contractor shall be at liberty to
    cancel and discontinue the Moving Order if the Contractor at any point believes that the Delivery
    Items and Moving Order are inaccurate, illegal, hazardous, or otherwise impractical. Should the
    Contractor decide to cancel or discontinue the Moving Order, the Contractor shall safely, and in a
    timely manner return the Delivery Items to the Customer and notify MOOVEZ.`,
  },
  {
    type: "B1",
    number: "4",
    content: `The Parties acknowledge that each Moving Order is made and specified by the Customer using the
    MOOVEZ website, software or platform. The Parties acknowledge that the Customer makes the
    specifications regarding the Delivery Items, pick-up location, delivery location or otherwise other
    specifications regarding the Moving Order. The Parties acknowledge that the Customer requests and
    establishes the parameters of the Moving Order, and that MOOVEZ provides these details to the
    Contractor. The Contractor may accept or decline the Moving Order at its sole and absolute discretion,
    however upon accepting the Moving Order, the Contractor shall be obligated to provide the Services of
    the Agreement subject to other provisions in this Agreement.`,
  },
  {
    type: "B1",
    number: "5",
    content: `The Contractor
    shall make reasonable inquiries into the lawfulness and legality of the Delivery Items and its contents.
    The Contractor shall also make reasonable inquiries into the lawfulness and legality of the contents of
    the Moving Order. The Contractor shall be at liberty to cancel and stop providing services under the
    Agreement, before, during or after the delivery of the Delivery Items pursuant to a Moving Order,
    wherein it determines after reasonable inquiry, that the Delivery Items or its contents, or any requests,
    information or contents of the Moving Order are unlawful or illegal in any way. The Contractor shall
    make any referrals necessary to the appropriate authorities regarding any illegal or illicit Delivery Items
    or contents.`,
    bolded:
      "REASONABLE INQUIRY INTO CONTENTS OF DELIVERY ITEMS AND MOVING ORDER – ",
  },
  {
    type: "H1U",
    number: "",
    content: `PART III. CONTRACTOR REPRESENTATIONS, REQUIREMENTS AND ASSUMPTION OF RISK`,
  },
  {
    type: "B1",
    number: "1",
    content: `The Contractor warrants and represents that it satisfies all
    legal and regulatory requirements in providing services to MOOVEZ pursuant to this Agreement or
    otherwise in any other related services in its independent contractor relationship to MOOVEZ.`,
    bolded: "LEGAL AND REGULATORY REQUIREMENTS – ",
  },
  {
    type: "B2",
    number: "1.1",
    content: `Specifically, but not limited to the following, the Contractor warrants and represents that it
    satisfies all regulatory, provincial, municipal, federal or other legal requirements pursuant to the
    Contractor’s lawful operation of a motor vehicle, including, but not limited to a valid driver’s
    license, insurance, vehicle registration in attending at, transporting and delivering of the Delivery
    Items pursuant to the Moving Order.`,
  },
  {
    type: "B2",
    number: "1.2",
    content: `Specifically, but not limited to the following, the Contractor warrants and represents that it
    satisfies all regulatory, provincial, municipal, federal or other legal requirements pursuant to its
    attending at, pick-up and delivery of the Delivery Items pursuant to the Moving Order.`,
  },
  {
    type: "B1",
    number: "2",
    content: `The Contractor shall obtain at its own
    cost, commercial cargo liability and general liability insurance, insuring against the loss, stolen, damaged
    or late delivery of the Customer’s Delivery Items. However, the Parties acknowledge that the Agreement
    is an independent contractor agreement providing the Contractor with the freedomand expertise to
    complete the obligations under the Agreement, and to fulfill the Moving Order as presented by the
    Customer. The Contractor hereby accepts any and all risks with respect to its activities, and that the
    Contractor agrees to the Indemnity Provisions in this Agreement.`,
    bolded: "COMMERCIAL CARGO AND GENERAL LIABILITY INSURANCE – ",
  },
  {
    type: "B2",
    number: "2.1",
    content: `Accordingly, the Contractor accepts and agrees that any liability arising from the claimed
    damage, loss, stolen or late delivery, negligence or any other causes or rights of action by the
    Customer pursuant to the Delivery Items or Moving Order due to the Contractor’s services shall be
    borne of the Contractor, and that the Contractor elects to, or declines to obtain Commercial Cargo
    and General Liability Insurance at the Contractor’s own sole benefit, or at the Contractor’s own
    sole risk, as the case may be.`,
  },
  {
    type: "B2",
    number: "2.2",
    content: `Without expressly limiting the liability of the Contractor in the above-noted provision 3.2.1,
    in some cases, the Customer may to purchase Cargo Insurance for coverage of the specific Delivery
    Items during a Moving Order.`,
  },
  {
    type: "B1",
    number: "3",
    content: `The Parties acknowledge that the
    Agreement represents an independent contractor agreement between the Parties; and accordingly, the
    Parties acknowledge that the Contractor shall not be eligible for Workers’ Compensation benefits
    through MOOVEZ at all. MOOVEZ shall not be liable for, and shall not provide Worker’s Compensation
    Benefits at all to the Contractor.`,
    bolded: "WORKERS’ COMPENSATION AND OCCUPATIONAL INSURANCE – ",
  },
  {
    type: "B2",
    number: "3.1",
    content: `At its own cost, the Contractor shall be responsible for obtaining and providing Workers’
    Compensation benefits or any other required occupational accident insurance to the Contractor’s
    own workers, employees as required and permitted by law.`,
  },
  {
    type: "B2",
    number: "3.2",
    content: `The Parties recognize that due to the nature of the contracted services, specifically the pick
    up, transportation and delivery of the Delivery Items, the Contractor may wish to opt-in or obtain
    optional non-mandatory Worker’s Compensation insurance or other occupational accident
    insurance coverage at the Contractor’s own cost and expense. The Parties also agree that the
    Contractor may, as of its absolute right to obtain or decline to obtain optional non- mandatory
    Worker’s Compensation insurance or other occupational accident insurance coverage, at its own
    benefit or own sole risk as the case may be.`,
  },
  {
    type: "B1",
    number: "4",
    content: `The Parties agree that this Agreement constitutes an
    independent Contractor agreement, and that this Agreement shall not be construed in any way as to
    represent a relationship of an employee-employer, master-servant, or any relationship other than that
    of an independent contractor relationship. For further clarification, the following acknowledgements are
    made between the Parties:`,
    bolded: "INDEPENDENT CONTRACTOR RELATIONSHIP – ",
  },
  {
    type: "B2",
    number: "4.1",
    content: `the Parties acknowledge that the Contractor has the right to control the
    manner and method in providing services under this Agreement and pursuant to the Moving
    Order, so long as the Contractor safely provides moving services to the Customer in a timely
    manner. In addition, it is understood that MOOVEZ shall not have control over the Contractor’s
    manner or means of providing services under this Agreement. The Parties agree that subject to
    other provisions, MOOVEZ shall not require the Contractor to have a specific type, quality or
    choice of transportation; MOOVEZ shall not require the Contractor’s personal appearance,uniform
    or mandated; MOOVEZ shall not require that the Contractor report to or take instruction or
    direction from any supervisor or overseeing entity.`,
    bolded: "CONTROL – ",
  },
  {
    type: "B3",
    number: "4.1.1",
    content: `Notwithstanding the above-noted 3.4.1 provision, the Contractor
    acknowledges that MOOVEZ provides for different services, remuneration or
    payment for services regarding the Contractor’s type of vehicle. Specifically, the
    Contractor acknowledges that there may exist a different number of Moving
    Orders, more or less remuneration or more or less customer demand for specific
    vehicle types.`,
  },
  {
    type: "B2",
    number: "4.2",
    content: `the Parties acknowledge that the Contractor shall provide,
    obtain or use its own equipment and that the Contractor shall be liable for incurring its own
    expenses in performing its obligations under the Agreement. The Parties acknowledge that
    MOOVEZ shall not provide, nor be liable for any equipment used by the Contractor in the
    Contractor’s performance of its obligations under the Agreement or pursuant to the Moving Order.`,
    bolded: "EQUIPMENT AND EXPENSES –",
  },
  {
    type: "B2",
    number: "4.3",
    content: `the
    Parties acknowledge that the Contractor is free to personally complete its obligations, and as the
    case may be, or that the Contractor is free to employ, direct, or instruct agents, employees,
    subcontractors, or any other entities (together referred to as the “Contractor’s Personnel”) in
    order to perform the Contractor’s obligations under the Agreement. The Contractor acknowledges
    that any liability, damage or risk by the Contractor’s Personnel shall be borne of the Contractor.
    The Contractor also agrees to indemnify MOOVEZ of its actions and the actions of its Personnel as
    pursuant to this provision and the Indemnity Provisions of this Agreement herein.`,
    bolded:
      "PERSONAL PERFORMANCE, PERSONNEL, EMPLOYEES, AGENTS, SUBCONTRACTOR – ",
  },
  {
    type: "B2",
    number: "4.4",
    content: `The Contractor acknowledges that it is required to exercise its own due diligence and
    reasonable inquiries as to confirm that the Contractor’s Personnel do not overly and unnecessarily
    pose any personal, commercial or general risk to MOOVEZ or its Customers. The Contractor
    acknowledges that it is encouraged to conduct its reasonable and due diligence investigations
    regarding the Contractor’s Personnel to assess the suitability of the Contractor’s Personnel, before
    the fulfilling of its obligations under the Agreement as follows:`,
  },
  {
    type: "B3",
    number: "4.4.1",
    content: `The Contractor shall be at liberty to, and is encouraged to make
    reasonable and due diligence inquiries into any ongoing or past penal, criminal,
    or regulatory record or history of the Contractor’s Personnel including but not
    limited to: the criminal record, driver’s abstract or any other record of the
    Contractor’s Personnel if the Contractor so decides same is necessary,`,
  },
  {
    type: "B3",
    number: "4.4.2",
    content: `The Contractor shall be at liberty to, and is encouraged to make
    reasonable and due diligence inquiries into any ongoing civil, civil enforcement
    proceedings, or bankruptcy proceedings or history including but not limited to:
    Civil Judgment Debtor status, Bankruptcy Status or any other ongoing or past
    record or history of the Contractor’s Personnel if the Contractor so decides same
    is necessary,`,
  },
  {
    type: "B3",
    number: "4.4.3",
    content: `The Contractor shall be at liberty to, and is encouraged make any other
    reasonable and due diligence inquiries into any other matters affecting the ability,
    credibility or safety risks whatsoever regarding the Contractor’s Personnel in
    providing services on behalf of, or assigned services to MOOVEZ and MOOVEZ’s
    customers if the Contractor so decides same is necessary.`,
  },
  {
    type: "B1",
    number: "5",
    content: `the Contractor agrees that it assumes all risks inherent in its
    performance of this Agreement, including but not limited to the risk and damage to equipment, the risk
    and injury of itself or the Contractor’s Personnel, the payment of all amounts due to the Contractor’s
    Personnel, the obligations for the accounting, reporting and payment of all insurance premium
    payments, municipal, provincial, federal or other taxes and obligations arising from the remuneration
    for the Contractor’s services under the Agreement, any civil, criminal or regulatory suits, proceedings
    and liabilities arising from the Contractor’s conduct, representations and performance of its obligations
    under the Agreement, and any other obligations and liabilities arising from the Contractor’s
    performance of its obligations under the Agreement.`,
    bolded: "GENERAL ASSUMPTION OF RISK – ",
  },
  {
    type: "B2",
    number: "5.1",
    content: `The Contractor also agrees that MOOVEZ is not responsible, nor shall be liable against any
    liabilities arising between the Contractor and the Customer regarding any of the following, but not
    limited to: the damage to the Delivery Items, the damage to any persons or property of the
    Customer including the Delivery Items and its contents, or any damage or liability arising at all
    from the Moving Order. For further and additional clarity, MOOVEZ solely acts in its capacity to
    match Customers with Contractors, provide Moving Orders to the Contractor, and remit payment
    between the Customer and the Contractor using a third-party payment processing service. The
    Contractor understands that any liability arising from activities between the Contractor and the
    Customer shall be borne solely of the Contractor.`,
  },
  {
    type: "B1",
    number: "6",
    content: `The Contractor agrees to indemnify, protect and
    hold harmless MOOVEZ, including all parent, subsidiary and/or affiliated companies, as well as
    MOOVEZ’s past and present successors, assigns, officers, owners, directors, agents, representatives,
    attorneys, and employees, from any and all claims, demands, damages, suits, losses, liabilities and
    causes of action arising directly or indirectly from, as a result of or in connection with, the actions of the
    Contractor and the Contractor’s Personnel arising from the performance of its obligations under the
    Agreement, and any and all of the Contractor’s activities with the Customer at including personal injury
    or death to any person (including to the Contractor and the Contractor’s Personnel), as well as any
    liability arising from the Contractor’s or the Contractor’s Personnel’s failure to comply with the
    Agreement.`,
    bolded: "INDEMNIFICATION OF MOOVEZ BY CONTRACTOR – ",
  },
  {
    type: "B2",
    number: "6.1",
    content: `The Contractor's obligations hereunder shall include the cost of defense, including solicitor/
    client fees, as well as the payment of any final judgment rendered against or settlement agreed
    upon by MOOVEZ, its parent, subsidiary, other affiliated companies, MOOVEZ’s assigns, officers,
    owners, directors, agents, representatives, attorneys and employees.`,
  },
  {
    type: "B2",
    number: "6.2",
    content: `The Contractor agrees to indemnify, protect and hold harmless MOOVEZ, including all
    parent, subsidiary, and/or affiliated companies, as well as its/ their past and present successors,
    assigns, officers, owners, directors, agents, representatives, attorneys, and employees, from any
    and all tax liabilities and responsibilities for payment of all federal, provincial and local taxes,
    including, but not limited to all payroll taxes, self-employment taxes, workers compensation
    premiums, and any contributions imposed or required under federal, provincial, municipal and
    local laws, with respect to the Contractor and the Contractor's Personnel.`,
  },
  {
    type: "B2",
    number: "6.3",
    content: `The Contractor shall be responsible for, and agrees to indemnify and hold harmless
    MOOVEZ, including all parent, subsidiary, and/or affiliated companies, as well as its/their past and
    present successors, assigns, officers, owners, directors, agents, representatives, attorneys, and
    employees, from all costs of the Contractor's business, including, but not limited to, the expense
    and responsibility for any and all applicable insurance, local, provincial or federal licenses, permits,
    taxes, and assessments of any and all regulatory agencies, boards or municipalities.`,
  },
  {
    type: "B1",
    number: "7",
    content: `Upon written request to the Contractor by MOOVEZ or an
    individual, employee or agent acting on behalf of the Contractor shall produce and provide to MOOVEZ
    within five (5) business days, any and all documents materially relevant under the Agreement, including,
    but not limited to any of the following:`,
    bolded: "REQUIREMENT TO PROVIDE DOCUMENTS – ",
  },
  {
    type: "B2",
    number: "7.1",
    content: `The Contractor shall provide any certificates of
    insurance, proof of purchase or otherwise any documents whatsoever requested of the following
    insurance if available: General Liability Insurance, Cargo and Commercial Insurance, Workers’
    Compensation insurance, or any`,
    bolded: "Insurance Certificates and Policies – ",
  },
  {
    type: "B2",
    number: "7.2",
    content: `The Contractor shall provide any pictures, video,
    media, or otherwise any evidence or proof which may be retained by the Contractor or provided
    by the Customer, or otherwise any documents or evidence at all confirming the performance of
    the Moving Order.`,
    bolded: "Confirmation of Completion of Services –",
  },
  {
    type: "B2",
    number: "7.3",
    content: `The Contractor shall provide any and all correspondence between the
    Customer and the Contractor regarding the Delivery Services before, during or after the Delivery
    Services.`,
    bolded: "Correspondence – ",
  },
  {
    type: "B1",
    number: "8",
    content: `the Contractor agrees that that during this
    Agreement with MOOVEZ and for a period of twelve months (12) after the termination of this
    Agreement, the Contractor shall not directly or indirectly, separately or in association with others,
    approach, actively induce, attempt to recruit, or solicit in any manner any Customers who have actively
    been matched and connected with the Contractor through the MOOVEZ platform. MOOVEZ may, at its
    absolute and discretionary right, terminate the Agreement. As a result of the unlawful solicitation or
    other actions of the Contractor, MOOVEZ may also seek relief of any other remedies available to
    MOOVEZ in law or equity.`,
    bolded: "NON-SOLICITATION BY INDEPENDENT CONTRACTOR – ",
  },
  {
    type: "B2",
    number: "8.1",
    content: `Nothing in the above-noted non-solicitation provision, or anything else in this Agreement
    shall be construed as a non-competition clause, nor shall anything else in this Agreement be
    considered as any provision, which constitutes a relationship between the Parties than that of an
    independent contractor agreement.`,
  },
  {
    type: "B1",
    number: "9",
    content: `the Contractor agrees that the Contractor may be
    requested by MOOVEZ to provide documentation prior to entering into this Agreement, including, but
    not limited to: the Contractor’s or Contractor’s Personnel’s valid driver’s license, valid insurance, valid
    vehicle registration, driver’s abstract, any other insurance, or any other documents which are requested
    by MOOVEZ. Specifically, the Contractor agrees that failure to provide these documents or otherwise
    any other things requested by MOOVEZ may result in MOOVEZ’s denial of the Contractor’s application,
    or otherwise the disallowance of same Contractor to use the MOOVEZ platform.`,
    bolded: "REQUIREMENT TO PROVIDE DOCUMENTATION – ",
  },
  {
    type: "B2",
    number: "9.1",
    content: `The Contractor warrants the completeness, accuracy and totality of any representations
    provided by the Contractor to MOOVEZ, and that any documents provided are full and conclusive
    proof of same lawful representation without further investigation by MOOVEZ. The Contractor
    understands that any liability arising from the omission, failure to provide, misrepresentation or
    otherwise any other issues shall be borne of the Contractor. The Contractor also understands that
    the Contractor shall indemnify MOOVEZ against any and all liability arising from these omissions,
    representations in addition and pursuant to this provision and other Indemnity clauses hereto.`,
  },
  {
    type: "H1U",
    number: "",
    content: `PART IV. INDEPENDENT CONTRACTOR REMUNERATION AND PAYMENT`,
  },
  {
    type: "B1",
    number: "1",
    content: `The Parties acknowledge and agree that the payment and remuneration paid to the Contractor shall be
    paid by MOOVEZ to the Contractor based upon a varying payment amount specified, disclosed, notified
    to and agreed upon by the Parties prior to the Contractor accepting the Moving Order (the “Payment to
    Contractor”). The Payment to Contractor may also be varied and changed during or after the course of
    the Services provided by the Contractor.`,
  },
  {
    type: "B2",
    number: "1.1",
    content: `The Parties agree that this varying payment amount whether before, during or after the
    Contractor’s services shall be of absolutely and discretionary to MOOVEZ, and MOOVEZ may take
    into consideration the following below considerations.`,
  },
  {
    type: "B2",
    number: "1.2",
    content: `Accordingly, the Parties acknowledge and agree that the Payment to Contractor may be
    differing, floating and otherwise changing due to any or none of the following: market research,
    availability of Customer and Moving Order demands, availability of Contractors, or any other
    considerations at the sole and absolute discretion of MOOVEZ.`,
  },
  {
    type: "B2",
    number: "1.3",
    content: `You will be paid a fixed eighty-percent (80%) fee of the amount received by
    MOOVEZ based on actual Customer charges, calculated for each Customer instance
    of completed Delivery Services provided to a User that are obtained via the moovez
    Platform (“Fare”), where such Fare is calculated based upon standard fares (as determined
    by Company using location-based services enabled through the Device) and/or time amounts,
    (details of which can be obtained from the Company) for the applicable Territory (“Fare
    Calculation”).`,
  },
  {
    type: "B2",
    number: "1.4",
    content: `You acknowledge and agree that the Fare provided under the Fare Calculation as well as
    any gratuity which could increase your chance for profit are the only payments you will
    receive in connection with the provision of Delivery Services. You are also entitled to charge
    User for any Tolls, taxes or fees incurred during the provision of Delivery Services, if
    applicable, you appoint MOOVEZ as your limited payment collection agent solely for the
    purpose of accepting the Fare, applicable Tolls and, depending on the region and/or if
    requested by you, applicable taxes and fees from the Customer on your behalf via the
    payment processing functionality facilitated by the MOOVEZ or their third party provider.`,
  },
  {
    type: "B2",
    number: "1.5",
    content: `You acknowledge and agree that Customers may elect to cancel requests for Delivery
    Services that have been accepted by you via the Mover App at any time prior to your arrival.
    In the event that a Customer cancels an accepted request for Delivery Services, MOOVEZ
    may charge the Customer cancellation fee on your behalf. If charged, this cancellation fee
    shall be deemed the Fare for the cancelled Delivery Services for the purpose of remittance to
    you hereunder (“Cancellation Fee”). The parties acknowledge and agree that as between you
    and MOOVEZ, this Cancellation Fee is set by MOOVEZ and is absolute. If charged, the
    Cancellation Fee shall be deemed the Fare for the cancelled Delivery Services for
    the purpose of remittance to you hereunder but MOOVEZ reserves the right to
    deduct an amount per cancellation fee.`,
  },
  {
    type: "B2",
    number: "1.6",
    content: `As part of using the Services, MOOVEZ provides you a system for the delivery
    of receipts to Customers for Delivery Services rendered. Upon your completion of
    Delivery Services for a Customers, MOOVEZ prepares an applicable receipt and
    issues such receipt to the Customers via email or via the User’s passenger
    application on your behalf. Such receipts are also provided to you via email,
    Mover’s App, or the online portal available to you through Delivery Services.
    Receipts include the breakdown of amounts charged to the User for Delivery
    Services and may include specific information about you, including your name,
    contact information and photo, as well as a map of the route you took. Any
    corrections to a Customer’s receipt for Delivery Services must be submitted to
    MOOVEZ in writing within three (3) business days after the completion of such
    Delivery Services. Absent such a notice, MOOVEZ shall not be liable for any
    mistakes in or corrections to the receipt or for recalculation or disbursement of the
    Fare.`,
  },
  {
    type: "B2",
    number: "1.7",
    content: `You acknowledge and agree that, for the mutual benefit of the parties,
    through advertising and marketing, MOOVEZ and its affiliates may seek to attract
    new Customers to MOOVEZ and to increase existing Customer’s use of MOOVEZ’s
    mobile application. You acknowledge and agree such advertising or marketing, in
    vehicle or otherwise, does not entitle you to any additional monetary amounts
    beyond the amounts expressly set forth in this Agreement.`,
  },
  {
    type: "B1",
    number: "2",
    content: `MOOVEZ shall process
    payments made by Customers through a third-party payment processing service such as Stripe Connect
    (website: https://stripe.com/en-ca/connect) (hereinafter referred to as “Stripe Connect”), or other
    third-party payment processing services and shall provide these payments to the Contractor.`,
    bolded: "STRIPE CONNECT AND THIRD-PARTY PAYMENT PROCESSING SERVICE – ",
  },
  {
    type: "B2",
    number: "2.1",
    content: `The Contractor agrees not to hold MOOVEZ liable for any loss, claims or damages
    howsoever arising in connection with Stripe Connect or other third-party payment processing
    services. Any specific disputes or inquiries with respect to the third party’s processing services
    such as Stripe Connect shall be between the Contractor and Stripe Connect, or other the third-
    party payment processing service.`,
  },
  {
    type: "B1",
    number: "3",
    content: `MOOVEZ will provide payment to the Contractor within a week or longer after receiving confirmation
    of the Contractor’s completion of the Moving Order, and delivery the Delivery Items and obligations
    under the Agreement.`,
  },
  {
    type: "B1",
    number: "4",
    content: `The Contractor agrees that it will not hold liable for any loss,
    claims or damages howsoever arising in connection with that third-party payment processing services
    such as Stripe Connect as stated below in Part IV of this Agreement. Any specific disputes or inquiries
    with respect to the third party’s processing services such as Stripe Connect shall be between the
    Contractor and Stripe Connect.`,
    bolded: "MOOVEZ’S LIMITATION ON LIABILITY – ",
  },
  {
    type: "H1U",
    number: "",
    content: `PART V. TERMINATION`,
  },
  {
    type: "B1",
    number: "1",
    content: `The Contractor may terminate this Agreement by providing seven (7) clear business days’ notice to
    MOOVEZ. However, unless specifically agreed to otherwise between the Parties, the Contractor shall be
    obligated to complete any Moving Orders that the Contractor has agreed to prior to the effective
    termination date, including any Moving Orders accepted prior to or after providing the seven (7) clear
    business days’ termination notice.`,
  },
  {
    type: "B1",
    number: "2",
    content: `MOOVEZ may immediately terminate this Agreement between the Contractor in its sole and absolute
    discretion at any time by providing notice to the Contractor. However, even after terminating the
    Contract, MOOVEZ shall provide to the Contractor any payments and remuneration for any services or
    Orders entered into and completed prior to MOOVEZ’s termination of the Agreement.`,
  },
  {
    type: "B1",
    number: "3",
    content: `Nothing in this Part precludes the Parties jointly re-engaging and entering into another Agreement
    subject to mutual acceptance of the Parties.`,
  },
  {
    type: "H1U",
    number: "",
    content: `PART VI. MANDATORY MEDIATION AND ARBITRATION`,
  },
  {
    type: "B1",
    number: "1",
    content: `Should a seemingly unresolvable issue arise relating to this Agreement, the Parties shall engage in
    good-faith mediation discussions in an attempt to resolve any issues or alleged breaches arising from
    the actions of either Party in this Agreement. Should the Parties fail to resolve the issue through
    mediation, the matter shall then be referred to the following arbitration clause of this Agreement for
    determination.`,
  },
  {
    type: "B1",
    number: "2",
    content: `Upon the written demand of either party and within ten (10) days of the date of such demand, each
    party shall appoint an arbitrator and the (2) arbitrators so appointed shall promptly thereafter appoint a
    third. If either party shall fail to appoint an arbitrator within ten (10) days from the date of such
    demand, then the arbitrator shall, upon the application of the other party, be appointed by a Justice of
    the Court of Queen's Bench of Alberta. The arbitrators shall proceed immediately to hear and
    determinethe matter of controversy. The award of the arbitrators, or a majority of them, shall be made
    within forty-five (45) days after the appointment of the third arbitrator, subject to any reasonable delay
    due to unforeseen circumstances. The award of the arbitrators shall be drawn in writing and signed by
    the arbitrators, or a majority of them, and shall be final and binding upon the parties, and the parties
    shall abide by the award and perform the terms and conditions thereof. Unless otherwise determined by
    the arbitrators, the fees and expenses of the arbitrator named by MOOVEZ shall be paid by MOOVEZ,
    the fees and expenses of the arbitrator name by the Contractor shall be paid by the Contractor and the
    fees and expenses of the third arbitrator shall be paid in equal proportion by the Parties.
    Notwithstanding the foregoing, nothing contained herein shall preclude either party from seeking a
    judicial interpretationof any of the provision of this Agreement.`,
  },
  {
    type: "H1U",
    number: "",
    content: `PART VII. GENERAL CLAUSES`,
  },
  {
    type: "B1",
    number: "1",
    content: `This Agreement and each Party’s obligations shall be binding on their
    representatives, assigns and successors.`,
    bolded: "SUCCESSORS AND ASSIGNS.",
  },
  {
    type: "B1",
    number: "2",
    content: `The Laws of Alberta shall govern this Agreement, and the Courts in Alberta shall
    have jurisdiction over this Agreement.`,
    bolded: "GOVERNING LAWS – ",
  },
  {
    type: "B1",
    number: "3",
    content: `Time is of the essence, and all deadlines shall be strictly enforced.`,
    bolded: "TIME – ",
  },
  {
    type: "B1",
    number: "4",
    content: `Any ambiguity of terms in this Agreement shall not be construed negatively against
    either Party. Instead, any ambiguity shall be interpreted in good faith in a manner that is fair for both
    Parties.`,
    bolded: "AMBIGUITY - ",
  },
  {
    type: "B1",
    number: "5",
    content: `No delay or waiver by a Party in exercising or enforcing any of its rights, powers, or
    remedies under this Agreement, in whole or in part, will constitute a waiver of or otherwise prevent
    thatParty from enforcing that right, power or remedy on any subsequent occasion.`,
    bolded: "WAIVER – ",
  },
  {
    type: "B1",
    number: "6",
    content: `Any notice given under this Agreement will be delivered personally, or sent by prepaid
    registered mail, or email. Notice will be given to each Party either at its respective below-stated
    registered office or by email at its respective below-stated email address. Notices delivered by email will
    be deemed to be received on the next business day after the notice was sent.`,
    bolded: "NOTICE – ",
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
  {
    type: "",
    number: "",
    content: ``,
  },
];
