import axios from "axios";
import * as Sentry from "@sentry/react";
import { customerBaseUrl as baseUrl } from "../constants/baseUrl";
import DrivBaseUrl from "../constants/baseUrl";
import { returnAuthHeader } from "../../utils/auth";

export const getFAQs = async (setFAQs, setFAQsLoading) => {
  try {
    const { data } = await axios.get(`${baseUrl}Faq`);
    console.log("FAQ from API=======", data);
    setFAQs(data);
    setFAQsLoading(false);
  } catch (error) {
    console.log(error);
    setFAQsLoading(false);
    Sentry.captureException(error);
  }
};

//Contact us Message
//This action will send user's feedback to admin side.
export const ContactUsMessage =
  (contactObj, setStatus, setError) => async (dispatch) => {
    setError("");
    console.log("contact object", contactObj);
    try {
      const { data } = await axios.post(
        `${baseUrl}Auth/SendContactUsMessage`,
        contactObj
      );
      console.log("contact us res data", data);
      setStatus("verify");
      return data;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setError("Something went wrong!");
    }
  };

//Earnings stats API
//This action will get driver's earnings during a time interval.
export const driverEarnings =
  (driverId, setEarnings, setLoading, setError) => async (dispatch) => {
    try {
      const config = returnAuthHeader();
      const { data } = await axios.get(
        `${DrivBaseUrl}DriverDetailAPI/driverEarning/${driverId}`,
        config
      );
      setEarnings(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      Sentry.captureException(error);
      setError("Something went wrong!");
    }
  };

//Earnings stats API with graph Data
//This action will get driver's earnings during a time interval.
export const driverEarningsGraph =
  (driverId, year, setGraph, setLoading) => async (dispatch) => {
    try {
      const config = returnAuthHeader();
      const { data } = await axios.get(
        `${DrivBaseUrl}DriverDetailAPI/GetEarningGraph?driverId=${driverId}&currentYear=${year}`,
        config
      );
      setGraph(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      Sentry.captureException(error);
    }
  };

//Earnings stats API with graph Data
//This action will withdraw driver's earnings
export const cashOut =
  (driverId, action, setwithdrawalModal) => async (dispatch) => {
    try {
      const config = returnAuthHeader();
      const { data } = await axios.post(
        `${DrivBaseUrl}DriverDetailAPI/WithdrawMoney?driverId=${driverId}`,
        {},
        config
      );
      action();
      setwithdrawalModal(false);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
