import React from "react";
import Layout from "../../layouts/index";
import DriverLicenseScreen from "../../screens/onbording_web/DriverLicense";

const DriverLicense = () => {
  return (
    <Layout>
      <DriverLicenseScreen />
    </Layout>
  );
};

export default DriverLicense;
