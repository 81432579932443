import React from "react";
import { useHistory } from "react-router";
import css from "../../style/Home.module.css";

const Step = ({ index, text }) => {
  return (
    <div className={css?.s6CardM}>
      <div className={css?.s6CardHeading}>Step {index}</div>
      <div className={css?.s6CardText}>{text}</div>
    </div>
  );
};
const Section6 = () => {
  const history = useHistory();
  const steps = [
    "Once onboarded and verified, view all available orders from your moover homepage.",
    "Accept the jobs that work for you.",
    "Hit the road and complete the jobs.",
    "Check your earnings!",
  ];
  return (
    <div className={css?.s6M}>
      <div className={css?.s6L}>
        <img
          className={css?.s6LImg}
          src="/images/dashboard_screen.png"
          alt="phone"
        />
      </div>
      <div className={css?.s6R}>
        <div className={css?.s6RHeading}>How it works?</div>
        <div className={css?.s6Cards}>
          {steps?.map((text, index) => (
            <Step index={index + 1} text={text} />
          ))}
        </div>
        <div
          className={css?.s6RBtn}
          onClick={() => history.push("/Driver/Earnings")}
        >
          Earnings overview
        </div>
      </div>
    </div>
  );
};

export default Section6;
