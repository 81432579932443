import React, { useState, useEffect } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postVehicleIdsMobile } from "../../store/actions/onboardingActions";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const SwitchButton = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  marginRight: 25,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--primary)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const VehicleType = () => {
  const history = useHistory();
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [vehicleIds, setVehicleIds] = useState([]);
  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );

  const handleAddVehicle = (e, id) => {
    setError("");
    if (e?.target?.checked) setVehicleIds([...vehicleIds, id]);
    else setVehicleIds(vehicleIds.filter((vehicleId) => vehicleId !== id));
  };

  const handleSubmitIds = () => {
    if (vehicleIds.length) {
      if (type === "OwnVehicleDriverFlow" && vehicleIds.length > 1) {
        toast.error("Please select only 1 vehicle.");
        return;
      }
      postVehicleIdsMobile({ vehicleIds }, id, history);
    } else toast.error("At least 1 vehicle must be selected!");
  };

  useEffect(() => {
    dispatch(getAllVehicles());
  }, []);

  return (
    <div className={css?.mD}>
      <div className={css?.mHeading}>Select from the list below</div>
      <div className={css?.mtext}>
        Please let us know which vehicles you have available to complete jobs
        (select all that apply):
      </div>
      <div>
        {Array.isArray(vehicleTypesInfo) && vehicleTypesInfo?.length ? (
          <FormGroup>
            {vehicleTypesInfo.map((vehicle) => (
              <FormControlLabel
                sx={{ color: "var(--text)", margin: "10px 0" }}
                control={
                  <SwitchButton
                    onChange={(e) => handleAddVehicle(e, vehicle.id)}
                  />
                }
                label={vehicle.title}
              />
            ))}
          </FormGroup>
        ) : (
          <CircularProgress />
        )}
      </div>
      <ErrorComponent error={error} />
      <div
        className={css?.submitButton}
        style={{ position: "absolute", bottom: 20, right: "calc(50% - 130px)" }}
        onClick={handleSubmitIds}
      >
        Continue
      </div>
    </div>
  );
};

export default VehicleType;
