import React from "react";
import Layout from "../../layouts/index";
import StarterKitScreen from "../../screens/onbording/StarterKit";

const StarterKit = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <StarterKitScreen />
    </Layout>
  );
};

export default StarterKit;
