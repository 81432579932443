import { Step, StepLabel, Stepper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  getCategories,
  postDriverDetails,
  postDriverInfoWOvehicle,
} from "../../store/actions/driverRegistration";
import css from "../../style/driverRegistration.module.css";

const DriverRegistrationWithoutVehicle = () => {
  const { id } = useParams();
  const history = useHistory();
  // select categories
  const [categories, setcategories] = useState();
  const [selectedCategories, setselectedCategories] = useState([]);

  const [driverInformation, setdriverInformation] = useState({
    insuranceFile: false,
    registrationFile: false,
    conditionFile: false,
    typeOfVehicle: false,
    licenseFile: false,
    POWEligibilityFile: false,
    starterKit: {
      dolly: false,
      straps: false,
      blankets: false,
      glooves: false,
    },
  });
  // change screens
  const [ChangeScreen, setChangeScreen] = useState(0);
  // dispatch actions
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories(setcategories));
  }, []);

  const handleUploadDriverInfo = (e) => {
    e.preventDefault();
    if (
      driverInformation?.licenseFile &&
      driverInformation?.POWEligibilityFile &&
      driverInformation?.starterKit?.dolly &&
      driverInformation?.starterKit?.straps &&
      driverInformation?.starterKit?.glooves &&
      driverInformation?.starterKit?.blankets
    ) {
      const formData = new FormData(e.target);
      dispatch(postDriverInfoWOvehicle(formData, id, setChangeScreen));
    } else {
      alert("Please fill the entire form");
    }
  };

  const handleAddDriverInfo = (e) => {
    e.preventDefault();
    if (selectedCategories?.length > 0) {
      dispatch(
        postDriverDetails(
          { categoryIds: selectedCategories, vehicleIds: [] },
          id,
          history
        )
      );
    } else {
      alert("Please fill the entire form");
    }
  };

  const steps = [
    "Signup",
    "Verify Email",
    "Upload documents",
    "Select category",
    "Approval",
  ];

  return (
    <div className={css?.profileSettingsMainDiv}>
      <div className={css?.profileSettingsInnerMain}>
        {ChangeScreen === 0 ? (
          <div className={css?.profileSettingsInnerMain}>
            <form
              className={css?.accountDetails}
              onSubmit={handleUploadDriverInfo}
            >
              <Stepper
                className={css?.stepperClass}
                activeStep={2}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className={css?.accountDetailsHeading}>
                <div className={css?.accountDetailHeadingBackground}>
                  Moovers information
                </div>
              </div>
              {/* Driver's license */}
              <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Driver's license
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                    Driver's license requirements vary based on location. You
                    must be 21+ years old, have a valid Class 1,2 or 4 driver's
                    license.
                  </div>
                </div>
                <div className={css?.accountDetailsBoxInputArea}>
                  <div className={css?.accountDetailsBoxInputBox}>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-license-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-license-image"
                      type="file"
                      accept="image/*"
                      name="driverLicense"
                      className={css?.uploadImageInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            licenseFile: true,
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            licenseFile: false,
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.notSubmitted}>
                    {driverInformation?.licenseFile
                      ? "Submitted"
                      : "Not Submitted"}
                  </div>
                </div>
              </div>
              {/* Proof of work eligibility */}
              <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Proof of work eligibility
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                    One of Canadian Passport, Study or work permit. Canadian
                    Birth Certificate, Permanent Resident or Citizenship Car,
                    Scans and photocopies are not permitted.
                  </div>
                </div>
                <div className={css?.accountDetailsBoxInputArea}>
                  <div className={css?.accountDetailsBoxInputBox}>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-eligibility-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-eligibility-image"
                      type="file"
                      accept="image/*"
                      name="eligibilityProof"
                      className={css?.uploadImageInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            POWEligibilityFile: true,
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            POWEligibilityFile: false,
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.notSubmitted}>
                    {driverInformation?.POWEligibilityFile
                      ? "Submitted"
                      : "Not Submitted"}
                  </div>
                </div>
              </div>
              {/* Starter Kit */}

              <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Starter Kit
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                    Basic equipment is required before you can start accepting
                    jobs. Upload pictures of the listed items to complete your
                    registration process
                  </div>
                </div>
                <div className={css?.accountDetailsBoxInputArea}>
                  {/* Starter Uploads */}
                  <div className={css?.accountDetailsBoxInputStarterBox}>
                    <div className={css?.accounrDetailsInputMultiBox}>
                      <label>Dolly</label>
                      <span
                        className={css?.accountDetailsBoxInputBoxLabel}
                      ></span>

                      <label
                        for="upload-dolly-image"
                        className={css?.uploadImageSpan}
                      >
                        Upload document
                      </label>
                      <input
                        id="upload-dolly-image"
                        type="file"
                        accept="image/*"
                        name="StarterKitDollyFile"
                        className={css?.uploadImageStarterInput}
                        onChange={(e) => {
                          let data = e.target.files;
                          if (data?.length > 0) {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                dolly: true,
                              },
                            });
                          } else {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                dolly: false,
                              },
                            });
                          }
                        }}
                      ></input>
                    </div>
                    <div className={css?.accounrDetailsInputMultiBox}>
                      <label>Straps</label>
                      <span
                        className={css?.accountDetailsBoxInputBoxLabel}
                      ></span>
                      <label
                        for="upload-straps-image"
                        className={css?.uploadImageSpan}
                      >
                        Upload document
                      </label>
                      <input
                        id="upload-straps-image"
                        type="file"
                        accept="image/*"
                        name="StarterKitStrapsFile"
                        className={css?.uploadImageStarterInput}
                        onChange={(e) => {
                          let data = e.target.files;
                          if (data?.length > 0) {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                straps: true,
                              },
                            });
                          } else {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                straps: false,
                              },
                            });
                          }
                        }}
                      ></input>
                    </div>

                    <div className={css?.accounrDetailsInputMultiBox}>
                      <label>Blankets</label>
                      <span
                        className={css?.accountDetailsBoxInputBoxLabel}
                      ></span>
                      <label
                        for="upload-blankets-image"
                        className={css?.uploadImageSpan}
                      >
                        Upload document
                      </label>
                      <input
                        id="upload-blankets-image"
                        type="file"
                        accept="image/*"
                        name="StarterKitBlanketsFile"
                        className={css?.uploadImageStarterInput}
                        onChange={(e) => {
                          let data = e.target.files;
                          if (data?.length > 0) {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                blankets: true,
                              },
                            });
                          } else {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                blankets: false,
                              },
                            });
                          }
                        }}
                      ></input>
                    </div>
                    <div className={css?.accounrDetailsInputMultiBox}>
                      <label>Gloves</label>
                      <span
                        className={css?.accountDetailsBoxInputBoxLabel}
                      ></span>
                      <label
                        for="upload-gloves-image"
                        className={css?.uploadImageSpan}
                      >
                        Upload document
                      </label>
                      <input
                        id="upload-gloves-image"
                        type="file"
                        accept="image/*"
                        name="StarterBlanketsKitFile"
                        className={css?.uploadImageStarterInput}
                        onChange={(e) => {
                          let data = e.target.files;
                          if (data?.length > 0) {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                glooves: true,
                              },
                            });
                          } else {
                            setdriverInformation({
                              ...driverInformation,
                              starterKit: {
                                ...driverInformation?.starterKit,
                                glooves: false,
                              },
                            });
                          }
                        }}
                      ></input>
                    </div>
                  </div>

                  {/* Starter Multi Uploads Uploads */}
                  <div className={css?.notSubmitted}>
                    Dolly:{" "}
                    {driverInformation?.starterKit?.dolly
                      ? "Submitted"
                      : "not Submitted"}
                  </div>

                  <div className={css?.notSubmitted}>
                    Straps:{" "}
                    {driverInformation?.starterKit?.straps
                      ? "Submitted"
                      : "not Submitted"}
                  </div>

                  <div className={css?.notSubmitted}>
                    Blankets:{" "}
                    {driverInformation?.starterKit?.blankets
                      ? "Submitted"
                      : "not Submitted"}
                  </div>

                  <div className={css?.notSubmitted}>
                    Gloves:{" "}
                    {driverInformation?.starterKit?.glooves
                      ? "Submitted"
                      : "not Submitted"}
                  </div>
                </div>
              </div>

              {/* Background check */}
              <div className={css?.accountDetailsBox}>
                <div className={css?.accountDetailsBoxText}>
                  <div className={css?.accountDetailsBoxheading}>
                    Background Screening
                  </div>
                  <div className={css?.accountDetailsBoxtextText}>
                    Criminal history screen through third party vendors ISB and
                    Triton. Screening for those using their vehicle will also
                    include driving history check. Once your documents are
                    approved you will receive an email to begin your background
                    screening. Status update: Background screen processing may
                    be experiencing delays
                  </div>
                </div>
                {/* <div className={css?.accountDetailsBoxInputArea}>
                  <div className={css?.accountDetailsBoxInputBox}>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-profile-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload Document
                    </label>
                    <input
                      id="upload-profile-image"
                      type="file"
                      accept="image/*"
                      name="backgroundCheckFile"
                      className={css?.uploadImageInput}
                    ></input>
                  </div>
                  <div className={css?.notSubmitted}>not Submitted</div>
                </div> */}
              </div>

              <input
                type="submit"
                className={css?.submitButton}
                value="submit"
              />
            </form>
          </div>
        ) : (
          <div className={css?.profileSettingsInnerMain}>
            <div className={css?.accountDetails}>
              <Stepper
                className={css?.stepperClass}
                activeStep={3}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {categories &&
                Array.isArray(categories) &&
                categories?.length > 0 && (
                  <>
                    <div className={css?.headingCat}>
                      Select categories in which you want to work
                    </div>

                    {categories?.map((category) => (
                      <div className={css?.categoryLine}>
                        <div className={css?.categoryIcon}>
                          <i class={category?.categoryIcon}></i>
                        </div>
                        <div className={css?.categoryName}>
                          {category?.categoryName}
                        </div>
                        <div className={css?.categoryIcon}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setselectedCategories([
                                  ...selectedCategories,
                                  category?.id,
                                ]);
                              } else {
                                setselectedCategories(
                                  selectedCategories?.filter(
                                    (cat) => cat !== category?.id
                                  )
                                );
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              <button
                className={css?.submitButton}
                onClick={handleAddDriverInfo}
              >
                submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverRegistrationWithoutVehicle;
