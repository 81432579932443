import React from "react";
import Layout from "../layouts/index";
import ResetPassword from "../screens/Accounts/ChangePassword";

const ResetPasswordPage = () => {
  return (
    <Layout>
      <ResetPassword />
    </Layout>
  );
};

export default ResetPasswordPage;
