import React from "react";
import css from "../../style/ourStory.module.css";

const Section2 = () => {
  return (
    <div className={css?.S2M}>
      <div className={css?.s2Heading}>On-demand moving & delivery</div>
      <div className={css?.s2P}>
        Moovez was created to transform the way people move big & bulky items
        from A to B. Frustrated by a general lack of innovation in the moving &
        delivery industry, we set out develop a convenient solution. Our
        innovative approach allows us to tap into the existing network of moving
        & delivery vehicles on the road, & match them with the needs of our
        customers. This means that the closest available moover(s) will be
        paired to your job & in just a few clicks, they will be on their way to
        help get your stuff moved safely. Our platform allows customers to
        quickly & easily choose the vehicle and number of moovers that best fits
        their needs & budget.
      </div>
      <div className={css?.s2P}>
        This means they get the help they need, when they need it, without the
        hassle and stress of traditional moving services. In addition, our
        platform allows customers to track the progress of their moves in
        real-time, so they always know where their belongings are and when they
        will arrive. This level of transparency and accountability is what sets
        us apart from other moving & delivery services, and is why so many
        people trust us to handle their moves. moovez was created to make the
        moving & delivery process more convenient, reliable and affordable. Not
        sure if moovez is right for you? Give us a try and then decide if you’ll
        ever move a couch on your own again.
      </div>
    </div>
  );
};

export default Section2;
