import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
//reducers
import { loginReducer } from "./reducers/loginReducer";
import { vehicleTypesReducer } from "./reducers/vehicleTypeReducer";
import { driverProfile } from "./reducers/driverProfileReducer";

//combining the reducers
const reducer = combineReducers({
  loginReducer,
  vehicleTypesReducer,
  driverProfile,
});

const initialState = {};
// added the thunk middleware
const middleware = [thunk];

// created the redux store
const Store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
