import React from "react";
import { useHistory } from "react-router";
import css from "../../style/ourStory.module.css";

const Section4 = () => {
  const history = useHistory();
  return (
    <div className={css?.S4M}>
      <div className={css?.s4Heading}>Before you go, let’s talk business!</div>
      <div className={css?.s4Text}>
        Moovez has established itself as the go-to partner for many small and
        medium-sized businesses looking to solve their last mile delivery
        issues. We service a diverse range of industries from real estate, home
        staging, and interior design, to furniture stores, flooring stores, and
        other retail stores. Our expertise helps businesses scale their delivery
        capabilities, freeing them up to focus on growing their business.
        Whatever your moving & delivery needs are, moovez is here to help.
      </div>
      <div className={css?.s4Card}>
        <div className={css?.s4CardLeft}>
          <div className={css?.s4CardLeftHeading}>
            Become a moover and start earning.
          </div>
          <div className={css?.s4CardLeftTxt}>
            Earn money helping people move their stuff.
          </div>
          <div
            className={css?.s4CardLeftBtn}
            onClick={() => history.push("/Driver/MooverSignUp")}
          >
            Sign up now
          </div>
        </div>
        <img
          className={css?.s4Image}
          src="/images/driver.png"
          alt="Driver on the driving seat"
        />
      </div>
    </div>
  );
};

export default Section4;
