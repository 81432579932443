import React, { useState } from "react";
import css from "../../style/onbording.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsMobile } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const BusinessLicense = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");
  const [front, setFront] = useState();
  const [frontImg, setFrontImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name === "LegalBusinessLicense" && e.target.files?.length) {
      setFront(e.target.files[0]);
      setFrontImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitForm = () => {
    if (front) {
      const formData = new FormData();
      formData.append("LegalBusinessLicense", front);
      postDocumentsMobile(id, type, formData, setError, history);
    } else {
      toast.error("Please upload your business license.");
    }
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <>
          <label htmlFor="dl-front">
            <div className={css?.dlUploadMd}>
              <div className={css?.l2Heading}>Click below to take photo</div>
              <div className={css?.dlUploadImgBG}>
                <img
                  src={frontImg ?? "/images/upload.png"}
                  className={frontImg ? css?.UploadImg : css?.dlUploadImg}
                />
              </div>
            </div>
          </label>
          <input
            id="dl-front"
            name="LegalBusinessLicense"
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
          <ErrorComponent error={error} />
          <div className={css?.submitButton} onClick={handleSubmitForm}>
            Confirm
          </div>
        </>
      ) : (
        <>
          <div className={css?.mHeading}>
            Take a photo of your Business License
          </div>
          <div className={css?.mtext}>
            Please upload a current (non expired) photo of your Business
            License.
          </div>
          <div className={css?.mtext}>
            Please make sure your business name, business number and expiration
            date are clear and visible.
          </div>
          <div className={css?.imageDiv}>
            <img
              src="/images/backgroundCheck.png"
              className={css?.dlImage}
              style={{ margin: "30px 0" }}
            />
          </div>
          <div className={css?.submitButton} onClick={() => setTakePhoto(true)}>
            Take Photo
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessLicense;
