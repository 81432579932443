import React from "react";
import css from "../../style/earning.module.css";
import { useHistory } from "react-router";

const Section5_Requirements = () => {
  const history = useHistory();
  const requirements = [
    [
      "At least 18 years of age.",
      "Valid Canadian driver's license and auto insurance.",
      "Own a pickup truck, cargo van, box truck, or a vehicle with a trailer.",
      "Or sign up as a helper (No Vehicle).",
    ],
    [
      "Able and willing to lift 75 pounds overhead.",
      "Able and willing to be paid via e transfer.",
      "Own an iPhone or Android with GPS that can support the moover app.",
      "Pass a background check.",
    ],
  ];
  return (
    <div className={css?.s5Md}>
      <div className={css?.s5Heading}>Moovez requirements</div>
      <div className={css?.s5ReqFlex}>
        {requirements?.map((requirementArr) => (
          <div className={css?.s5ReqBox}>
            {requirementArr.map((requirement) => (
              <div className={css?.s5ReqLine}>
                <div className={css?.s5ReqDot}>.</div>
                <div className={css?.s5ReqText}>{requirement}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div
        className={css?.s5Button}
        onClick={() => history.push("/Driver/MooverSignUp")}
      >
        Become a moover
      </div>
    </div>
  );
};

export default Section5_Requirements;
