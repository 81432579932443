import {
  GET_VEHICLE_TYPES_FAIL,
  GET_VEHICLE_TYPES_SUCCESS,
  GET_VEHICLE_TYPES_REQUEST,
} from "../constants/vehicleConstants";
import axios from "axios";
import baseUrl, { customerBaseUrl } from "../constants/baseUrl";

// vehicle types action
// this action is called in multiple pages
// it shows vehicle and its types
export const getAllVehicles = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_VEHICLE_TYPES_REQUEST,
    });
    const { data } = await axios.get(`${customerBaseUrl}VehicleTypesAPI`);
    console.log("vehicle data from api", data);
    dispatch({
      type: GET_VEHICLE_TYPES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_VEHICLE_TYPES_FAIL,
      payload: "vehicle types API Failed,Please try again!",
    });
  }
};

// single vehicle action
// this action is called in multiple pages
// it shows vehicle specs
export const getSingleVehicle = (vehicleId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VEHICLE_TYPES_REQUEST,
    });
    const { data } = await axios.get(`${baseUrl}VehicleTypesAPI/${vehicleId}`);
    console.log("vehicle data from api", data);
    dispatch({
      type: GET_VEHICLE_TYPES_SUCCESS,
      payload: data?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_VEHICLE_TYPES_FAIL,
      payload: "vehicle types API Failed,Please try again!",
    });
  }
};
