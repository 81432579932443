import React from "react";
import Layout from "../layouts";
import MyEarnings from "../screens/Accounts/MyEarnings";

const MyEarningsPage = () => {
  return (
    <Layout>
      <MyEarnings />
    </Layout>
  );
};
export default MyEarningsPage;
