import React, { useEffect } from "react";
import Header from "../components/newHeader";
import Footer from "../components/footer";
import { populateUser } from "../store/actions/loginActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import css from "../style/index.module.css";

const Layout = ({ children, hideHeader = false, hideFooter = false }) => {
  // dispatch actions
  const dispatch = useDispatch();
  // history for routing
  const history = useHistory();
  // get object from the local storage and populate values
  useEffect(() => {
    const tempUser = JSON.parse(localStorage.getItem("moovezDriverProfile"));
    if (tempUser) {
      console.log(tempUser);
      dispatch(populateUser(tempUser, history));
    }
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        margin: 0,
        backgroundColor: "#f1f5ff",
      }}
      className={css?.ScrollBar}
    >
      {hideHeader ? <></> : <Header />}
      <div
        style={{
          minHeight: "90vh",
        }}
      >
        {children}
      </div>
      {hideFooter ? <></> : <Footer />}
    </div>
  );
};

export default Layout;
