import React from "react";
import Layout from "../../layouts/index";
import TermsOfServicesScreen from "../../screens/onbording/TermsOfServices";

const TermsOfServices = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <TermsOfServicesScreen />
    </Layout>
  );
};

export default TermsOfServices;
