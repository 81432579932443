import React from "react";
import css from "../../style/Home.module.css";

const Card = ({ icon, title, text }) => {
  return (
    <div className={css?.S5CardM}>
      <img className={css?.S5CardImg} src={`/images/${icon}.svg`} alt={icon} />
      <div className={css?.S5CardHeading}>{title}</div>
      <div className={css?.S5CardTxt}>{text}</div>
    </div>
  );
};
const Section5 = () => {
  const cardData = [
    {
      icon: "get_paid",
      title: "Get paid",
      text: "The more you work the more you earn. Our moovers routinely make $1,000-1,700 per week. Our customers like to tip and the good news is you keep 100% of the tips.",
    },
    {
      icon: "control",
      title: "Control your own schedule",
      text: "Work as much or as little as you want. Work every day of the week, for an hour, or on weekends. Only take jobs when it works for you.",
    },
    {
      icon: "register",
      title: "Simple registration process",
      text: "Requirements may vary but you can sign up within minutes. Once you’ve been onboarded and verified, you’ll be able to start accepting jobs.",
    },
    {
      icon: "training",
      title: "Work with friends",
      text: "The majority of our jobs require 2 moovers. Get matched with helpers or request your friends to join the platform & complete jobs together.Be your own boss & build your business with friends.",
    },
  ];
  return (
    <div className={css?.s5M}>
      <div className={css?.s5Heading}>Why moovez?</div>
      <div className={css?.s5Txt}>
        Whether you’re a moving company or just someone looking for the perfect
        side hustle. We provide you with jobs so you get to build a moving &
        delivery business on your own terms without the stress of finding new
        customers.
      </div>
      <div className={css?.s5Cards}>
        {cardData?.map((card) => (
          <Card {...card} />
        ))}
      </div>
    </div>
  );
};

export default Section5;
