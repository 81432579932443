import React from "react";
import css from "../style/Accounts.module.css";

const AccountCard = ({ data, history }) => {
  const { icon, title, description, link } = data;
  return (
    <div
      className={css?.AccountCardMainDiv}
      onClick={() => history.push(`${link}`)}
    >
      <div className={css?.AccountCardIcon}>
        <i class={icon} />
      </div>
      <div className={css?.AccountCardITitle}>{title}</div>
      <div className={css?.AccountCardTxt}>{description}</div>
    </div>
  );
};

export default AccountCard;
