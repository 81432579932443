export default [
  {
    type: "MH",
    number: "",
    content: `moovez PLATFORM TERMS OF SERVICE`,
    bolded: "",
  },
  {
    type: "IT1",
    number: "",
    content: `Last Updated: August 10, 2022`,
    bolded: "",
  },
  {
    type: "T1",
    number: "",
    content: `PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY AS IT CONSTITUTES A
    LEGALLY BINDING AGREEMENT BETWEEN YOU AND THE APPLICABLE MOOVEZ ENTITY
    (AS FURTHER DEFINED IN SECTION 1 OF PART I) IN RESPECT OF ACCESS AND USE OF
    MOOVEZ’S WEBSITE LOCATED AT WWW.WEMOOVEZ.COM AND ANY SUBDOMAINS USED
    IN CONNECTION WITH OR RELATED TO THE SAME AND ANY FUTURE VERSIONS
    (THE “WEBSITE”) AS WELL AS MOOVEZ MOBILE APPLICATIONS (THE “MOOVEZ
    PLATFORM APP”)(TOGETHER THE MOOVEZ PLATFORM APPAND THE WEBSITE
    CONSTITUTE THE “MOOVEZ PLATFORM”) THESE TERMS OF SERVICE APPLY TO ALL
    USERS OF THE MOOVEZ PLATFORM, INCLUDING USERS WHO ARE PROVIDERS OF
    MOVING SERVICES TO OTHER USERS OF THE MOOVEZ PLATFORM, USERS WHO
    PURCHASE SERVICES THROUGH THE MOOVEZ PLATFORM, USERS WHO DOWNLOAD THE
    MOOVEZ PLATFORM APP AND USERS WHO SIMPLY VIEW THE CONTENT ON OR AVAILABLE
    THROUGH THE WEBSITE (THE “SERVICES”). YOU MUST HAVE REACHED THE AGE OF
    MAJORITY IN YOUR JURISDICTION IN ORDER TO PLACE ORDERS THROUGH THE MOOVEZ
    PLATFORM AS A CUSTOMER OR TO OPERATE AS A MOVER (AS SUCH TERMS ARE
    DEFINED IN SECTION 2 OF PART I). IF YOU ARE USING THE MOOVEZ PLATFORM ON
    BEHALF OF ANOTHER PERSON OR A CORPORATE ENTITY, YOU REPRESENT AND
    WARRANT THAT YOU HAVE THE AUTHORITY TO BIND SUCH PERSON OR ENTITY TO THESE
    TERMS OF SERVICE. THE TERM “USER”, “YOU” OR “YOUR”, or “CUSTOMER” REFERS TO
    THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, ACCESSING OR OTHERWISE USING
    MOOVEZ PLATFORM OR THE MOOVEZ SERVICES. YOU ACKNOWLEDGE AND AGREE THAT
    THE MOOVEZ PLATFORM AND THE MOOVEZ SERVICES ARE MADE AVAILABLE TO
    FACILITATE THE DELIVERY OF FURNITURE AND OTHER HOUSEHOLD ITEMS (INCLUDING
    BUT NOT LIMITED TO FURNITURE, CONSTRUCTION MATERIALS, HOUSEHOLD ITEMS, ETC.)
    THAT MAY BE DELIVERED BY MOVERS. YOU UNDERSTAND THAT MOOVEZ’S TECHNOLOGY
    AND SERVICES FACILITATE THESE TRANSACTIONS IN CERTAIN JURISDICTIONS ONLY AND
    THAT MOOVEZ IS NOT A MOVER NOR IS MOOVEZ RESPONSIBLE FOR FULFILLING
    DELIVERIES PLACED BY CUSTOMERS WITH MOVERS.`,
    bolded: "",
  },
  {
    type: "T1",
    number: "",
    content: ``,
    bolded: `BY ACCESSING, BROWSING AND USING THE MOOVEZ PLATFORM, YOU INDICATE THAT
    YOU HAVE READ AND ACCEPT THESE TERMS OF SERVICE. IF YOU DO NOT ACCEPT
    THESE TERMS OF SERVICE, THEN YOU MAY NOT ACCESS OR USE THE MOOVEZ
    PLATFORM OR MOOVEZ SERVICES OR ANY OF THEIR CONTENT OR SERVICES.`,
  },
  {
    type: "T1",
    number: "",
    content: `PLEASE BE AWARE THAT SECTIONS 9 AND 10 OF PART I CONTAIN
    IMPORTANT DISCLAIMERS AND LIMITATIONS OF LIABILITY. IN ADDITION, SECTION 12 OF
    PART I CONTAINS PROVISIONS GOVERNING HOW CLAIMS THAT YOU AND WE HAVE AGAINST
    EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS`,
    bolded: "IMPORTANT NOTICE:",
  },
  {
    type: "T1",
    number: "",
    content: `THAT AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF THIS AGREEMENT.
    IN PARTICULAR, IT CONTAINS AN ALTERNATIVE DISPUTE RESOLUTION PROCESS AND YOU
    WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN
    INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
    REPRESENTATIVE ACTION OR PROCEEDING AND YOU ARE WAIVING YOUR RIGHT TO SEEK
    RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.`,
    bolded: "",
  },
  {
    type: "T1",
    number: "",
    content: `When changes are made, moovez will make a new copy of the Terms of Service available on the
    moovez Platform. We will also update the "Last Updated" date at the top of the Terms of Service. If
    we make any material changes, and you have registered with us to create an account we may
    alsos end an e-mail to you at the last e-mail address you provided to us pursuant to the Terms of
    Service. Any changes to the Terms of Service will be effective immediately after posting notice of
    such changes on the moovez Platform. moovez may require you to provide consent to the
    updated Terms of Service in a specified manner before further use of the moovez Platform and/or
    the moovez Services is permitted. If you do not agree to any change(s) after receiving a notice of
    such change(s), you shall stop using the moovez Platform and/or the moovez Services. Otherwise,
    your continued use of the moovez Platform and/or moovez Services constitutes your acceptance
    of such change(s). PLEASE REGULARLY CHECK THE MOOVEZ PLATFORM TO VIEW THE
    MOST CURRENT TERMS.`,
    bolded: `THESE TERMS OF SERVICE ARE SUBJECT TO CHANGE BY MOOVEZ IN ITS SOLE
    DISCRETION AT ANY TIME TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.`,
  },
  {
    type: "T1",
    number: "",
    content: `You should read these Terms of Service in their entirety, however, this index is provided for
    ease of reference to enable you to navigate to specific sections of the Terms.`,
    bolded: "INDEX",
  },
  {
    type: "B1U",
    number: "I",
    content: `GENERAL TERMS`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "1",
    content: `moovez Parties`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "2",
    content: `Users andApplicable Legal Terms`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "3",
    content: `moovezAccounts`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "4",
    content: `LicensedAccess to the moovez Platform and Permitted Use`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "5",
    content: `No Unlawful or Prohibited Use`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "6",
    content: `Intellectual Property Terms`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "7",
    content: `Platform Updates andAvailability`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "8",
    content: `Disclaimers`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "9",
    content: `No Implied Warranties; Limitations of Liability
    and Release of Liability`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "10",
    content: `Indemnification`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "11",
    content: `Governing Law and Dispute Resolution`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "12",
    content: `Miscellaneous`,
    bolded: "",
  },
  {
    type: "B1U",
    number: "II",
    content: `ORDERING TERMS`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "1",
    content: `Access, moovez Services and Products`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "2",
    content: `moovez CustomerAccount`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "3",
    content: `Placing Deliveries`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "4",
    content: `Customer Care, Third Party Movers and Refunds`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "5",
    content: `Payment Processing Services`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "6",
    content: `Price and Payment`,
    bolded: "",
  },
  {
    type: "B1U",
    number: "III",
    content: `REFER-A-FRIEND PROGRAM, PROMOTIONAL VOUCHERS, GIFT CARDS AND
    MOOVEZ CREDITS AND CONTESTS`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "1",
    content: `Refer-A-Friend Program`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "2",
    content: `Promotional Vouchers`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "3",
    content: `Gift Card Terms and moovez Credits`,
    bolded: "",
  },
  {
    type: "B2U",
    number: "4",
    content: `Contests/Competitions`,
    bolded: "",
  },
  {
    type: "B1",
    number: "I",
    content: ``,
    bolded: "GENERAL TERMS",
  },
  {
    type: "B2",
    number: "1",
    content: ``,
    bolded: "moovez Parties",
  },
  {
    type: "B3",
    number: "A",
    content: `If you are accessing or using the moovez Platform within Canada, these
    Terms of Service form an agreement between you and MUV Logistics
    Network Technologies Ltd., a company incorporated under the laws of
    Canada.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `MUV Logistics Network Technologies Ltd. is referred to in these Terms of
    Service as the “moovez Group”. For the avoidance of doubt, moovez
    does not include any third parties such as Vendors and Movers (as such
    terms are defined in Section 2 of Part I offering services through the
    moovez Platform.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "2",
    content: ``,
    bolded: "Users and Applicable Legal Terms",
  },
  {
    type: "B3",
    number: "A",
    content: `The moovez Platform facilitates the ordering by
    consumers/end users of the moovez Platform ("Customers") of
    delivery and moving services through independently contracted movers
    who are users of the moovez Platform (the "Movers"). Collectively,
    Customers, Movers constitute "Users" of the moovez Platform and
    these Terms of Service apply to all Users.`,
    bolded: "Definition of Users.",
  },
  {
    type: "B3",
    number: "B",
    content: `Please note that important information about
    moovez’s practices on the collection, use and disclosure of Users’
    personal information submitted via the Website or the moovez Platform
    App is governed by our Privacy Policy, the terms of which are
    incorporated into this Terms of Service. Please see further: https://wemoovez.com/Home/MUVPrivacyPolicy ("Privacy Policy”).`,
    bolded: "moovez Privacy Policy.",
  },
  {
    type: "B3",
    number: "C",
    content: `In addition to these Terms of Service and
    the Privacy Policy, Movers are subject to additional terms agreed in
    separate agreements with moovez in respect of the services offered by
    such Users. In the event of any conflict between these Terms of Services
    and the separate agreement you have with moovez, the terms of the
    separate moovez agreement will govern.`,
    bolded: "Additional Terms for Movers.",
  },
  {
    type: "B3",
    number: "D",
    content: `To the extent the other terms and conditions of these Terms of
    Service are less restrictive than, or otherwise conflict with, the terms and
    conditions of this Section, the more restrictive or conflicting terms and
    conditions in this Section apply, but solely with respect to the use of
    Google Maps services and content.`,
    bolded:
      "Additional Terms and Conditions for moovez Platform Web App Users.",
  },
  {
    type: "T1",
    number: "",
    content: `The moovez Platform may use services and content from Google Maps or Google
    Earth. You acknowledge and agree that you are bound by the Google Maps/Google
    Earth Additional Terms of Service (including the Google Privacy Policy).`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `By creating an
    account on the moovez Platform, you agree that the Services may send you
    informational text (SMS) messages as part of the normal business operation
    of your use of the Services. You may opt-out of receiving text (SMS)
    messages from moovez Group at any time by sending an email to
    support@wemoovez.com indicating that you no longer wish to receive such
    messages, along with the phone number of the mobile device receiving the
    messages. You acknowledge that opting out of receiving text (SMS) messages
    may impact your use of the Services.`,
    bolded: "Text Messaging, Notifications, and Communications.",
  },
  {
    type: "B2",
    number: "3",
    content: ``,
    bolded: "moovez Accounts",
  },
  {
    type: "B3",
    number: "A",
    content: `You represent and warrant that all information
    supplied by you on the moovez Platform (including information provided
    by you to create an account for use of the moovez Platform (a "moovez
    Account")) or in connection with your use of the moovez Services is true,
    accurate, current and complete.`,
    bolded: "Accurate Information.",
  },
  {
    type: "B3",
    number: "B",
    content: `Any moovez Account must be kept secure
    and you agree that you will not share or disclose your moovez Account
    credentials with anyone. No members of the moovez Group will be liable
    for any loss or damage arising from your failure to safeguarding your
    moovez Account, use a strong password or limit its use to your moovez
    Account. You may browse some of the moovez Platform without creating a
    moovez Account.`,
    bolded: "Security of moovez Accounts.",
  },
  {
    type: "B3",
    number: "C",
    content: `moovez reserves the right
    to deny your request for a moovez Account (including usernames) and to
    disable or terminate access to any moovez Account (including usernames)
    issued to you at any time in moovez’s sole discretion. If moovez disables
    access to a moovez Account issued to you, you will be prevented from
    accessing the moovez Platform or the moovez Services, your moovez
    Account details, Moves, Deliveries or other information that is associated
    with your moovez Account.`,
    bolded: "Discretionary Access to moovez Accounts.",
  },
  {
    type: "B3",
    number: "D",
    content: `We
    may suspend or terminate your ability to access the moovez Platform, or
    cease providing you with all or part of the moovez Services at any time for
    any or no reason, including, if we believe: (i) you may have violated these
    Terms of Service, (ii) you may create risk or possible legal exposure for us;
    (iii) prolonged inactivity; or (iv) our provision of the moovez Services to you
    is no longer commercially viable. Suspension or termination may also
    include removal of some or all of the materials uploaded by you. We may
    make reasonable efforts to notify you by the email address associated with
    your account, through the moovez Platform or the next time you attempt to
    access your account, depending on the circumstances. You acknowledge
    and agree that all suspensions or terminations may be made by moovez in
    its sole discretion and that no members of the moovez Group will be liable
    to you or any third-party for any suspension or termination of your access
    or for the removal of any of the materials uploaded by you to the moovez
    Platform. Any suspension or termination of this Agreement by moovez will
    be in addition to any and all other rights and remedies that moovez may
    have.`,
    bolded: "Suspension, Term, Termination and Deletion of moovez Accounts.",
  },
  {
    type: "B3",
    number: "E",
    content: `You may request the deletion of your
    account by requesting (by email or through any then-available interfaces)
    that your moovez Account be deleted, ceasing use of the moovez Platform,
    the moovez Services and uninstalling and removing all local software
    components thereof, if any. If you request deletion of your moovez
    Customer Account, we may disable your moovez Customer Account but
    keep the disabled moovez Customer Account for fraud prevention or other
    lawful purposes. If we have received all outstanding payment from you, you
    may request termination these Terms of Service at any time by requesting
    by email (or any then-available interface on the moovez Platform) that your
    moovez Account be deleted. We may terminate these Terms of Service at
    any time by giving notice to you, at our discretion, by email at your current
    email address on file with us or through the moovez Platform.`,
    bolded: "Account Deletion and Termination.",
  },
  {
    type: "B2",
    number: "4",
    content: ``,
    bolded: "Licensed Access to The moovez Platform and Permitted Use",
  },
  {
    type: "B3",
    number: "A",
    content: `Subject to your compliance with these Terms of Service, moovez hereby
    grants you a personal, revocable, non-exclusive and non-transferable
    license to use the moovez Platform in accordance with these Terms of
    Service in the following manner depending on the type of User that you are:`,
    bolded: "",
  },
  {
    type: "B4",
    number: "i",
    content: `for Customers, you may use the moovez Platform to
    request moving/delivery services and pay for these
    services. You may also use feedback features of the
    moovez Platform to rate, post comments on Movers
    (as applicable);`,
    bolded: "",
  },
  {
    type: "B4",
    number: "ii",
    content: `for Movers, you may create an account and use the
    moovez Platform to manage and fulfill requests for
    deliveries,`,
    bolded: "",
  },
  {
    type: "B4",
    number: "iii",
    content: `for all Customers and Movers, you acknowledge and
    agree that the Services are made available solely for
    your personal, non-commercial use. YOU
    ACKNOWLEDGE AND AGREE THAT MOOVEZ
    GROUP DOES NOT PROVIDE TRANSPORTATION
    OR LOGISTICS SERVICES OR FUNCTION AS A
    TRANSPORTATION CARRIER AND THAT ALL
    SUCH TRANSPORTATION OR LOGISTICS
    SERVICES ARE PROVIDED BY INDEPENDENT
    THIRD PARTY CONTRACTORS WHO ARE NOT
    EMPLOYED BY MOOVEZ GROUP OR ANY OF
    ITS AFFILIATES.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "",
    content: `(i)-(iii) constitute the "Permitted Use" as applicable for a specific type of
    User.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `moovez retains the right, at its sole discretion, to
    deny access to anyone to the moovez Platform or the moovez Services,
    at any time and for any reason (or no reason at all), including, but not
    limited to, for violation of these Terms of Service or use other than the
    Permitted Use. You will cease and desist from any such access or use of
    the moovezPlatform or moovez Services immediately upon request by
    moovez.`,
    bolded: "Reservation of Rights.",
  },
  {
    type: "B3",
    number: "C",
    content: `The moovez Platform
    and/or specific services may not be available at all or any times and in all
    or any jurisdictions. Furthermore, nothing on the moovez Platform
    constitutes an offer or solicitation to buy or sell any product or service to
    anyone in any jurisdiction in which such an offer or solicitation may be
    prohibited by law.`,
    bolded: "Geographic Application of the moovez Platform.",
  },
  {
    type: "B3",
    number: "D",
    content: `moovez reserves the right to change or
    allow a third party to change any information, material or content contained
    on or provided through the moovez Platform (the "Content") at any time,
    and from time to time, without notice. For greater certainty, the Content
    include the User Content (as defined below).`,
    bolded: "Changes to the moovez Platform.",
  },
  {
    type: "B2",
    number: "5",
    content: ``,
    bolded: "No Unlawful or Prohibited Use",
  },
  {
    type: "B2",
    number: "",
    content: `You may not, without moovez's prior written permission, use the moovez Platform,
    the moovez Services or any Content (including other User’s User Content) for
    purposes other than the applicable Permitted Use. Without limiting the generality of
    the foregoing, you will not, and will not permit anyone else to, or attempt to use the
    moovez Platform and/or the Content to:`,
    bolded: "",
  },
  {
    type: "B3",
    number: "A",
    content: `"frame", "mirror" or otherwise incorporate the moovez Platform, the
    moovez Services or the Content or any part thereof on any commercial or
    non-commercial website;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `access, monitor or copy any part of the moovez Platform, the moovez
    Services or the Content using any robot, spider, scraper or other
    automated means or any manual process for any purpose without moovez’s
    express written permission;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `violate any laws;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `violate the restrictions in any robot exclusion headers on the Content or
    the moovez Platform or bypass or circumvent other measures employed to
    prevent or limit access to the moovez Services or the moovez Platform;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `remove (or permit anyone else to remove) any watermarks, labels or other
    legal or proprietary notices included in the moovez Platform or the Content;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "F",
    content: `modify or attempt to modify (or permit anyone else to modify or attempt to
        modify) the moovez Platform, including any modification for the purpose of
        disguising or changing any indications of the ownership or source of the
        moovez Platform;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "G",
    content: `forge headers or otherwise manipulate identifiers in order to disguise the
    origin of any material transmitted to or through the moovez Platform or
    impersonate another person or organization or misrepresent their affiliation
    with a person or entity;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "H",
    content: `attempt to, assist, authorize or encourage others to circumvent, disable or
    defeat any of the security features or components, such as digital rights
    management software or encryption, that protect the moovez Platform or the moovez Services;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "I",
    content: `copy, reproduce, modify, translate, distribute, transfer, sell, publish,
    broadcast, perform, transmit, license or circulate in any form any part of the
    moovez Platform or the Content;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "J",
    content: `license, sell, rent, lease, transfer, assign, distribute, host, or otherwise
    commercially exploit the moovez Platform;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "K",
    content: `create derivative works based on the moovez Platform, the moovez
    Services or the Content, in whole or in part, or decompile, disassemble,
    reverse engineer or other exploit any part of the moovez Platform, the
    moovez Services or the Content;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "L",
    content: `use of access the moovez Platform in a manner that violates the rights
    (including, but not limited to contractual, intellectual property or proprietary
    rights) of any third party; or`,
    bolded: "",
  },
  {
    type: "B3",
    number: "M",
    content: `upload to or transmit through the moovez Platform any information, images,
    text, data, media or other content that is offensive, harmful, tortuous, hateful,
    obscene, defamatory or violates any laws or is otherwise objectionable, in
    each case as determined by moovez in its sole discretion.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "6",
    content: ``,
    bolded: "Intellectual Property Terms",
  },
  {
    type: "B3",
    number: "a",
    content: ``,
    bolded: "Content and Intellectual Property",
  },
  {
    type: "B4",
    number: "i",
    content: `The moovez
    Platform and all content contained therein (excluding User Content
    as defined in Section 6(b) below), is owned or licensed by moovez
    and protected by copyright, trade-mark and other intellectual
    property laws ("moovez Content"). moovez expressly reserves all
    rights in the moovez Platform and all materials provided by
    moovez in connection with these Terms of Service that are not
    specifically granted to you. You acknowledge that all right, title and
    interest in the moovez Platform, all materials provided by moovez
    in connection with these Terms of Service (including the moovez
    Content), and any update, adaptation, translation, customization or
    derivative work thereof, and all intellectual property rights therein
    will remain with moovez (or third party suppliers or licensors, if
    applicable), and that the moovez Platform and all materials
    provided by moovez hereunder are licensed and not "sold" to you.`,
    bolded: "moovez Content and Intellectual Property Rights.",
  },
  {
    type: "B4",
    number: "ii",
    content: `All information, data, text, software, music, sound,
    photographs, graphics, video, messages or other materials, whether
    publicly posted or privately transmitted to the moovez Platform by
    Users ("User Content"), is the sole responsibility of such Users.
    This means that the User, and not members of the moovez Group,
    are entirely responsible for all such material uploaded, posted,
    emailed, transmitted or otherwise made available on the moovez
    Platform. moovez may monitor User Content from time to time but
    moovez does not guarantee the accuracy, integrity or quality of such
    content. Under no circumstances will any members of the moovez
    Group be liable in any way for any User Content including for any
    loss or damage of any kind incurred as a result of the viewing or
    use of any materials posted, emailed, transmitted or otherwise made
    available via the moovez Platform. To the extent your User Content
    contains any personally identifiable information, that data is handled
    in accordance with the moovez Privacy Policy and all other User
    Content will be considered non-confidential.`,
    bolded: "User Content.",
  },
  {
    type: "B4",
    number: "iii",
    content: `moovez may collect data, information,
    records, files, material or other content from your local computer
    (including mobile) system or from third-parties with your permission,
    and (in each case) including all results from processing such data,
    including compilations, and derivative works thereof ("User-Related
    Content"). You grant to moovez Group a perpetual, transferrable,
    irrevocable, royalty-free, worldwide and sublicensable license to
    access, collect, store and use any such User-Related Content. To the
    extent your User-Related Content contains any personally identifiable
    information, that data is handled in accordance with the moovez
    Privacy Policy. User-Related Content is not considered confidential
    or proprietary.`,
    bolded: "User-Related Content.",
  },
  {
    type: "B3",
    number: "b",
    content: `By submitting, posting or displaying User
    Content on or through the moovez Platform or otherwise to moovez, you grant
    moovez Group, a worldwide, non-exclusive, royalty-free, perpetual, transferable,
    and fully sublicensable right to use, refrain from using, remove, reproduce,
    modify, edit, copy, adapt, publish, translate, create derivative works from,
    distribute, transmit, perform display and otherwise use User Content, in whole
    or in part. You further grant moovez the right (although moovez does not have
    the obligation) to pursue at law any person or entity that violates your or
    moovez’s rights in the User Content by a breach of these Terms of Service. You
    represent and warrant that you have all the rights, power and authority
    necessary to grant the rights granted herein to any User Content that you
    submit. For the avoidance of doubt, moovez has no obligation to post or display
    any User Content on the moovez Platform.`,
    bolded: "User Content License to moovez.",
  },
  {
    type: "B3",
    number: "c",
    content: `You may only
    use moovez Content and other Users Content (eg. menus provided by Vendors) for the applicable Permitted Use. If you print extracts from the moovez Platform
    for your own personal, non-commercial use, you must not modify the digital or
    papers of such materials or use any graphics, pictures, photographs or videos
    separately from any accompanying text.`,
    bolded: "Use of moovez Content and User Content by other Users.",
  },
  {
    type: "B3",
    number: "d",
    content: ``,
    bolded: "Terms on Submissions/Feedback and Other User Content",
  },
  {
    type: "B4",
    number: "i",
    content: `Through the
    capabilities of the moovez Platform you may be able to provide ratings
    ("Submissions"). Submissions are a subset of User Content. In creating
    and posting any Submissions (or other User Content) you represent and
    warrant that you will not post or transmit to or from the moovez Platform
    any material or content which does or may:`,
    bolded: "Creating Submissions and Other User Content.",
  },
  {
    type: "B5",
    number: "1",
    content: `Breach any applicable local, national or international law;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "2",
    content: `Be unlawful or fraudulent;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "3",
    content: `Amount to unauthorized advertising;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "4",
    content: `Contain any defamatory, obscene or offensive material;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "5",
    content: `Promote violence or discrimination;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "6",
    content: `Infringe the intellectual property rights of another person;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "7",
    content: `Breach any legal duty owed to a third party (such as a
        duty of confidence);`,
    bolded: "",
  },
  {
    type: "B5",
    number: "8",
    content: `Promote illegal activity or invade another’s privacy;`,
    bolded: "",
  },
  {
    type: "B5",
    number: "9",
    content: `Give the impression that they originate from us; or`,
    bolded: "",
  },
  {
    type: "B5",
    number: "10",
    content: `Be used to impersonate another person or to misrepresent
    your affiliation with another person.`,
    bolded: "",
  },
  {
    type: "B4",
    number: "",
    content: `You will be solely liable for any damages resulting from any violation
    of the foregoing restrictions, or any other harm resulting from your
    posting of Submissions or other User Content to the moovez
    Platform.`,
    bolded: "",
  },
  {
    type: "B4",
    number: "ii",
    content: `We reserve the
    right to remove or edit at any time any Submissions or other User Content
    posted, uploaded or transmitted to the moovez Platform that we
    determine breaches the restrictions in Section 6(d)(i) above or is
    otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for any reason. Any Submissions on the
    moovez Platform is for information purposes only and does not constitute
    advice from us or the opinions of anyone within the moovez Group. User
    Content may reflect the opinions of Customers who have ordered through
    the moovez Platform and any statements, advice or opinions provided by
    such persons are theirs only. Accordingly, to the fullest extent permitted
    by law, no members of the moovez Group assume any responsibility or
    liability to any person for any User Content, including without limitation
    any mistakes, defamation, obscenity, omissions or falsehoods that you
    may encounter in any such materials.`,
    bolded: "Status of Submissions on the moovez Platform.",
  },
  {
    type: "B4",
    number: "iii",
    content: `You acknowledge that moovez may
    choose to provide attribution of your Submissions (for example, listing a
    date, User’s name and city on a User’s rating that you submit) at moovez’s
    discretion, and that such User Content may be visible to other Users and
    visitors to the moovez Platform. By providing a rating/review you
    acknowledge and consent to us using your given name in association with
    the rating/review. All ratings/reviews that we receive may be moderated
    before we publish them. We may do this to make sure that ratings/reviews
    fit our content guidelines.`,
    bolded: "Public Display of Submissions.",
  },
  {
    type: "B3",
    number: "e",
    content: `If you access any Person Information through or in
    connection with the moovez Platform you agree that you will treat such Personal
    Information in accordance with moovez's current Privacy Policy and you will not
    collect or harvest any Personal Information, including Personal Information of
    other users, except as permitted by these Terms of Service.`,
    bolded: "Note on Privacy.",
  },
  {
    type: "B2",
    number: "7",
    content: ``,
    bolded: "Availability and Updates",
  },
  {
    type: "B3",
    number: "A",
    content: `moovez may alter, suspend, or discontinue the moovez Platform or
    Service at any time and for any reason or no reason, without notice. The
    moovez Platform or Service may be unavailable from time to time due to
    maintenance or malfunction of computer or network equipment or other
    reasons. moovez may periodically add or update the information and
    materials on this the moovez Platform without notice.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `While we try to ensure the moovez Platform is normally available twenty
    four (24) hours a day, we do not undertake any obligation to do so, and no
    members of the moovez Group will be liable to you if the moovez Platform
    is unavailable at any time or for any period.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `moovez may upgrade or alter the moovez Platform at any time. moovez
    may remotely access the moovez Platform (including for greater certainty
    any moovez Platform App) at any time for any lawful reason, in particular to
    update the software or to perform software maintenance.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "8",
    content: ``,
    bolded: "DISCLAIMERS",
  },
  {
    type: "B3",
    number: "",
    content: `THE LAWS OF CERTAIN JURISDICTIONS, INCLUDING QUEBEC, DO NOT
    ALLOW THE EXCLUSION OR LIMITATION OF LEGAL WARRANTIES,
    CONDITIONS OR REPRESENTATIONS. IF THESE LAWS APPLY TO YOU, SOME
    OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN THESE TERMS OF SERVICE
    (INCLUDING THE FOLLOWING DISCLAIMERS AND LIMITATIONS OF LIABILITY)
    MAY NOT APPLY AND YOU MAY HAVE ADDITIONAL RIGHTS.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "A",
    content: `While we try to ensure that information on the moovez Platform is correct,
    we do not promise it is accurate or complete. We may make changes to the material
    on the moovez Platform including to Services and prices described on it, at any time
    without notice. The material on the moovez Platform may be out of date, and we make
    no commitment to update that material. The foregoing disclaimers do not affect
    Customer’s statutory rights against any other Business or Mover.`,
    bolded: "Content: ",
  },
  {
    type: "B3",
    number: "B",
    content: `Service Disclaimers:`,
    bolded: "",
  },
  {
    type: "B4",
    number: "i",
    content: `The downloading and viewing of the moovez Platform or
    the Content is done at your own risk. moovez cannot and does not
    guarantee or warrant that the moovez Platform or the Content are
    compatible with your computer system or that the moovez
    Platform or the Content, or any links from the moovez Platform or
    the Content, will be free of viruses, worms, malware, trojan horses
    or disabling devices or other code that manifests contaminating or
    destructive properties. You are responsible for implementing
    safeguards to protect the security and integrity of your computer
    system, and you are responsible for the entire cost of any service,
    repairs or connections of and to your computer system that may
    be necessary as a result of your use of the moovez Platform.`,
    bolded: "Viruses. ",
  },
  {
    type: "B3",
    number: "ii",
    content: `moovez does not guarantee
    the confidentiality of any communications made by you through the
    moovez Platform. Although moovez generally adheres to the
    accepted industry practices in securing the transmission of data to,
    from and through the moovez Platform, you understand, agree and
    acknowledge that moovez cannot and does not guarantee the
    security of data transmitted over the Internet or public networks in
    connection with your use of the moovez Platform.`,
    bolded: "Communications Not Confidential.",
  },
  {
    type: "B2",
    number: "9",
    content: ``,
    bolded: "NO IMPLIED WARRANTIES; LIMITATIONS OF LIABILITY.",
  },
  {
    type: "B3",
    number: "A",
    content: `THE MOOVEZ
    SERVICES, THE MOOVEZ PLATFORM AND THE CONTENT ARE
    PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY
    KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, MOOVEZ DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS
    OF ANY KIND WITH RESPECT TO THE MOOVEZ SERVICES, MOOVEZ
    PLATFORM AND THE CONTENT WHETHER EXPRESS, IMPLIED,
    STATUTORY OR COLLATERAL, INCLUDING, WITHOUT LIMITATION,
    THE IMPLIED WARRANTIES AND CONDITIONS OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
    NON-INFRINGEMENT OR THAT THE MOOVEZ SERVICES, THE MOOVEZ PLATFORM OR THE CONTENT ARE OR WILL BE ERROR-
    FREE OR WILL OPERATE WITHOUT INTERRUPTION. MOOVEZ GROUP MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE
    REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY
    OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS
    REQUESTED THROUGH THE USE OF THE SERVICES, OR THE
    QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
    PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF
    YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD
    REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH
    YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
    LAW.`,
    bolded: "NO IMPLIED WARRANTIES AND DISCLAIMERS.",
  },
  {
    type: "B3",
    number: "B",
    content: `IN NO EVENT WILL MOOVEZ
    OR ANY MEMBER OF THE MOOVEZ GROUP BE LIABLE, WHETHER
    BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT
    LIABILITY OR ANY OTHER LEGAL THEORY, FOR ANY DAMAGES OF
    ANY KIND AT LAW (INCLUDING, WITHOUT LIMITATION, INDIRECT,
    INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE
    DAMAGES, LOST PROFITS, LOSS OF REVENUE, LOSS OF USE,
    LOSS OF DATA, PERSONAL INJURY, FINES, FEES, PENALTIES OR
    OTHER LIABILITIES), WHETHER OR NOT MOOVEZ IS ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM OR
    RELATED TO THE USE OF, OR THE INABILITY TO MAKE USE OF, THE
    MOOVEZ SERVICES, THE MOOVEZ PLATFORM OR THE CONTENT.`,
    bolded: "EXCLUSION OF INDIRECT DAMAGES.",
  },
  {
    type: "B3",
    number: "C",
    content: `MOVERS OFFERING SERVICES
    THROUGH THE MOOVEZ PLATFORM ARE INDEPENDENT PERSONS
    OR ORGANIZATIONS AND NOT REPRESENTATIVES, AGENTS OR
    EMPLOYEES OF MOOVEZ. MOOVEZ IS THEREFORE NOT LIABLE
    FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS,
    WARRANTIES, CONTRACTUAL BREACHES OR NEGLIGENCE OF ANY
    VENDORS OR MOVERS OR FOR ANY PERSONAL INJURY, DEATH,
    PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES
    RESULTING THERE FROM AND TAKES NO RESPONSIBILITY
    WHATSOEVER FOR THE PRODUCTS OR SERVICES OFFERED BY
    MOVERS.`,
    bolded: "NO RESPONSIBILITY FOR MOVERS.",
  },
  {
    type: "B3",
    number: "D",
    content: `MOOVEZ GROUP SHALL NOT BE LIABLE
    FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
    CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST
    DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN
    CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF
    THE SERVICES, EVEN IF MOOVEZ GROUP HAS BEEN ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES. MOOVEZ GROUP SHALL NOT
    BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT
    OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
    INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY
    TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD
    PARTY PROVIDER, EVEN IF MOOVEZ GROUP HAS BEEN ADVISED OF
    THE POSSIBILITY OF SUCH DAMAGES. MOOVEZ GROUP SHALL NOT
    BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING
    FROM CAUSES BEYOND MOOVEZ GROUP’S REASONABLE
    CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY
    TRANSPORTATION PROVIDERS PROVIDING TRANSPORTATION
    SERVICES REQUESTED THROUGH SOME REQUEST BRANDS MAY
    OFFER MOVING TRANSPORTATION SERVICES AND MAY NOT BE
    PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL
    MOOVEZ GROUP’S TOTAL LIABILITY TO YOU IN CONNECTION WITH
    THE SERVICES FOR ALL DAMAGES, LOSSES AND CAUSES OF
    ACTION EXCEED 100 HUNDRED DOLLARS ($100).`,
    bolded: "LIMITATION OF LIABILITY.",
  },
  {
    type: "B3",
    number: "E",
    content: `Additional costs: You assume full and sole responsibility for any additional
    or associated costs that you may incur in connection with or as a result of
    your use of the moovez Platform, including without limitation costs relating
    to the servicing, repair or adaptation of any equipment, software or data
    that you may own, lease, license or otherwise use. THE LIMITATIONS ABOVE REFLECTS A FAIR ALLOCATION OF RISK BUT
    FOR WHICH MOOVEZ WOULD NOT MAKE THE MOOVEZ PLATFORM
    AND/OR MOOVEZ SERVICES AVAILABLE TO USERS. THE LIMITATIONS
    SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY
    LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO HAVE
    FAILED OF ITS ESSENTIAL PURPOSE.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "10",
    content: ``,
    bolded: "Indemnification and Release of Liability",
  },
  {
    type: "B2",
    number: "",
    content: `You agree to defend, indemnify and hold harmless moovez Group, its licensors
    and their directors, officers, agents, contractors, partners, representatives and
    employees from and against any threated or actual claims, causes of action,
    demands, recoveries, losses, liabilities, damages, fines, penalties or other costs or
    expenses of any kind or nature including but not limited to reasonable legal fees,
    incurred in connection with or as a result of:`,
    bolded: "",
  },
  {
    type: "B3",
    number: "A",
    content: `your use of the moovez Platform or use of the Services, in any manner
    whatsoever;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `your breach of these Terms of Service or any documents referenced
    herein;`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `your violation of any law or the rights of a third party, including third
    party providers (including, without limitation, intellectual property rights);`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `your breach of or failure to perform in respect of any Deliveries made by
    you or by any third party acting on your behalf or with your permission;
    or moovez reserves the right, at its own expense, to assume the
    exclusive defence and control of any matter otherwise subject to
    indemnification by you hereunder, and you shall cooperate as fully as
    reasonably required by moovez.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `You shall release and indemnify and hold MOOVEZ harmless from and
    against any and all damages, fines, fees, settlements, payments,
    obligations, penalties, deficiencies, losses, costs and expenses
    (including, without limitation, interest, court costs, reasonable fees of
    attorneys, accountants and other experts and other reasonable
    expenses of litigation or other proceedings or of any claim, default or
    assessment) (collectively, the "Losses") suffered, incurred or sustained
    by any member of the MOOVEZ or which any member of MOOVEZ
    becomes subject at any time, by reason of any action, claim, suit,
    proceeding, arbitration, notification, investigation or audit to the extent
    arising out of or resulting from:`,
    bolded: "",
  },
  {
    type: "B4",
    number: "i",
    content: `any breach of the Customer’s representations, warranties or
    obligations under this Agreement, or non-fulfillment of or failure to
    perform any covenant or agreement made by the Customer in this
    Agreement;`,
    bolded: "",
  },
  {
    type: "B4",
    number: "ii",
    content: `any negligent act or omission on the part of the Customer, its
    employees, contractors, or agents;`,
    bolded: "",
  },
  {
    type: "B4",
    number: "iii",
    content: `the misuse of the Services by the Customer, its employees,
    contractors, agents or other persons.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "F",
    content: `You agree to sign the standard Waiver and Release of Liability, which is
    attached to and forms part of these Terms of Service.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "11",
    content: ``,
    bolded: "Governing Law and Dispute Resolution",
  },
  {
    type: "B3",
    number: "A",
    content: `These Terms of Service will be governed by the laws of
    the Province of Alberta, unless you access the moovez Platform or receive
    the moovez Services in Quebec, in which case the laws of the Province of Quebec apply to the extent of such access or use of the moovez Services,
    and the federal laws of Canada applicable therein. These laws apply to
    your access to or use of the, the moovez Platform, the moovez Services or
    the Content, notwithstanding your domicile, residency or physical location.
    The moovez Platform, the moovez Services and the Content are intended
    for use only in jurisdictions where they may lawfully be offered for use.`,
    bolded: "Governing Law.",
  },
  {
    type: "B3",
    number: "B",
    content: ``,
    bolded: "Dispute Resolution Process.",
  },
  {
    type: "B4",
    number: "i",
    content: `Except to the extent restricted by applicable law, if there is any
    dispute or controversy between (1) you and (2) moovez or any
    members of the moovez Group, including any dispute or
    controversy arising out of or relating to these Terms of Service, the
    moovez Platform or moovez Services, any interactions or
    transactions between (1) you and (2) moovez or any members of
    the moovez Group, or in respect of any legal relationship
    associated with or derived from these Terms of Service, including the
    validity, existence, breach, termination, construction or application, or
    the rights, duties or obligations of you or us, (each, a "Dispute"),
    the disputing person will serve notice on the other person and each
    you and us must use good faith efforts to resolve the Dispute
    informally.`,
    bolded: "",
  },
  {
    type: "B4",
    number: "ii",
    content: `Except to the extent restricted by applicable law, if the Dispute is
    not resolved after twenty (20) business days of a person serving
    notice on the other party that there is a Dispute, you and us agree
    that the Dispute will be finally resolved by confidential arbitration
    before a single arbitrator in accordance with the Arbitration Rules of
    the ADR Institute of Canada, Inc. The seat of the arbitration will be
    Ontario or wherever convenient or necessary acting reasonably.
    There will be no appeals of any kind. The language of the arbitration
    will be English unless otherwise required by applicable law or agreed
    to by you and us. moovez will pay the reasonable arbitration costs.`,
    bolded: "",
  },
  {
    type: "B4",
    number: "iii",
    content: `Except to the extent restricted by applicable law, you and us agree
    that you and us will resolve any Dispute on an individual basis. Any
    claim you may have must be brought individually, in your individual
    capacity and not as a representative plaintiff or class member, and
    you will not join such claim with claims of any other person or entity,
    or bring, join or participate in a class action lawsuit, collective or
    representative proceeding of any kind (existing or future) against
    any members of the moovez Group.`,
    bolded: "",
  },
  {
    type: "B4",
    number: "iv",
    content: `Nothing in this Section will prohibit us from seeking interim
    measures from a court, including preliminary or injunctive relief of
    breach of you of any intellectual property rights.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "12",
    content: ``,
    bolded: "Miscellaneous",
  },
  {
    type: "B3",
    number: "A",
    content: `Unless the context clearly requires otherwise, (a)
    references to the plural include the singular, the singular the plural, the
    partthe whole, (b) references to any gender include all genders, (c)
    “including” has the inclusive meaning frequently identified with the phrase
    “but not limited to” and (d) references to “hereunder” or “herein” relate to
    this Agreement. The section headings in this Agreement are for reference
    and convenience only and shall not be considered in the interpretation of
    these Terms of Service.`,
    bolded: "Interpretation.",
  },
  {
    type: "B3",
    number: "B",
    content: `If you are a Customer, these Terms of Service, the
    Privacy Policy and any documents incorporated by reference herein
    constitute the entire agreement between moovez and you pertaining to the
    subject matter hereof and supersede all prior or contemporaneous
    communications and proposals, whether electronic, oral or written, between
    you and moovez with respect to the moovez Platform. A printed version of
    these Terms of Service and of any notice given in electronic form will be
    admissible in judicial or administrative proceedings based upon or relating
    to these Terms of Service to the same extent and subject to the same
    conditions as other business documents and records originally generated
    and maintained in printed form.`,
    bolded: "Entire Agreement.",
  },
  {
    type: "B3",
    number: "C",
    content: `moovez may, in its sole discretion, provide any notices to you in
    connection with these Terms of Service through the moovez Platform or by
    email at the then-current email address for you on file with moovez.
    moovez may be contacted in writing at:`,
    bolded: "Notices.",
  },
  {
    type: "B3",
    number: "",
    content: `support@wemoovez.com`,
    bolded: "",
  },
  {
    type: "B3",
    number: "",
    content: `moovez may change its notice contact information from time to time by
    posting updated contact details on the moovez Platform.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `moovez's failure to insist upon or enforce strict performance of
    any provision of these Terms of Service will not be construed as a waiver
    of any provisions or right.`,
    bolded: "No Waiver.",
  },
  {
    type: "B3",
    number: "E",
    content: `moovez will not be liable to you for any failure of or delay
    in the performance of its obligations under these Terms of Service for the
    period that such failure or delay is due to causes beyond moovez's
    reasonable control, including but not limited to acts of God, power outages,
    internet disconnectivity, war, strikes or labor disputes, embargoes,
    government orders or any other force majeure event.`,
    bolded: "Force Majeure.",
  },
  {
    type: "B3",
    number: "F",
    content: `If any of the provisions contained in these Terms of Service
    are determined to be void, invalid or otherwise unenforceable by a court of
    competent jurisdiction, the provision shall be modified by the court and
    interpreted so as best to accomplish the objectives of the original provision
    to the fullest extent permitted by law, and the remaining provisions of this
    Agreement shall remain in effect.`,
    bolded: "Severability.",
  },
  {
    type: "B3",
    number: "G",
    content: `If any of the provisions contained in these Terms
    of Service conflict with the terms of another agreement between you and
    us, then these Terms of Service will prevail.`,
    bolded: "Delivery of Precedence.",
  },
  {
    type: "B3",
    number: "H",
    content: `These Terms of Services are made available in English`,
    bolded: "Language.",
  },
  {
    type: "B3",
    number: "I",
    content: `You may not transfer any of your rights or obligations under
    these Terms of Service without our prior written consent. We may transfer
    any of our rights or obligations under these Terms of Service without your
    prior written consent to any of our affiliates or any business that we enter
    intoa joint venture with, purchase or are sold to.`,
    bolded: "Assignment.",
  },
  {
    type: "B1",
    number: "II",
    content: ``,
    bolded: "ORDERING TERMS",
  },
  {
    type: "B2",
    number: "1",
    content: ``,
    bolded: "Access, moovez Services and Products",
  },
  {
    type: "B2",
    number: "",
    content: `Through the moovez Platform you may place orders for Deliveries from Movers,
    and moovez may provide customer care support between you and Movers (these
    services and associated services such as order management, payment processing
    for Moves and Deliveries, and provision of access to the moovez Platform
    provided by moovez from time to time are the "moovez Services"). For avoidance
    of doubt, moovez does not provide delivery services directly and no members of
    the moovez Group will be liable for any transactions between Customers, and
    Movers. moovez may provide services to Movers through a separate agreement
    executed with such parties.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "2",
    content: ``,
    bolded: "moovez Customer Account",
  },
  {
    type: "B2",
    number: "",
    content: `To use the moovez Platform and most features of the moovez Services, you are
    required to successfully create a user account (the "moovez Customer Account")
    using the available interfaces at the moovez Platform.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "3",
    content: ``,
    bolded: "Placing Moves or Deliveries",
  },
  {
    type: "B3",
    number: "A",
    content: `Once you have created your moovez Customer Account you may request a
    move or delivery from point A to point B, select the delivery details may
    be edited at any time prior to placing a Move or Delivery. Once you have
    made your selection, you may proceed to place your Move or Delivery. At
    this time, you will have the opportunity to review your Move or Delivery details. After you review and confirm that the move or delivery details are
    correct, you may proceed to place your Move or Delivery, provide your
    payment card information, and a Move or Delivery will be created and sent
    to the Mover. You are responsible to ensure that all of your Move or Delivery
    details, including billing, addresses and other information is current,
    complete and accurate. Your payment card will be charged when you place
    your Move or Delivery if the Move or Delivery is a Card Move or Delivery
    (as defined in section 6(b)) and subject to the payment terms and conditions
    of Section 6 herein. Once you have placed your Move or Delivery, you will
    be charged a fee for cancellation. You will not be entitled to change your
    payment card once a move or delivery is already in progress and you will
    not be entitled to a full refund (except where required by law).
    Notwithstanding that you are not entitled as of right to change your payment
    card during an ongoing move or delivery or cancel your Move or Delivery
    and expect a full refund (except where prohibited bylaw), if you wish to
    amend or cancel your Move or Delivery for a total or partial refund of your
    Move or Delivery’s price, you can do so and will be charged according to
    the stage at which the Move or Delivery was cancelled. You can email or
    call the moovez support phone line at the contact information page provided
    on the moovez Platform for more information.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `Because of standard banking procedures, once you have placed a Card
    Delivery and your payment has been authorized, your bank or card issuer
    will "hold" the full amount of your Delivery. If your Delivery is not confirmed
    by the Mover or cancelled for any other reason, your bank or card issuer
    will not transfer the funds for the Delivery to us, and will instead release the
    relevant amount back into your available balance. However, this may take a
    period of typically up to 5 working days (and in some cases up to 30 days,
    depending on your bank or card issuer). You acknowledge and agree that
    no members of the moovez Group nor the relevant Mover will be
    responsible or liable to you in relation to this delay by your bank or card
    issuer in the release of funds back into your account.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `You agree that moovez or the Mover will have no liability to you for Deliveries
    that are not confirmed, are cancelled or that the Mover has been unable or
    unwilling to fulfill.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "4",
    content: ``,
    bolded: "Customer Care, Third Party Movers and Refunds",
  },
  {
    type: "B3",
    number: "A",
    content: `moovez may offer customer care in respect of issues relating to the
    Movers. If you have any problems, questions or suggestions with respect to
    the moovez Platform or your Move or Delivery, please contact moovez’s
    customer care team (and not the Mover) via email or phone at the contact
    information provided on the moovez Platform.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `moovez may provide, in its discretion, the refund or compensation by payment to your payment credit or debit card or providing the Customer with
    moovez Credits (as defined below).`,
    bolded: "",
  },
  {
    type: "B2",
    number: "5",
    content: ``,
    bolded: "Payment Processing Services",
  },
  {
    type: "B2",
    number: "",
    content: `Payment processing services for the moovez Platform are provided by Stripe, Inc.
    (collectively referred to as the "Payment Processing Services Provider"). You
    hereby authorize moovez to share with the Payment Processing Services Provider
    that financial information, banking details and transaction information related to your
    use of the payment processing services and the moovez Platform. You also agree
    that the moovez Group is not responsible for the use or misuse of such financial
    information, banking details and transaction information by the PaymentProcessing
    Services Provider or any third party.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "6",
    content: ``,
    bolded: "Price and Payment",
  },
  {
    type: "B3",
    number: "A",
    content: `Delivery prices listed on the moovez Platform from Vendor in Canada are
    in Canadian dollars, Taxes and delivery charges may be added at checkout
    and will be payable by you along with the Delivery price.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `Payment for Deliveries must be made with an accepted credit or debit card
    through the moovez Platform ("Card Deliveries"). You understand that
    use of the Services may result in charges to you for the services or
    goods you receive from a Third Party Provider (“Charges ”). After you
    have received services or goods obtained through your use of the
    Service, moovez Group will facilitate your payment of the applicable
    Charges on behalf of the Third Party Provider as such Third Party
    Provider’s limited payment collection agent. Payment of the Charges
    in such manner shall be considered the same as payment made
    directly by you to the Third Party Provider. Charges will be inclusive of
    applicable taxes where required by law. Charges paid by you are final
    and non-refundable, unless otherwise determined by moovez Group.
    You retain the right to request lower Charges from a Third Party
    Provider for services or goods received by you from such Third Party
    Provider at the time you receive such services or goods. Moovez
    Group will respond accordingly to any request from a Third Party
    Provider to modify the Charges for a particular service or good.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `All Charges are due immediately and payment will be facilitated by
    moovez Group using the preferred payment method designated in
    your account, after which moovez Group will send you a receipt by
    email. If your primary Account payment method is determined to be
    expired, invalid or otherwise not able to be charged, you agree that
    moovez Group may, as the Third Party Provider’s limited payment
    collection agent, use a secondary payment method in your Account, if available.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `For Card Deliveries, you will be charged at the time of successful
    completion of your Move or Delivery. The moovez Platform uses the third
    party payment processors to effect charges onto your debit or credit card.
    You consent to the collection and use of your information (including, if
    applicable, personal information) by such payment processing service as
    necessary to process your payments. We reserve the right to change, or to
    stop accepting, any permitted payment method at any time in our sole
    discretion. You agree we may charge your payment card for any order
    placed and for any additional amounts (including any taxes) as may be
    applicable in connection with your purchase. You are responsible to ensure
    that all of your billing information is current, complete, and accurate. We will
    provide you with an online or emailed billing summary statement which you
    may review, save, or print at your discretion. This is the only billing
    statement that will be provided by us.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `moovez or the Mover is under no obligation to fulfill your Move or Delivery at
    the incorrect price. Any such differences will not entitle you to a refund or a
    reduction in price of your Move or Delivery.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "F",
    content: `Move or Delivery charges and any other fees are subject to change at any
    time at moovez’s discretion.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "G",
    content: `If you fail to make any payment when due, without limiting our other
    rights and remedies: (i) Provider may charge interest on the past due
    amount at the rate of twelve percent (12%) per annum or, if lower, the
    highest rate permitted under applicable law; (ii) Customer shall reimburse
    moovez Group for all costs incurred by Provider in collecting any late
    payments or interest, including legal fees on a solicitor and own client
    basis, court costs, and collection agency fees.`,
    bolded: "",
  },
  {
    type: "B1",
    number: "III",
    content: ``,
    bolded: `REFER-A-FRIEND PROGRAM, PROMOTIONAL VOUCHERS, GIFT CARDS AND
    MOOVEZ CREDITS AND CONTESTS.`,
  },
  {
    type: "B2",
    number: "1",
    content: ``,
    bolded: "Refer-A-Friend Program",
  },
  {
    type: "B3",
    number: "A",
    content: `If you are a moovez Customer Account holder, you may be entitled to
    voluntarily participate in the moovez refer a friend program (the “Referral
    Program"). A unique alphanumeric code is assigned to your moovez code
    ("Referral Code"). To participate in the Referral Program, you will need to
    refer friends who have never placed an order with us ("Friends") and invite
    your Friends to use the moovez Platform with your Referral Code.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `You will earn a moovez credit or discount ("Referral moovez Credit") in the
    amount we determined from time to time once your Friend ("Referred User")
    has completed an eligible order on the moovez Platform ("Eligible Delivery").
    Criteria for Eligible Deliveries is determined in our sole discretion from time to time. Referral Credit will be applied automatically against your item subtotal
    on your next Card Delivery, up to and including the item subtotal before
    taxes, tip, and delivery fee. If any credit happens to remain after being
    applied to the order, that credit will remain on your account. Referral Credits
    are subject to expiration and must be used within the time frame, if stated or
    otherwise determined by us from time to time. The amount of Referral Credits
    earned and redeemed are subject to change at any time at moovez’s sole
    discretion.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `The Referral Code may only be used for personal and non-commercial
    purposes. You may share your code with your personal connections via
    social media, email, or word of mouth, where you are the primary content
    owner. In cases where you are not the primary owner of the content or
    website (e.g., Wikipedia, coupon websites, affiliate websites, discussion
    forums, etc.), public distribution is not allowed. Promoting or advertising your
    Referral Code via search engine marketing or any other paid advertising
    media (e.g., Bing/Yahoo/Adwords) is not allowed. You are not allowed to
    misrepresent your relationship with any members of the moovez Group or
    with the moovez Services to any third parties, and you will not make any
    warranty or representation on behalf of any members of the moovez Group.
    You are not allowed to refer yourself or members of your household for the
    purposes of earning Referral Credit.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `moovez reserves the right, at its sole discretion, to deactivate your account(s)
    and revoke the Referral Credits of any account holder who it finds or believes
    to be earning or using Referral Credits against these Terms of Service, or
    who is manipulating or abusing the moovez Services or the moovez Platform.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `For greater certainty, we reserve the right, at any time and in our sole
    discretion, to add to or amend these Terms of Service in relation to the use of
    Referral Program or to vary or terminate the operation of a Referral moovez
    Credit issued pursuant to the Referral Program at any time without notice,
    including without limitation these Terms of Service, Referral moovez Credit,
    Gift cards, credit or other promotions`,
    bolded: "",
  },
  {
    type: "B2",
    number: "2",
    content: ``,
    bolded: "Promotional Vouchers",
  },
  {
    type: "B2",
    number: "",
    content: `The following terms and conditions will apply to all vouchers issued for free from time to time for
    promotional, advertising, or marketing purposes for use on Orders on the moovez Platform
    ("Promotional Vouchers"):`,
    bolded: "",
  },
  {
    type: "B3",
    number: "A",
    content: `Promotional Vouchers may only be redeemed towards Orders on the moovez
    Platform. Promotional Vouchers may have a specific dollar amount, may provide a
    percentage-based discount, or may have other effects (such as making a charitable
    donation). The particular terms of a Promotional Voucher (for example, the amount
    of any dollar or percentage discount, number of times the Promotional Voucher can
    be used, expiry date, area Promotional Voucher can be use, whether applicable for
    Move Orders or Delivery Orders) any other rules that may apply are described in
    the information accompanying or in conjunction with the Promotional Voucher (for
    example, the social media post or advertisement where the Promotional Voucher
    was posted).`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `Promotional Vouchers must be used in conjunction with a moovez Customer
    Account. Promotional Vouchers must be applied to an online Order by no later than
    the deadline specified on the Promotional Voucher and will expire if not applied by
    this time. If the Promotional Voucher provides for a discount on an Order, an amount determined by the rules of the Promotional Voucher will be credited to the
    Order to which the Promotional Voucher is applied.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `The right to use a Promotional Voucher is personal to the original recipient and may
    not be transferred. No Promotional Voucher may be copied, reproduced, distributed,
    or published directly or indirectly in any form or by any means for use by an entity
    other than the original recipient, or stored in a data retrieval system, without our
    prior written permission. For greater certainty, Promotional Vouchers distributed or
    circulated without our written approval, for example on an Internet message board
    or on a "bargains" website, are not valid for use and may be refused or canceled.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `Promotional Vouchers may not be used in conjunction with other vouchers or any
    other discounts or promotions provided or advertised from time to time unless
    otherwise stated.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `Promotional Vouchers may not be exchanged, redeemed, or refunded for cash, and
    may not be transferred or assigned.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "F",
    content: `No members of the moovez Group will not be liable to you, any customer or
    household for any loss or claim arising out of the refusal, rejection, cancellation or
    withdrawal of any Promotional Voucher or any failure or inability of a customer or
    household to use a Promotional Voucher for any reason.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "G",
    content: `For greater certainty, we reserve the right, at any time and in our sole discretion, to
    add to or amend terms and conditions (including these Terms of Service) in relation
    to the use of Promotional Vouchers or to vary or terminate the operation of a
    Promotional Voucher at any time without notice.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "2",
    content: ``,
    bolded: "Gift Card Terms and moovez Credits",
  },
  {
    type: "B3",
    number: "A",
    content: `moovez may grant you credit toward eligible purchases as set out in these terms
    ("Eligible Purchases") in the form of moovez gift cards ("Gift Cards") or moovez
    credit ("moovez Credit"). When you purchase a Gift Card, you are purchasing
    credit toward Eligible Purchases in a particular dollar amount and currency that is
    stored in an electronic form and linked to a unique gift card claim code ("Claim
    Code"). To use a Gift Card, you must have a moovez Customer Account, log in to
    your moovez Customer Account, and apply the Gift Card to your moovez Customer
    Account by entering the Claim Code on the moovez Platform. moovez Credit is
    credit toward Eligible Purchases in a particular dollar amount that is associated with
    a unique moovez Customer Account. The value of moovez Credit including credit
    from Gift Cards applied to your moovez Customer Account is referred to as your
    "moovez Credit Balance". When you apply a Gift Card to your moovez Customer
    Account, your moovez Credit Balance will be credited by the dollar value of the Gift
    Card, and the value associated with the Claim Code will be reduced to zero. From
    time to time, moovez may also issue moovez Credit to you for other purposes (for
    example, to refund a purchase that you have made). Your moovez Credit Balance
    includes the total remaining balance of all moovez Credit that has been applied to
    your moovez Customer Account by way of Gift Card or otherwise, but not yet
    applied to a purchase. To check your moovez Credit Balance, log in to your moovez Customer Account. To check the balance of a Gift Card before it has been applied to
    a moovez Customer Account, simply review the email or other document in which
    you received the Gift Card, where the face value will be printed.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `The Eligible Purchases that you may purchase using Gift Cards and moovez Credit
    consist of Goods, including all fees and costs on the Order for those Goods which
    you are permitted to purchase in accordance with these Terms of Service. moovez
    reserves the right to restrict the use of Gift Cards and moovez Credit to comply with
    applicable law. Gift Cards and moovez Credit cannot be used to purchase anything
    other than Eligible Purchases and must be used in the currency in which they were
    paid or credited for.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `When you make a purchase from your moovez Customer Account using moovez
    Credit (including moovez Credit from a Gift Card), the value of the moovez Credit
    that you use for that purchase will be deducted from your moovez Credit Balance.
    As long as you are only purchasing Eligible Purchases, you may use moovez Credit
    to pay for all fees and costs on your Order, including but not limited to the cost of
    move, delivery charges, tips, and taxes. Any unused moovez Credit Balance will
    remain associated with your moovez Customer Account. If the cost of an Order
    exceeds your moovez Credit Balance, the remaining amount must be paid with
    another payment method.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "D",
    content: `Gift Cards and moovez Credit do not expire. There are no fees associated with
    purchasing, using, or redeeming Gift Cards or moovez Credit.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "E",
    content: `moovez Cards and moovez Credit cannot be resold (except with the express written
        permission of moovez), used for payment outside the Eligible Purchases,
        transferred for value, returned for a cash refund, or redeemed for cash, except to
        the extent required by applicable law. Gift Cards cannot be reloaded. No portion of
        your moovez Credit Balance may be transferred to another moovez Customer
        Account or otherwise assigned or transferred to another person. moovez Credit
        cannot be used in conjunction with Cash Orders.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "F",
    content: `The risk of loss and title for Gift Cards pass to the purchaser upon our electronic
    transmission of the Gift Card to the purchaser or designated recipient. moovez is
    not responsible if any Gift Card is lost, stolen or destroyed, if your moovez Credit
    Balance or any Gift Card is used without your permission, or if you lose access to
    your moovez Customer Account or any moovez Credits.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "G",
    content: `Gift Cards and moovez Credit may be subject to any additional terms and
    conditions that will be specified in connection with the specific Gift Card and
    moovez Credit grant.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "H",
    content: `moovez has the right to void Gift Cards or moovez Credit, close moovez Customer
    Accounts and bill alternative forms of payment if it suspects that a Gift Card is
    obtained, used or applied to a moovez Customer Account (or moovez Credit is
    applied to a purchase) fraudulently, unlawfully or otherwise in violation of these
    Terms of Service, or in a manner that abuses the moovez Platform.`,
    bolded: "",
  },
  {
    type: "B2",
    number: "4",
    content: ``,
    bolded: "Contests/Competitions",
  },
  {
    type: "B3",
    number: "A",
    content: `From time to time, moovez may run contests or competitions ("Contests"). Those
    Contests will be subject to these Terms of Service, as well as rules specific to each
    Contest ("Contest Rules”).`,
    bolded: "",
  },
  {
    type: "B3",
    number: "B",
    content: `In general, Contests for Canadian users will be open to individual residents of
    Canada over the age of majority in the province or territory in which they reside,
    with the exception of:`,
    bolded: "",
  },
  {
    type: "B4",
    number: "i",
    content: `employees, representatives and agents (and persons with whom they are
        domiciled, whether related or not) of moovez Group, its advertising and
        promotion agencies; and`,
    bolded: "",
  },
  {
    type: "B4",
    number: "ii",
    content: `residents of Quebec, unless specified in the Contest Rules.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "",
    content: `The Contest Rules may specify other eligibility rules.`,
    bolded: "",
  },
  {
    type: "B3",
    number: "C",
    content: `For greater certainty, subject to applicable law, we reserve the right, at any time and
    in our sole discretion, to withdraw any Contest, to add to or amend terms and
    conditions (including these Terms of Service) in relation to any Contest or to vary or
    terminate the operation of a Contest at any time without notice.`,
    bolded: "",
  },
];
