// api/RegisterPageAPI/vehicleInformation?id=1000
// api/RegisterPageAPI/driverInformation?id=1000

import axios from "axios";
import baseUrl from "../constants/baseUrl";

// post Driver profile action
// this action is called in Driver registration page
export const postPaymentInformation = (paymentInfo, driverId) => async (dispatch) => {
  try {
    console.log("profile is updating", paymentInfo);
    const { data } = await axios.post(
      `${baseUrl}RegisterPageAPI/driverInformationWOvehicle?id=${driverId}`,
      paymentInfo
    );
    console.log("profile data from api", data);
  } catch (error) {
    console.log(error);
  }
};

