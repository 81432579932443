import React from "react";
import Layout from "../../layouts/index";
import BackgroundCheckScreen from "../../screens/onbording/BackgroundCheck";

const BackgroundCheck = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <BackgroundCheckScreen />
    </Layout>
  );
};

export default BackgroundCheck;
