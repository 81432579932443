import { List as ListIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../store/actions/loginActions";
import css from "../style/header.module.css";
import { MdManageAccounts } from "react-icons/md";
import {
  FaExchangeAlt,
  FaBell,
  FaHandsHelping,
  FaCarSide,
} from "react-icons/fa";

// get screen width
function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);
  return size;
}

function MarketingHeader() {
  // dimensions
  const [height, width] = useWindowSize();
  const [userMenuToggleState, setuserMenuToggleState] = React.useState(null);
  // dispatch actions
  const dispatch = useDispatch();
  // getting user info
  const { loginInfo } = useSelector((state) => state.loginReducer);
  //history for routing
  const history = useHistory();
  //function to open user menu
  const handleOpenUserMenu = (event) => {
    setuserMenuToggleState(event.currentTarget);
  };
  // Menu for signup
  const [openSignupMenu, setopenSignupMenu] = useState(false);
  // showDrawer
  const [showDrawer, setshowDrawer] = useState(false);
  const handlePush = (route, history) => {
    let link = "/Driver/";
    if (loginInfo?.id) link += route;
    else link += "Login";
    history.push(link);
  };

  const menuRef = useRef();

  useEffect(() => {
    const closeSignupMenuEvent = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setopenSignupMenu(false);
      }
    };
    document.addEventListener("click", closeSignupMenuEvent);

    return () => {
      document.addEventListener("click", closeSignupMenuEvent);
    };
  }, []);

  return (
    <div className={css?.headerMainDiv}>
      <div
        className={css?.wemoovezLogo}
        onClick={() => {
          history.push("/");
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/images/logo.svg"}
          alt="wemoovez Logo"
          title="wemoovez Logo"
        />
      </div>
      {width > 600 && (
        <>
          <div className={css?.centerOptions}>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                onClick={() => handlePush("availablePickups", history)}
              >
                Available Pickups
              </span>
            </div>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                onClick={() => handlePush("myTrips", history)}
              >
                My Trips
              </span>
            </div>
            <div className={css?.headerOptionsDiv}>
              <span
                className={css?.headerOptionstext}
                onClick={() =>
                  handlePush("myEarnings/" + loginInfo.id, history)
                }
              >
                My Earnings
              </span>
            </div>
          </div>
        </>
      )}
      {!loginInfo ? (
        <div className={css?.logoutButtons}>
          <span
            className={css?.loginButton}
            onClick={() => {
              history.push("/Driver/Login");
            }}
            title="Log in"
          >
            <img src={process.env.PUBLIC_URL + "/images/user.svg"} alt="" />
            {width > 600 && <>Login</>}
          </span>
          <span
            ref={menuRef}
            className={css?.signupButton}
            onClick={() => {
              setopenSignupMenu(!openSignupMenu);
            }}
            title="Sign up"
          >
            Sign up
          </span>
          <div
            className={css?.signupDrawer}
            style={{ display: openSignupMenu ? "flex" : "none" }}
          >
            <div
              style={{ borderBottom: "1.5px solid black" }}
              className={css?.signupDrawerOption}
              onClick={() => {
                window.location.href = "https://moovez.ca/Signup";
              }}
            >
              Schedule a pickup
            </div>
            <div
              className={css?.signupDrawerOption}
              onClick={() => {
                history.push("/Driver/MooverSignUp");
                setopenSignupMenu(false);
              }}
            >
              Become a moover
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.logoutButtons}>
          {loginInfo?.firstName + " " + loginInfo?.lastName}
          <p
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            className={css?.Profile}
          >
            {loginInfo?.email[0]}
          </p>
          <div className={css?.dropdown}>
            <div class={css?.dropdownContent}>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  onClick={() => history.push("/Driver/availablePickups")}
                >
                  <FaCarSide />
                  <span className={css?.dropdownOptionText}>
                    Available Pickups
                  </span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div className={css?.dropdownOption}>
                  <MdManageAccounts />
                  <span
                    className={css?.dropdownOptionText}
                    onClick={() =>
                      history.push(`/driver/account/${loginInfo?.email}`)
                    }
                  >
                    Account
                  </span>
                </div>

                <div className={css?.dropdownOption}>
                  <FaExchangeAlt />
                  <span
                    className={css?.dropdownOptionText}
                    onClick={() => {
                      history.push(
                        `/driver/ChangePassword/${loginInfo?.email}`
                      );
                    }}
                  >
                    Change Password
                  </span>
                </div>
                <div className={css?.dropdownOption}>
                  <FaBell />
                  <span className={css?.dropdownOptionText}>Notifications</span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  onClick={() => {
                    history.push("/driver/help");
                  }}
                >
                  <FaHandsHelping />
                  <span className={css?.dropdownOptionText}>Help</span>
                </div>
              </div>
              <div className={css?.dropdownSection}>
                <div
                  className={css?.dropdownOption}
                  style={{ borderBottom: "none" }}
                >
                  <span
                    className={css?.dropdownOptionText}
                    onClick={() => dispatch(logout(history))}
                  >
                    Logout
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {width < 601 && (
        <>
          <Button onClick={() => setshowDrawer(!showDrawer)}>
            <ListIcon style={{ color: "white" }} />
          </Button>
        </>
      )}
      <div
        className={css?.headerDrawer}
        style={{ display: showDrawer ? "flex" : "none" }}
      >
        <div
          className={css?.drawerOption}
          onClick={() => {
            history.push("/Driver/availablePickups");
          }}
        >
          Available Pickups
        </div>
        <div
          className={css?.drawerOption}
          onClick={() => {
            history.push("/driver/help");
          }}
        >
          Help
        </div>
        <div
          className={css?.drawerOption}
          onClick={() => {
            history.push("/driver/myTrips");
          }}
        >
          My Trips
        </div>
      </div>
    </div>
  );
}

export default MarketingHeader;
