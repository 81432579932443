import React from "react";
import Layout from "../../layouts/index";
import BackgroundCheckScreen from "../../screens/onbording_web/BackgroundCheck";

const BackgroundCheck = () => {
  return (
    <Layout>
      <BackgroundCheckScreen />
    </Layout>
  );
};

export default BackgroundCheck;
