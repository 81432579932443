import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { getFAQs } from "../store/actions/CommonActions";
import css from "../style/FAQ.module.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { BsChevronDown } from "react-icons/bs";
import Layout from "../layouts/index";

const FAQAccordion = ({ question, answer }) => {
  return (
    <div className={css?.FAQM}>
      <Accordion className={css?.FAQAcc}>
        <AccordionSummary
          expandIcon={<BsChevronDown />}
          collapse
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={css?.FAQSummary}
        >
          <div className={css.AccordionQuestion}>{question}</div>
        </AccordionSummary>
        <AccordionDetails className={css.AccordionAnswer}>
          {answer}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Section8 = () => {
  const [FAQs, setFAQs] = useState([]);
  const [FAQsLoading, setFAQsLoading] = useState(true);
  useEffect(() => {
    try {
      getFAQs(setFAQs, setFAQsLoading);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <Layout>
      <div className={css?.M}>
        <div className={css?.Heading}>Frequently asked questions</div>
        {FAQsLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div className={css?.FAQs}>
            {FAQs?.map((FAQ) => (
              <FAQAccordion {...FAQ} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Section8;
