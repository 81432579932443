import React from "react";
import css from "../../style/ourStory.module.css";

const Section1 = () => {
  return (
    <div className={css?.s1M}>
      <div className={css?.s1L}>
        <div className={css?.s1LHeading}>Our story</div>
        <div className={css?.s1LText}>
          moovez was created to transform the way people move big & bulky items.
        </div>
      </div>
      <div className={css?.s1R}>
        <img
          className={css?.s1RImage}
          src="/images/ourStory_banner.png"
          alt="loading_shipment"
        />
      </div>
    </div>
  );
};

export default Section1;
