import React from "react";
import DriverWithVehicle from "../screens/Signin/DriverWithVehicle";
import Layout from "../layouts";

const DriverWithVehiclePage = () => {
  return (
    <Layout>
      <DriverWithVehicle />
    </Layout>
  );
};

export default DriverWithVehiclePage;
