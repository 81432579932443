export const list = [
  {
    title: "Terms of Service",
    route: "TermsOfServices",
    propertyName: "isTermsOfServicesAccepted",
    isCompleted: false,
    permissions: [
      "CompanyVehicleDriverFlow",
      "OwnVehicleDriverFlow",
      "NoVehicleDriverFlow",
    ],
  },
  {
    title: "Legal Agreement",
    route: "LegalAgreement",
    propertyName: "isLegalAgreementAccepted",
    isCompleted: false,
    permissions: [
      "CompanyVehicleDriverFlow",
      "OwnVehicleDriverFlow",
      "NoVehicleDriverFlow",
    ],
  },
  {
    title: "Driver's License",
    route: "DriverLicense",
    propertyName: "licenseFile",
    propertyNames: ["driverLicenseBackStatus", "driverLicenseFrontStatus"],
    isCompleted: false,
    permissions: ["OwnVehicleDriverFlow"],
  },
  {
    title: "Business License",
    route: "BusinessLicense",
    propertyName: "legalBussinessLicenseStatus",
    isCompleted: true,
    permissions: ["CompanyVehicleDriverFlow"],
  },
  {
    title: "Profile Photo",
    route: "ProfilePhoto",
    propertyName: "profilePhotoStatus",
    isCompleted: false,
    permissions: ["OwnVehicleDriverFlow", "NoVehicleDriverFlow"],
  },
  {
    title: "Proof of Work Eligibility",
    route: "ProofOfWorkEligibility",
    propertyName: "eligibilityProofStatus",
    isCompleted: false,
    permissions: ["OwnVehicleDriverFlow", "NoVehicleDriverFlow"],
  },
  {
    title: "Background Check",
    route: "BackgroundCheck",
    propertyName: "backgroundCheckFileStatus",
    isCompleted: false,
    permissions: ["OwnVehicleDriverFlow", "NoVehicleDriverFlow"],
  },
  {
    title: "Vehicle Registration",
    route: "VehicleRegistration",
    propertyName: "registrationFileStatus",
    isCompleted: false,
    permissions: ["OwnVehicleDriverFlow"],
  },
  {
    title: "Vehicle Insurance",
    route: "VehicleInsurance",
    propertyName: "insuranceFileStatus",
    isCompleted: true,
    permissions: ["OwnVehicleDriverFlow"],
  },
  {
    title: "Proof of Insurance",
    route: "ProofOfInsurance",
    propertyNames: [
      "businessInsuranceCGStatus",
      "businessInsuranceCargoCoverageLStatus",
      "businessInsuranceCargoCoveragestatus",
      "businessInsuranceWorkerCompensationStatus",
    ],
    isCompleted: false,
    permissions: ["CompanyVehicleDriverFlow"],
  },
  {
    title: "Starter kit",
    route: "StarterKit",
    propertyNames: [
      "starterkitBlanketsFileStatus",
      "starterkitDollyFileStatus",
      "starterkitGlovesFileStatus",
      "starterkitStrapsFileStatus",
    ],
    isCompleted: false,
    permissions: ["CompanyVehicleDriverFlow", "OwnVehicleDriverFlow"],
  },
  {
    title: "Vehicle type",
    route: "VehicleType",
    propertyName: "vehicleTypeStatus",
    isCompleted: false,
    permissions: ["CompanyVehicleDriverFlow", "OwnVehicleDriverFlow"],
  },
];
