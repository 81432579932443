import React from "react";
import RegistrationStatusScreen from "../screens/Signin/RegistrationStatus";
import Layout from "../layouts";

const RegistrationStatus = () => {
  return (
    <Layout>
      <RegistrationStatusScreen />
    </Layout>
  );
};

export default RegistrationStatus;
