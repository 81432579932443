import React, { useState } from "react";
import Layout from "../layouts/index";
import SignupScreen from "../screens/Signin/Signup";

function Register() {
  return (
    // <Layout>
    <SignupScreen />
    // </Layout>
  );
}

export default Register;
