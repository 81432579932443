import React, { useState, useEffect } from "react";
import css from "../../style/driverRegistration.module.css";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  postCompanyVehicleDriverFlowInfo,
  getVehicleTypes,
  getCategories,
  postDriverDetails,
} from "../../store/actions/driverRegistration";
import { Stepper, Step, StepLabel } from "@material-ui/core";

const CompanyVehicleDriverFlow = () => {
  const { id } = useParams();
  const history = useHistory();

  const [driverInformation, setdriverInformation] = useState({
    insuranceFile: false,
    registrationFile: false,
    conditionFile: false,
    typeOfVehicle: false,
    POWEligibilityFile: false,
    starterKit: {
      dolly: false,
      straps: false,
      blankets: false,
      glooves: false,
    },
    inspectionFiles: {
      front: false,
      back: false,
      right: false,
      left: false,
    },
    businessLicense: false,
    BusinessInsurance1: false,
    BusinessInsurance2: false,
    BusinessInsurance3: false,
  });
  // vehicle types information
  const [vehicleTypes, setvehicleTypes] = useState();
  const [selectedVehicle, setselectedVehicle] = useState([]);
  // select categories
  const [categories, setcategories] = useState();
  const [selectedCategories, setselectedCategories] = useState([]);

  useEffect(() => {
    dispatch(getVehicleTypes(setvehicleTypes));
    dispatch(getCategories(setcategories));
  }, []);

  // change screens
  const [ChangeScreen, setChangeScreen] = useState(0);
  // dispatch actions
  const dispatch = useDispatch();

  const handleSubmitVehicleInfo = (e) => {
    e.preventDefault();
    if (
      driverInformation?.businessLicense &&
      driverInformation?.BusinessInsurance1 &&
      driverInformation?.BusinessInsurance2 &&
      driverInformation?.BusinessInsurance3 &&
      driverInformation?.starterKit?.dolly &&
      driverInformation?.starterKit?.straps &&
      driverInformation?.starterKit?.glooves &&
      driverInformation?.starterKit?.blankets &&
      driverInformation?.inspectionFiles?.front &&
      driverInformation?.inspectionFiles?.back &&
      driverInformation?.inspectionFiles?.right &&
      driverInformation?.inspectionFiles?.left
    ) {
      const formData = new FormData(e.target);
      dispatch(postCompanyVehicleDriverFlowInfo(formData, id, setChangeScreen));
    } else {
      alert("Please fill the entire form");
    }
  };

  const handleAddDriverInfo = (e) => {
    e.preventDefault();
    if (selectedVehicle?.length > 0 && selectedCategories?.length > 0) {
      dispatch(
        postDriverDetails(
          { categoryIds: selectedCategories, vehicleIds: selectedVehicle },
          id,
          history
        )
      );
    } else {
      alert("Please fill the entire form");
    }
  };

  const steps = [
    "Signup",
    "Verify Email",
    "Upload documents",
    "Select category",
    "Approval",
  ];

  return (
    <div className={css?.profileSettingsMainDiv}>
      <div className={css?.profileSettingsInnerMain}>
        {ChangeScreen === 0 ? (
          <form
            onSubmit={handleSubmitVehicleInfo}
            className={css?.accountDetails}
          >
            <Stepper
              className={css?.stepperClass}
              activeStep={2}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={css?.accountDetailsHeading}>
              <div className={css?.accountDetailHeadingBackground}>
                Vehicle information
              </div>
            </div>
            {/* Vehicle insurance */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.headingCat}>Commercial auto insurance</div>
                <div className={css?.accountDetailsBoxtextText}>
                  Insurance must be in your legal business name and name must be
                  legible. Scans and photocopies are not permitted
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                <div className={css?.accountDetailsBoxInputBox}>
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {/* {vehicleInformation?.insuranceFile} */}
                  </span>
                  <label
                    for="upload-vehicle-insurance"
                    className={css?.uploadImageSpan}
                  >
                    Upload document
                  </label>
                  <input
                    id="upload-vehicle-insurance"
                    type="file"
                    accept="image/*"
                    name="insuranceFile"
                    onChange={(e) => {
                      let data = e.target.files;
                      if (data?.length > 0) {
                        setdriverInformation({
                          ...driverInformation,
                          insuranceFile: true,
                        });
                      } else {
                        setdriverInformation({
                          ...driverInformation,
                          insuranceFile: false,
                        });
                      }
                    }}
                    className={css?.uploadImageInput}
                  ></input>
                </div>
                <div className={css?.notSubmitted}>
                  {driverInformation?.insuranceFile
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>
            {/* Vehicle Registration */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.accountDetailsBoxheading}>
                  Vehicle Registration
                </div>
                <div className={css?.accountDetailsBoxtextText}>
                  For each of the vehicles on your account you need to provide
                  both sides of your registration document. To be accepted the
                  document needs to have: 1) VIN, 2) registration number, and 3)
                  the expiration date of technical inspection. Uploaded images
                  of both sides of the registration must be clear and legible.
                  Remember, that all four corners of the document should be
                  visible in the picture. Scans and photocopies are not
                  permitted.
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                <div className={css?.accountDetailsBoxInputBox}>
                  <span className={css?.accountDetailsBoxInputBoxLabel}>
                    {/* {vehicleInformation?.registrationFile} */}
                  </span>
                  <label
                    for="upload-vehicle-registration"
                    className={css?.uploadImageSpan}
                  >
                    Upload document
                  </label>
                  <input
                    id="upload-vehicle-registration"
                    type="file"
                    accept="image/*"
                    name="registrationFile"
                    onChange={(e) => {
                      let data = e.target.files;
                      if (data?.length > 0) {
                        setdriverInformation({
                          ...driverInformation,
                          registrationFile: true,
                        });
                      } else {
                        setdriverInformation({
                          ...driverInformation,
                          registrationFile: false,
                        });
                      }
                    }}
                    className={css?.uploadImageInput}
                  ></input>
                </div>
                <div className={css?.notSubmitted}>
                  {driverInformation?.registrationFile
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>
            {/* Vehicle Inspection */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.accountDetailsBoxheading}>
                  Vehicle Condition
                </div>
                <div className={css?.accountDetailsBoxtextText}>
                  Upload one photo from all 4 sides of your vehicle (front,
                  left, right and back). You don’t have to own a Tesla
                  Cybertruck to operate on our platform but presentation is
                  important. A well-maintained vehicle builds customer
                  confidence and trust in our service
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                <div className={css?.accountDetailsBoxInputStarterBox}>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Front</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>

                    <label
                      for="upload-inspaction-front-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-inspaction-front-image"
                      type="file"
                      accept="image/*"
                      name="inspectionFileFront"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              front: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              front: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Back</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-inspection-back-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-inspection-back-image"
                      type="file"
                      accept="image/*"
                      name="inspectionFileBack"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              back: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              back: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>

                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Right</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-inspection-right-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-inspection-right-image"
                      type="file"
                      accept="image/*"
                      name="inspectionFileRight"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              right: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              right: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Left</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-inspection-left-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-inspection-left-image"
                      type="file"
                      accept="image/*"
                      name="inspectionFileLeft"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              left: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            inspectionFiles: {
                              ...driverInformation?.inspectionFiles,
                              left: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                </div>
                {/* Starter Multi Uploads Uploads */}
                <div className={css?.notSubmitted}>
                  Front:{" "}
                  {driverInformation?.inspectionFiles?.front
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Back:{" "}
                  {driverInformation?.inspectionFiles?.back
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Right:{" "}
                  {driverInformation?.inspectionFiles?.right
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Left:{" "}
                  {driverInformation?.inspectionFiles?.left
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>

            <div className={css?.accountDetailsHeading}>
              <div className={css?.accountDetailHeadingBackground}>
                Company information
              </div>
            </div>
            {/* Driver's license */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.accountDetailsBoxheading}>
                  Legal business license
                </div>
                <div className={css?.accountDetailsBoxtextText}>
                  Bussiness Licences are required for anyone providing a service
                  or goods. Proof of a registered Bussiness is required to
                  complete your registration process.
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                <div className={css?.accountDetailsBoxInputBox}>
                  <span className={css?.accountDetailsBoxInputBoxLabel}></span>
                  <label for="upload-BL-image" className={css?.uploadImageSpan}>
                    Upload document
                  </label>
                  <input
                    id="upload-BL-image"
                    type="file"
                    accept="image/*"
                    name="businessLicense"
                    onChange={(e) => {
                      let data = e.target.files;
                      if (data?.length > 0) {
                        setdriverInformation({
                          ...driverInformation,
                          businessLicense: true,
                        });
                      } else {
                        setdriverInformation({
                          ...driverInformation,
                          businessLicense: false,
                        });
                      }
                    }}
                    className={css?.uploadImageInput}
                  ></input>
                </div>
                <div className={css?.notSubmitted}>
                  {driverInformation?.businessLicense
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>
            {/* Proof of work eligibility */}
            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.accountDetailsBoxheading}>
                  Bussiness Insurance
                </div>
                <div className={css?.accountDetailsBoxtextText}>
                  The most common and comprehensive types of policies moving
                  companies carry are general liability and cargo insurance.
                  Please upload the insurance policy or policies your company
                  carries.
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                <div className={css?.accountDetailsBoxInputStarterBox}>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>File 1</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>

                    <label
                      for="upload-BI1-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-BI1-image"
                      type="file"
                      accept="image/*"
                      name="BusinessInsurance1"
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance1: true,
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance1: false,
                          });
                        }
                      }}
                      className={css?.uploadImageStarterInput}
                    ></input>
                  </div>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>File 2</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-BI2-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-BI2-image"
                      type="file"
                      accept="image/*"
                      name="BusinessInsurance2"
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance2: true,
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance2: false,
                          });
                        }
                      }}
                      className={css?.uploadImageStarterInput}
                    ></input>
                  </div>

                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>File 3</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-BI3-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-BI3-image"
                      type="file"
                      accept="image/*"
                      name="BusinessInsurance3"
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance3: true,
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            BusinessInsurance3: false,
                          });
                        }
                      }}
                      className={css?.uploadImageStarterInput}
                    ></input>
                  </div>
                </div>

                {/* Starter Multi Uploads Uploads */}
                <div className={css?.notSubmitted}>
                  File 1:{" "}
                  {driverInformation?.BusinessInsurance1
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  File 2:{" "}
                  {driverInformation?.BusinessInsurance2
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  File 3:{" "}
                  {driverInformation?.BusinessInsurance3
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>
            {/* Starter Kit */}

            {/* Starter Kit */}

            <div className={css?.accountDetailsBox}>
              <div className={css?.accountDetailsBoxText}>
                <div className={css?.accountDetailsBoxheading}>Starter Kit</div>
                <div className={css?.accountDetailsBoxtextText}>
                  Basic equipment is required before you can start accepting
                  jobs. Upload pictures of the listed items to complete your
                  registration process
                </div>
              </div>
              <div className={css?.accountDetailsBoxInputArea}>
                {/* Starter Uploads */}
                <div className={css?.accountDetailsBoxInputStarterBox}>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Dolly</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>

                    <label
                      for="upload-dolly-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-dolly-image"
                      type="file"
                      accept="image/*"
                      name="ownStarterKitDollyFile"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              dolly: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              dolly: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Straps</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-straps-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-straps-image"
                      type="file"
                      accept="image/*"
                      name="ownStarterKitStrapsFile"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              straps: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              straps: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>

                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Blankets</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-blankets-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-blankets-image"
                      type="file"
                      accept="image/*"
                      name="ownStarterKitBlanketsFile"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              blankets: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              blankets: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                  <div className={css?.accounrDetailsInputMultiBox}>
                    <label>Gloves</label>
                    <span
                      className={css?.accountDetailsBoxInputBoxLabel}
                    ></span>
                    <label
                      for="upload-gloves-image"
                      className={css?.uploadImageSpan}
                    >
                      Upload document
                    </label>
                    <input
                      id="upload-gloves-image"
                      type="file"
                      accept="image/*"
                      name="StarterBlanketsKitFile"
                      className={css?.uploadImageStarterInput}
                      onChange={(e) => {
                        let data = e.target.files;
                        if (data?.length > 0) {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              glooves: true,
                            },
                          });
                        } else {
                          setdriverInformation({
                            ...driverInformation,
                            starterKit: {
                              ...driverInformation?.starterKit,
                              glooves: false,
                            },
                          });
                        }
                      }}
                    ></input>
                  </div>
                </div>

                {/* Starter Multi Uploads Uploads */}
                <div className={css?.notSubmitted}>
                  Dolly:{" "}
                  {driverInformation?.starterKit?.dolly
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Straps:{" "}
                  {driverInformation?.starterKit?.straps
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Blankets:{" "}
                  {driverInformation?.starterKit?.blankets
                    ? "Submitted"
                    : "not Submitted"}
                </div>

                <div className={css?.notSubmitted}>
                  Gloves:{" "}
                  {driverInformation?.starterKit?.glooves
                    ? "Submitted"
                    : "not Submitted"}
                </div>
              </div>
            </div>

            <input className={css?.submitButton} type="submit" value="Submit" />
          </form>
        ) : (
          <div className={css?.profileSettingsInnerMain}>
            <div className={css?.accountDetails}>
              <Stepper
                className={css?.stepperClass}
                activeStep={3}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {categories &&
                Array.isArray(categories) &&
                categories?.length > 0 && (
                  <>
                    <div
                      className={css?.accountDetailsBoxheading}
                      style={{ marginBottom: 20 }}
                    >
                      Select categories in which you want to work
                    </div>

                    {categories?.map((category) => (
                      <div className={css?.categoryLine}>
                        <div className={css?.categoryIcon}>
                          <i class={category?.categoryIcon}></i>
                        </div>
                        <div className={css?.categoryName}>
                          {category?.categoryName}
                        </div>
                        <div className={css?.categoryIcon}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setselectedCategories([
                                  ...selectedCategories,
                                  category?.id,
                                ]);
                              } else {
                                setselectedCategories(
                                  selectedCategories?.filter(
                                    (cat) => cat !== category?.id
                                  )
                                );
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {vehicleTypes &&
                Array.isArray(vehicleTypes) &&
                vehicleTypes?.length > 0 && (
                  <>
                    <div className={css?.headingCat}>Select vehicles</div>

                    {vehicleTypes?.map((vehicle) => (
                      <div className={css?.categoryLine}>
                        <div
                          className={css?.categoryName}
                          style={{ width: "83%" }}
                        >
                          {vehicle?.title}
                        </div>
                        <div className={css?.categoryIcon}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setselectedVehicle([
                                  ...selectedVehicle,
                                  vehicle?.id,
                                ]);
                              } else {
                                setselectedVehicle(
                                  selectedVehicle?.filter(
                                    (vehicleId) => vehicleId !== vehicle?.id
                                  )
                                );
                              }
                            }}
                          ></input>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              <button
                className={css?.submitButton}
                onClick={handleAddDriverInfo}
              >
                submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyVehicleDriverFlow;
