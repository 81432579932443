import React from "react";
import Layout from "../../layouts/index";
import ProofOfWorkEligibilityScreen from "../../screens/onbording_web/ProofOfWorkEligibility";

const ProofOfWorkEligibility = () => {
  return (
    <Layout>
      <ProofOfWorkEligibilityScreen />
    </Layout>
  );
};

export default ProofOfWorkEligibility;
