import React from "react";
import Layout from "../../layouts/index";
import ProfilePhotoScreen from "../../screens/onbording/ProfilePhoto";

const ProfilePhoto = () => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <ProfilePhotoScreen />
    </Layout>
  );
};

export default ProfilePhoto;
