import React from "react";
// importing the css
import css from "../../style/common.module.css";
//Modal Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

//Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minWidth: 200,
  maxWidth: 400,
  width: "30%",
};

const ModalComponent = ({
  text,
  openState,
  closeFunction,
  btnText1,
  btnClickFunc1,
  btnText2,
  btnClickFunc2,
  loader,
}) => {
  return (
    <Modal
      open={openState}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {text}
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {btnText1 && (
            <div
              className={css?.roundBtn}
              style={{ width: "45%" }}
              onClick={btnClickFunc1}
            >
              {btnText1}
            </div>
          )}
          {btnText2 && (
            <div
              className={css?.roundBtn}
              style={{ width: "45%" }}
              onClick={btnClickFunc2}
            >
              {btnText2}
            </div>
          )}
          {loader && (
            <div style={{ textAlignLast: "center", paddingTop: "10%" }}>
              <Box>
                <CircularProgress color="secondary" />
              </Box>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
