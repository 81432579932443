import React, { useState } from "react";
import css from "../../style/onboardingweb.module.css";
import { useParams, useHistory } from "react-router";
import { postDocumentsWeb } from "../../store/actions/onboardingActions";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent";

const VehicleRegistration = () => {
  const history = useHistory();
  const { id, type } = useParams();

  const [error, setError] = useState("");
  const [front, setFront] = useState();
  const [frontImg, setFrontImg] = useState();
  const [takePhoto, setTakePhoto] = useState(false);

  const handleAddImage = (e) => {
    if (e?.target?.name === "insuranceFile" && e.target.files?.length) {
      setFront(e.target.files[0]);
      setFrontImg(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmitForm = () => {
    if (front) {
      const formData = new FormData();
      formData.append("insuranceFile", front);
      postDocumentsWeb(id, type, formData, setError, history);
    } else {
      toast.error("Please upload your your vehicle insurance document.");
    }
  };
  return (
    <div className={css?.mD}>
      {takePhoto ? (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <label htmlFor="dl-front">
              <div className={css?.dlUploadMd}>
                <h4 className={css?.l2Heading}>Click below to take photo</h4>
                <div className={css?.dlUploadImgBG}>
                  <img
                    src={frontImg ?? "/images/upload.png"}
                    className={frontImg ? css?.UploadImg : css?.dlUploadImg}
                  />
                </div>
              </div>
            </label>
            <input
              id="dl-front"
              name="insuranceFile"
              type="file"
              accept="image/*"
              capture="camera"
              onChange={handleAddImage}
              style={{ display: "none" }}
            />
          </div>
          <ErrorComponent error={error} />
          <div className={css?.buttonPanel}>
            <div className={css?.submitButton} onClick={handleSubmitForm}>
              Confirm
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.centerDiv}>
          <div className={css?.contentDiv}>
            <h3 className={css?.mHeading}>
              Take a photo of your Vehicle Insurance
            </h3>
            <div className={css?.mtext}>
              Please upload a current (non-expired) photo of your Vehicle
              Insurance. Please make sure your name, VIN, insurance company, and
              expiration date are clear and visible.
            </div>
            <div className={css?.imageDiv}>
              <img
                src="/images/VI.png"
                className={css?.dlImage}
                style={{ margin: "30px 0" }}
              />
            </div>
          </div>
          <div className={css?.buttonPanel}>
            <div
              className={css?.submitButton}
              onClick={() => setTakePhoto(true)}
            >
              Take Photo
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleRegistration;
