import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import css from "../../style/signupStyles.module.css";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/Modals/Modal";
import { driverSignup } from "../../store/actions/loginActions";
import { customerWebUrl as baseUrl } from "../../store/constants/baseUrl";

function Register() {
  const options = [
    {
      value: "I own or work for a moving company.",
      id: "1",
      label: "I own or manage a moving company.",
    },
    {
      value: "I want to register as a moover WITH my vehicle.",
      id: "2",
      label: "I own a qualified vehicle and can lift over 75 lbs.",
    },
    {
      value: "I want to register as a moover WITHOUT a vehicle",
      id: "3",
      label: "I don’t own a vehicle but can lift over 75 lbs.",
    },
  ];
  // state to restrict multipel clicks
  const [buttonClicked, setButtonClicked] = useState(false);
  // errors
  const [error, seterror] = useState();
  //history for routing
  const history = useHistory();
  // dispatch actions
  const dispatch = useDispatch();
  //Type of Moover
  const [typeOfMoover, setTypeOfMoover] = useState("");
  const [InviteCode, setInviteCode] = useState("");
  const [Place, setPlace] = useState("");
  //firstName
  const [firstName, setfirstName] = useState("");
  //last name
  const [lastName, setlastName] = useState("");
  //email
  const [email, setemail] = useState("");
  //phoneNo
  const [phoneNo, setphoneNo] = useState("");
  //password
  const [password, setpassword] = useState("");
  //confirmPass
  const [confirmPassword, setconfirmPassword] = useState("");
  // terms and conditions
  const [TnC, setTnC] = useState(false);
  // custom alert state
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  // useEffect to show error modal
  useEffect(() => {
    try {
      if (error) {
        setModalFields({
          ...modalFields,
          open: true,
          text: error,
        });
        seterror("");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [error]);

  useEffect(() => {
    try {
      let makeBtnClickable;

      if (buttonClicked) {
        makeBtnClickable = setTimeout(() => {
          setButtonClicked(false);
        }, 10000);
      }
      return () => clearTimeout(makeBtnClickable);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [buttonClicked]);

  // function to add number
  const handleAddPhoneNumber = (e) => {
    let number = e?.target?.value;
    let numArr = [...number];
    if (numArr?.length < 13) {
      let newNum = "";
      for (let i = 0; i < numArr?.length; i++) {
        console.log(i);
        if (numArr[i] >= "0" && numArr[i] <= "9" && i !== 3 && i !== 7) {
          newNum = newNum + numArr[i];
        } else if (numArr[i] === "-" && i === 3) {
          newNum = newNum + numArr[i];
        } else if (numArr[i] === "-" && i === 7) {
          newNum = newNum + numArr[i];
        } else {
          setphoneNo(newNum);
          return null;
        }
      }
      if (numArr?.length === 3) {
        number = number + "-";
        setphoneNo(number);
      } else if (numArr?.length === 7) {
        number = number + "-";
        setphoneNo(number);
      } else {
        setphoneNo(number);
      }
    }
  };

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  const handleSignUp = () => {
    if (TnC) {
      if (
        firstName &&
        lastName &&
        email &&
        phoneNo &&
        password &&
        confirmPassword &&
        Place &&
        // InviteCode &&
        typeOfMoover
      ) {
        if (ValidateEmail(email)) {
          if (password?.length > 0) {
            if (password === confirmPassword) {
              if (phoneNo?.length === 12) {
                const signupData = {
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  password: password,
                  phone: phoneNo,
                  InviteCode: InviteCode,
                  MooverType: typeOfMoover,
                  Place: Place,
                };
                console.log("signup data from signup component", signupData);
                if (!buttonClicked) {
                  setButtonClicked(true);
                  dispatch(driverSignup(signupData, seterror, history));
                }
              } else {
                alert("Invalid phone number");
              }
            } else {
              alert("Passwords do not match");
            }
          } else {
            alert("Password must be of at least 6 characters.");
          }
        }
      } else {
        alert("Please fill all the fields");
      }
    } else {
      alert("Please agree to the terms and conditions before proceeding");
    }
  };

  return (
    <div className={css?.signupMainDiv}>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <div className={css?.signupForm}>
        <img
          src="/images/Moovez-Dark.svg"
          alt=""
          className={css?.signupImage}
        />
        <div className={css?.posterMainHeading}>
          Become a moover & get paid!
        </div>
        <div
          className={css?.posterMainHeading}
          style={{ fontSize: 20, fontWeight: 500, margin: "10px 0" }}
        >
          You won’t need a gym membership anymore either.
        </div>
        <input
          value={firstName}
          placeholder="First name"
          onChange={(e) => setfirstName(e.target.value)}
          className={css?.halfInput}
        ></input>
        <input
          value={lastName}
          placeholder="Last name"
          onChange={(e) => setlastName(e.target.value)}
          className={css?.halfInput}
        ></input>
        <select
          value={typeOfMoover}
          className={css?.fullInput}
          onChange={(e) => setTypeOfMoover(e.target.value)}
          placeholder="Type of Moover"
        >
          <option value="">Type of Moover</option>
          {options?.map((option) => (
            <option value={option?.value}>{option?.label}</option>
          ))}
        </select>
        <input
          value={email}
          placeholder="Email"
          onChange={(e) => setemail(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={phoneNo}
          placeholder="Phone number"
          onChange={(e) => handleAddPhoneNumber(e)}
          className={css?.fullInput}
        ></input>
        <input
          value={password}
          type="password"
          placeholder="Password"
          onChange={(e) => setpassword(e?.target?.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={confirmPassword}
          type="password"
          placeholder="Confirm password"
          onChange={(e) => setconfirmPassword(e.target.value)}
          className={css?.fullInput}
        ></input>
        <input
          value={Place}
          placeholder="What city are you in?"
          onChange={(e) => setPlace(e.target.value)}
          className={css?.fullInput}
        ></input>
        <div className={css?.TnCMainDiv}>
          <input
            type="checkbox"
            checked={TnC}
            onChange={(e) => setTnC(e.target.checked)}
            className={css?.TnCCheckbox}
          ></input>
          By proceeding, I agree to moovez's{" "}
          <span
            className={css?.signupTxtLink}
            onClick={() => window.open(baseUrl + "terms")}
          >
            Terms of Use
          </span>{" "}
          and acknowledge that I have read the{" "}
          <span
            className={css?.signupTxtLink}
            onClick={() => window.open(baseUrl + "Privacy")}
          >
            Privacy Policy
          </span>
          .
        </div>
        <div className={css?.signupBtnMainDiv}>
          <button className={css?.signupBtn} onClick={handleSignUp}>
            Sign up
          </button>
          <div className={css?.signinRedirect}>
            Already have an account?{" "}
            <span
              className={css?.signinTxt}
              onClick={() => history.push("/Driver/Login")}
            >
              Sign in
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
