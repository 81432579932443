import React from "react";
import { customerWebUrl } from "../../store/constants/baseUrl";
import css from "../../style/Help.module.css";
import { BiChevronRight } from "react-icons/bi";

const Card = ({ title, index, clickFunc }) => {
  return (
    <div className={css?.S1CardMD}>
      <img
        className={css?.S1Image}
        src={`/images/h${index + 1}.png`}
        alt="Help"
      />
      <div className={css?.S1CardInfo}>
        <div className={css?.S1CardTitle}>{title}</div>
        <div className={css?.S1CardBtn} onClick={clickFunc}>
          <BiChevronRight />
        </div>
      </div>
    </div>
  );
};
const Section1Intro = () => {
  const cardInfo = [
    { title: "Schedule a delivery", link: "deliveryInfo" },
    { title: "Book a move", link: "moverInfo" },
    { title: "Business deliveries", link: "businessInfo" },
  ];
  return (
    <div className={css?.S1M}>
      <div className={css?.S1Heading}>How we can help</div>
      <div className={css?.S1CardsDiv}>
        {cardInfo.map((info, index) => (
          <Card
            title={info.title}
            index={index}
            clickFunc={() => window.open(customerWebUrl + info.link, "_blank")}
          />
        ))}
      </div>
    </div>
  );
};

export default Section1Intro;
