import React, { useState, useEffect } from "react";
import css from "../../style/driverProfile.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  postProfileImage,
  getDriverProfile,
  postDriverProfile,
} from "../../store/actions/DriverProfileActions";
import { useParams } from "react-router";
import { profileImageURL } from "../../store/constants/baseUrl";
import { useHistory } from "react-router";

const DriverProfile = () => {
  // for routing
  const history = useHistory();
  //bring labels into text
  const [firstNameToggle, setfirstNameToggle] = useState(true);
  const [lastNameToggle, setlastNameToggle] = useState(true);
  const [emailToggle, setemailToggle] = useState(true);
  const [contactNoToggle, setcontactNoToggle] = useState(true);
  const [DOBToggle, setDOBToggle] = useState(true);

  const { driverEmail } = useParams();
  // DriverProfileObj
  const [DriverObj, setDriverObj] = useState();

  const handleUpdateProfile = () => {
    dispatch(postDriverProfile(DriverObj));
  };
  //upload Driver profile image
  const handleMediaFileUpload = (e) => {
    console.log(e?.target?.files);
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append(file?.type, file);

    dispatch(postProfileImage(DriverObj?.id, DriverObj?.email, formData));
  };
  // call Driver profile get API
  useEffect(() => {
    dispatch(getDriverProfile(driverEmail));
  }, []);
  // dispatch actions
  const dispatch = useDispatch();
  // get loginInfo from reducer
  const { driverProfileInfo } = useSelector((state) => state?.driverProfile);
  // populate DriverObj from redux
  useEffect(() => {
    if (driverProfileInfo) {
      setDriverObj(driverProfileInfo);
    }
  }, [driverProfileInfo]);
  return (
    <div className={css?.profileSettingsInnerMain}>
      <div className={css?.sidePannel}>
        <span
          className={css?.sidePannelIcon}
          onClick={() => history.push(`/Driver/account/${driverEmail}`)}
        >
          <i class="fas fa-angle-left"></i>
        </span>
        <span
          className={css?.sidePannelHeading}
          onClick={() => history.push(`/Driver/account/${driverEmail}`)}
        >
          my account
        </span>
      </div>
      <div className={css?.accountDetails}>
        <div className={css?.accountDetailsHeading}>profile settings</div>
        {/* profile image */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>Profile image</div>
          <div className={css?.accountDetailsBoxInputBox}>
            <span className={css?.accountDetailsBoxInputBoxLabel}>
              {DriverObj?.profileDisplayImageUrl && (
                <img
                  src={`${profileImageURL}${DriverObj?.profileDisplayImageUrl}`}
                  alt="profile image"
                  className={css?.DriverProfileImageImage}
                />
              )}
            </span>
            <label for="upload-profile-image" className={css?.uploadImageSpan}>
              Upload document
            </label>
            <input
              id="upload-profile-image"
              type="file"
              accept="image/*"
              onChange={(e) => handleMediaFileUpload(e)}
              className={css?.uploadImageInput}
            ></input>
          </div>
        </div>
        {/* first name */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>Your first name*</div>
          <div className={css?.accountDetailsBoxInputBox}>
            {firstNameToggle ? (
              <span className={css?.accountDetailsBoxInputBoxLabel}>
                {DriverObj?.firstName}
              </span>
            ) : (
              <input
                value={DriverObj?.firstName}
                onChange={(e) =>
                  setDriverObj({ ...DriverObj, firstName: e.target.value })
                }
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
            )}
            <span
              className={css?.accountDetailsBoxInputBoxEditIcon}
              onClick={() => setfirstNameToggle(!firstNameToggle)}
            >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>
        {/* last name */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>Your last name*</div>
          <div className={css?.accountDetailsBoxInputBox}>
            {lastNameToggle ? (
              <span className={css?.accountDetailsBoxInputBoxLabel}>
                {DriverObj?.lastName}
              </span>
            ) : (
              <input
                value={DriverObj?.lastName}
                onChange={(e) =>
                  setDriverObj({
                    ...DriverObj,
                    lastName: e.target.value,
                  })
                }
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
            )}
            <span
              className={css?.accountDetailsBoxInputBoxEditIcon}
              onClick={() => setlastNameToggle(!lastNameToggle)}
            >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>
        {/* email */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>
            Contact Email (Not log in email!)*
          </div>
          <div className={css?.accountDetailsBoxInputBox}>
            {emailToggle ? (
              <span className={css?.accountDetailsBoxInputBoxLabel}>
                {DriverObj?.secondaryEmail}
              </span>
            ) : (
              <input
                value={DriverObj?.secondaryEmail}
                onChange={(e) =>
                  setDriverObj({
                    ...DriverObj,
                    secondaryEmail: e.target.value,
                  })
                }
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
            )}
            <span
              className={css?.accountDetailsBoxInputBoxEditIcon}
              onClick={() => setemailToggle(!emailToggle)}
            >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>
        {/* contactNo */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>Your phone number*</div>
          <div className={css?.accountDetailsBoxInputBox}>
            {contactNoToggle ? (
              <span className={css?.accountDetailsBoxInputBoxLabel}>
                {DriverObj?.phone}
              </span>
            ) : (
              <input
                value={DriverObj?.phone}
                onChange={(e) =>
                  setDriverObj({ ...DriverObj, phone: e.target.value })
                }
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
            )}
            <span
              className={css?.accountDetailsBoxInputBoxEditIcon}
              onClick={() => setcontactNoToggle(!contactNoToggle)}
            >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>
        {/* Date Of Birth */}
        <div className={css?.accountDetailsBox}>
          <div className={css?.accountDetailsBoxText}>Date of Birth</div>
          <div className={css?.accountDetailsBoxInputBox}>
            {DOBToggle ? (
              <span className={css?.accountDetailsBoxInputBoxLabel}>
                {DriverObj?.birthDate}
              </span>
            ) : (
              <input
                type="date"
                value={DriverObj?.birthDate}
                onChange={(e) =>
                  setDriverObj({
                    ...DriverObj,
                    birthDate: e.target.value,
                  })
                }
                className={css?.accountDetailsBoxInputBoxInput}
              ></input>
            )}
            <span
              className={css?.accountDetailsBoxInputBoxEditIcon}
              onClick={() => setDOBToggle(!DOBToggle)}
            >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>

        <button className={css?.submitButton} onClick={handleUpdateProfile}>
          Save
        </button>
      </div>
    </div>
  );
};

export default DriverProfile;
