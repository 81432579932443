import axios from "axios";
import baseUrl from "../constants/baseUrl";
import { returnAuthHeader } from "../../utils/auth";
import { showLoader, hideLoader } from "../../utils/loader";

const options = [
  {
    value: "I own or work for a moving company.",
    type: "CompanyVehicleDriverFlow",
  },
  {
    value: "I want to register as a moover WITH my vehicle.",
    type: "OwnVehicleDriverFlow",
  },
  {
    value: "I want to register as a moover WITHOUT a vehicle",
    type: "NoVehicleDriverFlow",
  },
];

export const getStatus = async (id, setData, setError, setLoading) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}RegisterPageAPI/GetDriverFilesStatus?driverId=${id}`,
      returnAuthHeader()
    );
    if (data?.mooverType) {
      let actualMoverType = options.find(
        (option) => option.value === data.mooverType
      );
      data.mooverType = actualMoverType.type;
    } else throw new Error();
    setData(data);
    setError("");
    setLoading(false);
  } catch (error) {
    console.log(error);
    setError("Something went wrong, please try again later!");
    setLoading(false);
  }
};

export const postDocumentsWeb = async (
  id,
  type,
  data,
  setError,
  history,
  isLegalAgreementAccepted = false,
  isTermsOfServicesAccepted = false
) => {
  try {
    showLoader();
    const config = returnAuthHeader();
    const token = config.headers.Authorization;
    let params = `?id=${id}&isTermsOfServicesAccepted=${isTermsOfServicesAccepted}&isLegalAgreementAccepted=${isLegalAgreementAccepted}`;
    await axios.post(
      `${baseUrl}RegisterPageAPI/${type}${params}`,
      data ?? {},
      config
    );
    setError("");
    history.push(`/Driver/web/registration/${id}`);
    hideLoader();
  } catch (error) {
    console.log(error);
    setError("Something went wrong, please try again later!");
    hideLoader();
  }
};

export const postDocumentsMobile = async (
  id,
  type,
  data,
  setError,
  history,
  isLegalAgreementAccepted = false,
  isTermsOfServicesAccepted = false
) => {
  try {
    showLoader();
    const config = returnAuthHeader();
    const token = config.headers.Authorization;
    let params = `?id=${id}&isTermsOfServicesAccepted=${isTermsOfServicesAccepted}&isLegalAgreementAccepted=${isLegalAgreementAccepted}`;
    await axios.post(
      `${baseUrl}RegisterPageAPI/${type}${params}`,
      data ?? {},
      config
    );
    setError("");
    history.push(
      `/Driver/mobile/registration/${id}?token=${token.split(" ")[1]}`
    );
    hideLoader();
  } catch (error) {
    console.log(error);
    setError("Something went wrong, please try again later!");
    hideLoader();
  }
};

export const postVehicleIdsWeb = async (vehicleIds, id, history) => {
  try {
    showLoader();
    const authHeader = returnAuthHeader();
    const token = authHeader.headers.Authorization;
    await axios.post(
      `${baseUrl}RegisterPageAPI/PostVehicle?Driverid=${id}`,
      vehicleIds,
      authHeader
    );
    history.push(`/Driver/web/registration/${id}`);
    hideLoader();
  } catch (error) {
    console.log(error);
    hideLoader();
  }
};

export const postVehicleIdsMobile = async (vehicleIds, id, history) => {
  try {
    showLoader();
    const authHeader = returnAuthHeader();
    const token = authHeader.headers.Authorization;
    await axios.post(
      `${baseUrl}RegisterPageAPI/PostVehicle?Driverid=${id}`,
      vehicleIds,
      authHeader
    );
    history.push(
      `/Driver/mobile/registration/${id}?token=${token.split(" ")[1]}`
    );
    hideLoader();
  } catch (error) {
    console.log(error);
    hideLoader();
  }
};
