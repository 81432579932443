import React from "react";
import Layout from "../layouts/index";
import ContactUs from "../screens/Info/ContactUs";

const ContactUsPage = () => {
  return (
    <Layout>
      <ContactUs />
    </Layout>
  );
};

export default ContactUsPage;
